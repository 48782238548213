import { cookies } from '../helpers/Common'

const LangId = () => {
  cookies.set('lang', 'ID')
  window.location.reload(false)
}

const LangEn = () => {
  cookies.set('lang', 'EN')
  window.location.reload(false)
}

export const isLangEN = cookies.get('lang') === 'EN'
// const annualLeaveLabelId = cookies.get('annual_label_id') || 'Cuti'
// const annualLeaveLabelEn = cookies.get('annual_label_en') || 'Annual Leave'
const annualLeaveLabelId = 'Cuti'
const annualLeaveLabelEn = 'Paid Leave'

const DashboardEn = 'Dashboard'
const CompanySettingEn = 'Company'
const ManageAdminEn = 'Manage Admin'
const ManageDivisionEn = 'Manage Division'
const AttendanceSpotEn = 'Attendance Location'
const WorkPatternEn = 'Work Pattern'
const ManageEmployeesEn = 'Manage Employees'
const DataEmployeesEn = 'Data Employees'
const EmployeesDivisionEn = 'Employees Division'
const WorkTimeEn = 'Workday Schedule'
const TimesheetEn = 'Timesheet'
const TimesheetListEn = 'Timesheet List'
const TimesheetReportEn = 'Timesheet Report'
const DailyAttendanceEn = 'Daily Attendance'
const AttendanceSummaryEn = 'Attendance Summary'
const ShiftWorkTimeEn = 'Shift Schedule'
const YourTimeZoneEn = 'Your Time Zone'
const EmployeePerDivisionEn = 'Employee Per Division'
const AttendancePerDayEn = 'Daily Attendence'
const SummaryAttendanceTimeEn = 'Summary Attendance Time'
const SummaryAttendanceSpotEn = 'Summary Attendance Spot'
const AddScheduleEn = 'Add Schedule'

const DashboardId = 'Dasbor'
const CompanySettingId = 'Perusahaan'
const ManageAdminId = 'Admin'
const ManageDivisionId = 'Divisi'
const AttendanceSpotId = 'Lokasi Kehadiran'
const WorkPatternId = 'Pola Kerja'
const ManageEmployeesId = 'Kelola Karyawan'
const DataEmployeesId = 'Data Karyawan'
const EmployeesDivisionId = 'Divisi Karyawann'
const WorkTimeId = 'Jadwal Hari Kerja'
const TimesheetId = 'Timesheet'
const TimesheetListId = 'Daftar Timesheet'
const TimesheetReportId = 'Laporan Timesheet'
const DailyAttendanceId = 'Kehadiran Harian'
const AttendanceSummaryId = 'Ringkasan Kehadiran'
const ShiftWorkTimeId = 'Jadwal Shift'
const YourTimeZoneId = 'Zona Waktu Anda'
const EmployeePerDivisionId = 'Karyawan Per Divisi'
const AttendancePerDayId = 'Presensi Masuk Per Hari'
const SummaryAttendanceTimeId = 'Ringkasan Waktu Kehadiran'
const SummaryAttendanceSpotId = 'Ringkasan Lokasi Kehadiran'
const AddScheduleId = 'Tambah Jadwal'

const Dashboard = isLangEN ? DashboardEn : DashboardId
export const LangTrialPeriodRemaining = isLangEN ? 'The trial period will end on' : 'Masa percobaan akan berakhir pada'
export const LangEnjoyTrial1 = isLangEN ? (<>Welcome to your <b>14-day trial</b> period. If you have any questions,</>) : (<>Selamat mencoba masa <b>Trial 14 hari</b>, jika ada pertanyaan</>)
export const LangEnjoyTrial2 = isLangEN ? 'we are ready to assist you' : 'kami siap membantu'
export const LangSubscriptionPackageUpgrades = isLangEN ? 'Upgrade Subscription Package' : 'Upgrade Paket Langganan'
export const LangPackageUpgrade = isLangEN ? 'Package Upgrade' : 'Upgrade Paket'
export const LangFreeForeverMax2 = isLangEN ? 'Free Forever - Maximum 2 Employees' : 'Gratis Selamanya - Maksimal 2 Karyawan'
export const LangHello = isLangEN ? 'Hello' : 'Halo'
export const LangScheduleMeeting = isLangEN ? 'Schedule a free appointment with the Kerjoo team via Zoom Meeting for a 45-minute consultation to get an explanation of features and the best pricing.' : 'Agendakan jadwal meeting dan konsultasi secara gratis dengan tim Kerjoo melalui Zoom Meeting selama 45 menit untuk mendapatkan penjelasan fitur dan harga terbaik.'
export const LangScheduleNow = isLangEN ? 'SCHEDULE NOW' : 'JADWALKAN SEKARANG'
const CompanySetting = isLangEN ? CompanySettingEn : CompanySettingId
const ManageAdmin = isLangEN ? ManageAdminEn : ManageAdminId
const ManageDivision = isLangEN ? ManageDivisionEn : ManageDivisionId
const AttendanceSpot = isLangEN ? AttendanceSpotEn : AttendanceSpotId
const WorkPattern = isLangEN ? WorkPatternEn : WorkPatternId
const ManageEmployees = isLangEN ? ManageEmployeesEn : ManageEmployeesId
export const LangDeleteEmployee = isLangEN ? 'Delete Employee' : 'Hapus Karyawan'
export const LangResign = isLangEN ? 'Resign' : 'Resign'
export const LangWrongInput = isLangEN ? 'Wrong input' : 'Salah input'
export const LangExample = isLangEN ? 'Example' : 'Contoh'
export const LangDeletedEmployee = isLangEN ? 'Deleted Employee' : 'Karyawan Dihapus'
export const LangActiveEmployee = isLangEN ? 'Active Employee' : 'Karyawan Aktif'
export const LangDeletedAt = isLangEN ? 'Deleted At' : 'Dihapus Pada'
export const LangDeletedReason = isLangEN ? 'Deleted Reason' : 'Alasan Dihapus'
export const AccountDetail = isLangEN ? 'Account Detail' : 'Detail Akun'
export const LangRejectionReason = isLangEN ? 'Rejection Reason' : 'Alasan Penolakan'
const DataEmployees = isLangEN ? DataEmployeesEn : DataEmployeesId
const EmployeesDivision = isLangEN ? EmployeesDivisionEn : EmployeesDivisionId
const WorkTime = isLangEN ? WorkTimeEn : WorkTimeId
const Timesheet = isLangEN ? TimesheetEn : TimesheetId
const TimesheetList = isLangEN ? TimesheetListEn : TimesheetListId
const TimesheetReport = isLangEN ? TimesheetReportEn : TimesheetReportId
const DailyAttendance = isLangEN ? DailyAttendanceEn : DailyAttendanceId
const AttendenceSummary = isLangEN ? AttendanceSummaryEn : AttendanceSummaryId
const ShiftWorkTime = isLangEN ? ShiftWorkTimeEn : ShiftWorkTimeId
const YourTimeZone = isLangEN ? YourTimeZoneEn : YourTimeZoneId
export const SwitchMode = isLangEN ? 'Switch Mode' : 'Ganti Mode'
export const DarkMode = isLangEN ? 'Dark Mode' : 'Mode Gelap'
export const LightMode = isLangEN ? 'Light Mode' : 'Mode Terang'
const EmployeePerDivision = isLangEN ? EmployeePerDivisionEn : EmployeePerDivisionId
const AttendancePerDay = isLangEN ? AttendancePerDayEn : AttendancePerDayId
const SummaryAttendanceTime = isLangEN ? SummaryAttendanceTimeEn : SummaryAttendanceTimeId
const SummaryAttendanceSpot = isLangEN ? SummaryAttendanceSpotEn : SummaryAttendanceSpotId
const AddSchedule = isLangEN ? AddScheduleEn : AddScheduleId
const ExportExcel = isLangEN ? 'Export Excel' : 'Ekspor Excel'
export const LangOtherPayment = isLangEN ? 'Add Other Payment' : 'Tambah Pembayaran Lain'
export const LangPaymentName = isLangEN ? 'Payment Name' : 'Nama Pembayaran'
export const LangComponentName = isLangEN ? 'Component Name' : 'Nama Komponen'
export const LangPaymentPreview = isLangEN ? 'Payment Preview' : 'Preview Pembayaran'
export const LangOtherPaymentAttention = isLangEN ? 'Fill payment name and component name first.' : 'Isi nama pembayaran dan nama komponen terlebih dahulu.'
export const LangDownloadFormatOthPay = isLangEN ? 'Download data entry format.' : 'Unduh format pengisian data.'
export const LangImportPaymentFile = isLangEN ? 'Import Target Employees and Nominal' : 'Impor Target Karyawan dan Nominal'
export const LangDownloadFormatHere = isLangEN ? 'Please download the data entry format first' : 'Silakan unduh format pengisian data terlebih dahulu.'
export const LangPaymentProcess = isLangEN ? 'Payment Process' : 'Proses Pembayaran'
export const LangPaymentAmountTotal = isLangEN ? 'Payment Amount Total' : 'Total Jumlah Pembayaran'
export const LangWebhookLinkInfo = isLangEN ? 'Check out the explanation of how to set up webhooks' : 'Simak penjelasan cara mengatur webhook'

const HiEn = 'Hi'
const SearchEn = 'Search'
const RequiredEn = 'Required'
const SaveEn = 'Save'
const ActionEn = 'Action'
const EditEn = 'Edit'
const DeleteEn = 'Delete'
const ConfirmPasswordEn = 'Confirm Password'
const CloseEn = 'Close'
const NameEn = 'Name'
const PasswordEn = 'Password'
const KosongkanEn = 'Leave it blank if you don`t want to change it'
const EditDataEn = 'Edit Data'
const YesEn = 'Yes'
const NoEn = 'No'
const DayEn = 'Day'
const MaximumImgEn = 'The maximum image size is 3MB'
const IsDayEn = 'Is Day Off'
const HomeEn = 'Dashboard'
const ShiftEn = 'Shift'

const HiId = 'Halo'
const SearchId = 'Cari'
const RequiredId = 'Wajib Diisi'
const SaveId = 'Simpan'
const ActionId = 'Aksi'
const EditId = 'Ubah'
const DeleteId = 'Hapus'
const ConfirmPasswordId = 'Ulang Kata Sandi'
const CloseId = 'Tutup'
const NameId = 'Nama'
const PasswordId = 'Kata Sandi'
const KosongkanId = 'Kosongkan jika tidak diubah'
const EditDataId = 'Ubah Data'
const YesId = 'Ya'
const NoId = 'Tidak'
const DayId = 'Hari'
const MaximumImgId = 'Ukuran gambar maksimum adalah 3MB'
const IsDayId = 'Jadwal Libur'
const HomeId = 'Dasbor'
const ShiftId = 'Shift'

const Hi = isLangEN ? HiEn : HiId
const Search = isLangEN ? SearchEn : SearchId
const Action = isLangEN ? ActionEn : ActionId
const Edit = isLangEN ? EditEn : EditId
export const LangResendEmail = isLangEN ? 'Resend Email' : 'Kirim Email Ulang'
const Delete = isLangEN ? DeleteEn : DeleteId
const ConfirmPassword = isLangEN ? ConfirmPasswordEn : ConfirmPasswordId
const Close = isLangEN ? CloseEn : CloseId
const Name = isLangEN ? NameEn : NameId
const Save = isLangEN ? SaveEn : SaveId
export const SaveChange = isLangEN ? 'Save Change' : 'Simpan Perubahan'
const Password = isLangEN ? PasswordEn : PasswordId
const Kosongkan = isLangEN ? KosongkanEn : KosongkanId
const EditData = isLangEN ? EditDataEn : EditDataId
const Yes = isLangEN ? YesEn : YesId
const No = isLangEN ? NoEn : NoId
const Day = isLangEN ? DayEn : DayId
export const LangDay = isLangEN ? DayEn : DayId
const MaximumImg = isLangEN ? MaximumImgEn : MaximumImgId
const IsDay = isLangEN ? IsDayEn : IsDayId
const IsDayOff = isLangEN ? 'Is Day Off' : 'Jadwal Libur'
const Home = isLangEN ? HomeEn : HomeId
const Shift = isLangEN ? ShiftEn : ShiftId
export const LangDays = isLangEN ? 'Days' : DayId

// Dashboard
const NoStatusEn = 'No Status'
const PresentEn = 'Attendance'
const SickEn = 'Sick'
const AbsentEn = 'Absent'
const LeaveEn = 'Leave'
const TotalEn = 'Total'
const AttendanceTimeReportEn = 'Attendance Time Report'
const OnTimeEn = 'On Time'
const LateEn = 'Late'
const ToleranceEn = 'Tolerance'
const AttendanceSpotReportEn = 'Attendance Spot Report'
const OnSpotEn = 'On Spot'
const OutSpotEn = 'Out Spot'
const UndefinedSpotEn = 'Undefined Spot'
const WorkHourEn = 'Work Hour'
const WorkDayEn = 'Work Day'
const NoneEn = 'None'
const DivisionEn = 'Division'
const DivisionNameEn = 'Division Name'
const SpotEn = 'Spot'
const isOnSpotEn = 'According To The Location'
const LeavePermit7daysEn = `Leave & ${annualLeaveLabelEn} for the next 7 days`
const EmployeeRequestLeaveEn = `Employee requesting ${annualLeaveLabelEn}`
const EmployeeRequestPermitEn = 'Employee requesting leave'
const NoLeaveRequest7DaysEn = `No ${annualLeaveLabelEn} request for the next 7 days`
const NoPermitRequest7DaysEn = 'No leave request for the next 7 days'

const NoStatusId = 'Tanpa Status'
const PresentId = 'Presensi'
const SickId = 'Sakit'
const AbsentId = 'Absen'
const LeaveId = 'Izin'
const TotalId = 'Total'
const AttendanceTimeReportId = 'Laporan Waktu Kehadiran'
const OnTimeId = 'Tepat Waktu'
const LateId = 'Terlambat'
const ToleranceId = 'Toleransi'
const AttendanceSpotReportId = 'Laporan Lokasi Kehadiran'
const OnSpotId = 'Sesuai Lokasi'
const OutSpotId = 'Di Luar Lokasi'
const UndefinedSpotId = 'Tanpa Lokasi'
const WorkHourId = 'Jam Kerja'
const WorkDayId = 'Hari Kerja'
const NoneId = 'Tanpa Jam Kerja'
const DivisionId = 'Divisi'
const DivisionNameId = 'Nama Divisi'
const SpotId = 'Lokasi'
const isOnSpotId = 'Sesuai Lokasi'
const LeavePermit7daysId = `${annualLeaveLabelId} & Izin Selama 7 hari kedepan`
const EmployeeRequestLeaveId = `Karyawan ${annualLeaveLabelId}`
const EmployeeRequestPermitId = 'Karyawan Izin'
const NoLeaveRequest7DaysId = `Tidak ada yang ${annualLeaveLabelId} selama 7 hari kedepan`
const NoPermitRequest7DaysId = 'Tidak ada yang Izin selama 7 hari kedepan'

const NoStatus = isLangEN ? NoStatusEn : NoStatusId
const Present = isLangEN ? PresentEn : PresentId
const Sick = isLangEN ? SickEn : SickId
const Absent = isLangEN ? AbsentEn : AbsentId
const Leave = isLangEN ? LeaveEn : LeaveId
const Total = isLangEN ? TotalEn : TotalId
const AttendanceTimeReport = isLangEN ? AttendanceTimeReportEn : AttendanceTimeReportId
const OnTime = isLangEN ? OnTimeEn : OnTimeId
const Late = isLangEN ? LateEn : LateId
const Tolerance = isLangEN ? ToleranceEn : ToleranceId
const AttendanceSpotReport = isLangEN ? AttendanceSpotReportEn : AttendanceSpotReportId
const OnSpot = isLangEN ? OnSpotEn : OnSpotId
const OutSpot = isLangEN ? OutSpotEn : OutSpotId
const UndefinedSpot = isLangEN ? UndefinedSpotEn : UndefinedSpotId
const WorkHour = isLangEN ? WorkHourEn : WorkHourId
const WorkDay = isLangEN ? WorkDayEn : WorkDayId
const None = isLangEN ? NoneEn : NoneId
const Division = isLangEN ? DivisionEn : DivisionId
const DivisionName = isLangEN ? DivisionNameEn : DivisionNameId
const Required = isLangEN ? RequiredEn : RequiredId
const Spot = isLangEN ? SpotEn : SpotId
export const LangAttendanceLocation = isLangEN ? 'Attendance Location' : 'Lokasi Kehadiran'
const isOnSpot = isLangEN ? isOnSpotEn : isOnSpotId
const LeavePermit7days = isLangEN ? LeavePermit7daysEn : LeavePermit7daysId
export const LangEmployeeBirthday = isLangEN ? 'Employee Birthday' : 'Ulang Tahun Karyawan'
export const LangTodayBirthday = isLangEN ? 'Birthday Today' : 'Ulang Tahun Hari Ini'
export const LangTomorrowBirthday = isLangEN ? 'Birthday Tomorrow' : 'Ulang Tahun Besok'
export const LangNoBirthdayToday = isLangEN ? 'Nobody has a birthday today' : 'Tidak ada yang ulang tahun hari ini'
export const LangNoBirthdayTomorrow = isLangEN ? 'Nobody has a birthday tomorrow' : 'Tidak ada yang ulang tahun besok'
export const LangNoBirthday = isLangEN ? 'Nobody has a birthday' : 'Tidak ada yang ulang tahun'
export const LangYear = isLangEN ? 'Year' : 'Tahun'
const EmployeeRequestLeave = isLangEN ? EmployeeRequestLeaveEn : EmployeeRequestLeaveId
const EmployeeRequestPermit = isLangEN ? EmployeeRequestPermitEn : EmployeeRequestPermitId
const NoLeaveRequest7Days = isLangEN ? NoLeaveRequest7DaysEn : NoLeaveRequest7DaysId
const NoPermitRequest7Days = isLangEN ? NoPermitRequest7DaysEn : NoPermitRequest7DaysId
export const LangUnpaidLeave = isLangEN ? 'Unpaid Leave' : 'Izin'
export const LangLeaveDay = isLangEN ? 'Leave Day' : 'Izin Hari'

// ManageAdmin
const AddNewAdminEn = 'Add New Admin'

const AddNewAdminId = 'Tambah Admin'

const AddNewAdmin = isLangEN ? AddNewAdminEn : AddNewAdminId

// Company Setting
const CompanyLogoEn = 'Company Logo'
const CompanyNameEn = 'Company Name'
const BussinessFieldsEn = 'Bussiness Fields'
const EmployeEn = 'Employee'
const AddressEn = 'Address'
const ProvinceEn = 'Choose Province'
const CityEn = 'City / Regency'
const DistrictEn = 'District'
const VillagesEn = 'Villages'
const TimeZoneEn = 'Time Zone'
const ZipCodeEn = 'Zip Code'
const PhoneNumberEn = 'Phone Number'
const FaxNumberEn = 'Fax Number'
const MobileAplicationEn = 'Mobile Application'
const LangShowSecondAttendaceEn = 'Show Seconds'
const LangAttendanceSettingEn = 'Attendance'

const CompanyLogoId = 'Logo Perusahaan'
const CompanyNameId = 'Nama Perusahaan'
const BussinessFieldsId = 'Bidang Usaha'
const EmployeId = 'Karyawan'
const AddressId = 'Alamat'
const ProvinceId = 'Provinsi'
const CityId = 'Kabupaten / Kota'
const DistrictId = 'Kecamatan'
const VillagesId = 'Kelurahan'
const TimeZoneId = 'Zona Waktu'
const ZipCodeId = 'Kode Pos'
const PhoneNumberId = 'Nomor Telepon'
const FaxNumberId = 'Nomor Fax'
const MobileAplicationId = 'Aplikasi Mobile'
const LangShowSecondAttendaceIn = 'Tampilkan Detik Presensi'
const LangAttendanceSettingIn = 'Presensi'

const CompanyLogo = isLangEN ? CompanyLogoEn : CompanyLogoId
const CompanyName = isLangEN ? CompanyNameEn : CompanyNameId
export const CompanyAddress = isLangEN ? 'Company Address' : 'Alamat Perusahaan'
const BussinessFields = isLangEN ? BussinessFieldsEn : BussinessFieldsId
const Employe = isLangEN ? EmployeEn : EmployeId
export const TotalEmployees = isLangEN ? 'Total Employees' : 'Total Karyawan'
export const TotalEmployees2 = isLangEN ? 'The total number of employees' : 'Total Karyawan'
export const TotalEmployeesWorkDay = isLangEN ? 'Total Employee Workdays' : 'Total Karyawan Hari Kerja'
export const TotalShiftEmployees = isLangEN ? 'Total Shift Employees' : 'Total Karyawan Shift'
const Address = isLangEN ? AddressEn : AddressId
export const AdditionalField = isLangEN ? 'Additional Fields' : 'Field Tambahan'
const Province = isLangEN ? ProvinceEn : ProvinceId
const City = isLangEN ? CityEn : CityId
const District = isLangEN ? DistrictEn : DistrictId
const Villages = isLangEN ? VillagesEn : VillagesId
const TimeZone = isLangEN ? TimeZoneEn : TimeZoneId
const ZipCode = isLangEN ? ZipCodeEn : ZipCodeId
const PhoneNumber = isLangEN ? PhoneNumberEn : PhoneNumberId
const FaxNumber = isLangEN ? FaxNumberEn : FaxNumberId
const MobileAplication = isLangEN ? MobileAplicationEn : MobileAplicationId
export const LangAttendanceSetting = isLangEN ? LangAttendanceSettingEn : LangAttendanceSettingIn
export const LangShowSecondAttendance = isLangEN ? LangShowSecondAttendaceEn : LangShowSecondAttendaceIn

// Manage Division
const LateAttendancePunishmentEn = 'Late Penalty'
const EmployeesCountEn = 'Number of employees'
const WorkSystemEn = 'Work System'
const AddNewDivisionEn = 'Add New Division'
const ShiftDayEn = 'Shift Day'
const WarningWorkSystemEn = 'Work system cannot be changed, please be careful.'

const LateAttendancePunishmentId = 'Denda Keterlambatan'
const EmployeesCountId = 'Jumlah Karyawan'
const WorkSystemId = 'Sistem Kerja'
const AddNewDivisionId = 'Tambah Divisi'
const ShiftDayId = 'Shift'
const WarningWorkSystemId = 'Sistem kerja tidak dapat diubah, harap berhati-hati.'

const LateAttendancePunishment = isLangEN ? LateAttendancePunishmentEn : LateAttendancePunishmentId
const EmployeesCount = isLangEN ? EmployeesCountEn : EmployeesCountId
export const EmployeesCount2 = isLangEN ? 'Number of employees' : EmployeesCountId
const WorkSystem = isLangEN ? WorkSystemEn : WorkSystemId
const AddNewDivision = isLangEN ? AddNewDivisionEn : AddNewDivisionId
const ShiftDay = isLangEN ? ShiftDayEn : ShiftDayId
const WarningWorkSystem = isLangEN ? WarningWorkSystemEn : WarningWorkSystemId

// AttendanceSpot

const TotalAssignEmployeesEn = 'Total Assign Employees'
const SpotNameEn = 'Location name'
const SelectDivisionEn = 'Select Division'
const AllEmployeesEn = 'All Division'
const AddNewSpotEn = 'Add New Location'
const SearchLocationEn = 'Search Location'

const TotalAssignEmployeesId = 'Jumlah Karyawan Ditugaskan'
const SpotNameId = 'Nama Lokasi'
const SelectDivisionId = 'Pilih Divisi'
const AllEmployeesId = 'Semua Divisi'
const AddNewSpotId = 'Tambah Lokasi'
const SearchLocationId = 'Cari Lokasi'

const TotalAssignEmployees = isLangEN ? TotalAssignEmployeesEn : TotalAssignEmployeesId
const SpotName = isLangEN ? SpotNameEn : SpotNameId
const SelectDivision = isLangEN ? SelectDivisionEn : SelectDivisionId
export const SelectClient = isLangEN ? 'Select Client' : 'Pilih Klien'
const AllEmployees = isLangEN ? AllEmployeesEn : AllEmployeesId
const AddNewSpot = isLangEN ? AddNewSpotEn : AddNewSpotId
const SearchLocation = isLangEN ? SearchLocationEn : SearchLocationId
export const SearchEmployee = isLangEN ? 'Search Employee' : 'Cari Karyawan'
export const EmployeeNotFound = isLangEN ? 'Employee Not Found' : 'Karyawan Tidak Ditemukan'

// Work Pattern
const ClockInEn = 'Clock In'
const ClockOutEn = 'Clock Out'
const NameOfWorkPatternEn = 'Name of Work Pattern'
const NumberofDayinCycleEn = 'Number of Day in Cycle'
const TardinessToleranceEn = 'Tardiness Tolerance'
const AddWorkPatternEn = 'Add Work Pattern'
const SundayEn = 'Sunday'
const MondayEn = 'Monday'
const TuesdayEn = 'Tuesday'
const WednesdayEn = 'Wednesday'
const ThursdayEn = 'Thursday'
const FridayEn = 'Friday'
const SaturdayEn = 'Saturday'
const MinutesEn = 'Minutes'
const WorkingDayEn = 'Working Day'
const DayOffEn = 'Day Off'
const WorkScheduleEn = 'Work Schedule'
const ColorEn = 'Color'
const HoursEn = 'Hours'

const ClockInId = 'Presensi Masuk'
const ClockOutId = 'Presensi Keluar'
const NameOfWorkPatternId = 'Nama Pola Kerja'
const NumberofDayinCycleId = 'Jumlah Siklus Hari'
const TardinessToleranceId = 'Toleransi Keterlambatan'
const AddWorkPatternId = 'Tambah Pola Kerja'
const SundayId = 'Minggu'
const MondayId = 'Senin'
const TuesdayId = 'Selasa'
const WednesdayId = 'Rabu'
const ThursdayId = 'Kamis'
const FridayId = 'Jumat'
const SaturdayId = 'Sabtu'
const MinutesId = 'Menit'
const WorkingDayId = 'Hari Kerja'
const DayOffId = 'Jadwal Libur'
const WorkScheduleId = 'Jadwal Kerja'
const ColorId = 'Warna'
const HoursId = 'Jam'

const ClockIn = isLangEN ? ClockInEn : ClockInId
const ClockOut = isLangEN ? ClockOutEn : ClockOutId
const NameOfWorkPattern = isLangEN ? NameOfWorkPatternEn : NameOfWorkPatternId
const NumberofDayinCycle = isLangEN ? NumberofDayinCycleEn : NumberofDayinCycleId
const TardinessTolerance = isLangEN ? TardinessToleranceEn : TardinessToleranceId
const AddWorkPattern = isLangEN ? AddWorkPatternEn : AddWorkPatternId
export const AddClient = isLangEN ? 'Add Client' : 'Tambah Klien'
export const EditClient = isLangEN ? 'Edit Client' : 'Ubah Klien'
const Sunday = isLangEN ? SundayEn : SundayId
const Monday = isLangEN ? MondayEn : MondayId
const Tuesday = isLangEN ? TuesdayEn : TuesdayId
const Wednesday = isLangEN ? WednesdayEn : WednesdayId
const Thrusday = isLangEN ? ThursdayEn : ThursdayId
const Friday = isLangEN ? FridayEn : FridayId
const Saturday = isLangEN ? SaturdayEn : SaturdayId
const Minutes = isLangEN ? MinutesEn : MinutesId
const Seconds = isLangEN ? 'Seconds' : 'Detik'
const WorkingDay = isLangEN ? WorkingDayEn : WorkingDayId
export const WorkingDay2 = isLangEN ? 'Working Days' : WorkingDayId
const DayOff = isLangEN ? DayOffEn : DayOffId
const WorkSchedule = isLangEN ? WorkScheduleEn : WorkScheduleId
const Color = isLangEN ? ColorEn : ColorId
const Hours = isLangEN ? HoursEn : HoursId
export const LangHours = isLangEN ? HoursEn : HoursId
export const LangIncorrectTimetable = isLangEN ? 'Clock Out cannot be less than Clock In. Use shift work pattern for more flexible input working time until the next day.' : 'Pengaturan jam keluar tidak boleh kurang dari jam masuk. Silakan gunakan pola kerja shift yang lebih fleksibel untuk input waktu kerja sampai hari berikutnya.'
export const LangEmptyTimetable = isLangEN ? 'Clock In/Clock Out cannot be empty if Work Day.' : 'Jam Masuk/Jam Keluar tidak boleh kosong selama Hari Kerja.'

// Manage Employees
const GenderEn = 'Gender'
const MaleEn = 'Male'
const FemaleEn = 'Female'
const StatusNoteEn = 'Status Note'
const StartWorkEn = 'Start Date of Employment'
const GroupEn = 'Division'
const EmployeIdentityIDEn = 'Employee Identification Type'
const EmployeIDEn = 'Employee Identification Number'
const EmployeIDFileEn = 'Employee Identification Document'
const ProfilePictureEn = 'Profile Picture'
const PhoneCCEn = 'Phone CC'
const AddNewEmployeesEn = 'Add New Employees'
const CreatedAtEn = 'Created at'
const UpdatedAtEn = 'Updated an'

const GenderId = 'Jenis Kelamin'
const MaleId = 'Laki-laki'
const FemaleId = 'Perempuan'
const StatusNoteId = 'Catatan Status'
const StartWorkId = 'Tgl. Mulai Bekerja'
const GroupId = 'Divisi'
const EmployeIdentityIDId = 'Jenis Identitas Karyawan'
const EmployeIDId = 'Nomor Identitas Karyawan'
const EmployeIDFileId = 'File Identitas Karyawan'
const ProfilePictureId = 'Foto Profil'
const PhoneCCId = 'Kode Negara'
const AddNewEmployeesId = 'Tambah Karyawan'
const CreatedAtId = 'Dibuat Pada'
const UpdatedAtId = 'Terakhir Diubah'

const Gender = isLangEN ? GenderEn : GenderId
const Male = isLangEN ? MaleEn : MaleId
const Female = isLangEN ? FemaleEn : FemaleId
const StatusNote = isLangEN ? StatusNoteEn : StatusNoteId
const StartWork = isLangEN ? StartWorkEn : StartWorkId
export const StartWork2 = isLangEN ? 'Start Date of Work' : StartWorkId
export const LangBirthDate = isLangEN ? 'Date of Birth' : 'Tanggal Lahir'
const Group = isLangEN ? GroupEn : GroupId
const EmployeIdentityID = isLangEN ? EmployeIdentityIDEn : EmployeIdentityIDId
const EmployeID = isLangEN ? EmployeIDEn : EmployeIDId
const EmployeIDFile = isLangEN ? EmployeIDFileEn : EmployeIDFileId
const ProfilePicture = isLangEN ? ProfilePictureEn : ProfilePictureId
const PhoneCC = isLangEN ? PhoneCCEn : PhoneCCId
const AddNewEmployees = isLangEN ? AddNewEmployeesEn : AddNewEmployeesId
const CreatedAt = isLangEN ? CreatedAtEn : CreatedAtId
const UpdatedAt = isLangEN ? UpdatedAtEn : UpdatedAtId

const LangGeneral = isLangEN ? 'General' : 'Umum'
const LangBirthPlace = isLangEN ? 'Place of Birth' : 'Tempat Lahir'
const LangMaritalStatus = isLangEN ? 'Marital Status' : 'Status Perkawinan'
const LangReligion = isLangEN ? 'Religion' : 'Agama'
const LangBloodType = isLangEN ? 'Blood Type' : 'Golongan Darah'
const LangIdentityType = isLangEN ? 'Identification Type' : 'Jenis Identitas'
const LangIdentityNumber = isLangEN ? 'Identification Number' : 'Nomor Identitas'
const LangIdentityAddress = isLangEN ? 'Identity Address' : 'Alamat KTP'
const LangIdentityProvince = isLangEN ? 'Identity Province' : 'Alamat KTP Provinsi'
const LangIdentityCity = isLangEN ? 'Identity City' : 'Alamat KTP Kota'
const LangDomicileAddress = isLangEN ? 'Domicile Address' : 'Alamat Domisili'
const LangDomicileProvince = isLangEN ? 'Domicile Province' : 'Alamat Domisili Provinsi'
const LangDomicileCity = isLangEN ? 'Domicile City' : 'Alamat Domisili Kota'
const LangRecentEducation = isLangEN ? 'Recent Education' : 'Pendidikan Terakhir'
const LangEducationLevel = isLangEN ? 'Education Level' : 'Jenjang'
const LangEducationMajor = isLangEN ? 'Major' : 'Jurusan'
const LangInstitutionName = isLangEN ? 'Institution Name' : 'Nama Institusi Pendidikan'
const LangGraduationYear = isLangEN ? 'Year of Graduation' : 'Tahun Lulus'
const LangEmployeeAffair = isLangEN ? 'Employee Affair' : 'Kepegawaian'
const LangEmployeeId = isLangEN ? 'Employee ID' : 'Nomor Induk Karyawan'
const LangEmployeeIdentityFile = isLangEN ? 'Additional Identification File' : 'File Identitas Tambahan'
const LangAddFile = isLangEN ? 'Add File' : 'Tambah File'
const LangFileType = isLangEN ? 'File Type' : 'Jenis'
const LangFileIdNumber = isLangEN ? 'ID Number' : 'Nomor'
const LangEmployeeFile = isLangEN ? 'File' : 'File'
const LangPersonalInformation = isLangEN ? 'Personal Information' : 'Informasi Pribadi'
const LangOvertime = isLangEN ? 'Overtime' : 'Lembur'
const LangNoDataAvailable = isLangEN ? 'No Data Available' : 'Belum ada data'
export const LangGenBabyBoomers = isLangEN ? 'Gen Baby Boomers' : 'Gen Baby Boomers'
export const LangGenX = isLangEN ? "Gen X 'Bust'" : "Gen X 'Bust'"
export const LangGenY = isLangEN ? "Gen Y 'Millennials'" : "Gen Y 'Milenial'"
export const LangGenZ = isLangEN ? 'Gen Z' : 'Gen Z'
export const LangGenAlpha = isLangEN ? 'Gen Alpha' : 'Gen Alpha'
export const LangSameAdddress = isLangEN ? 'Same as identity address' : 'Sama dengan alamat KTP'
export const LangAutomaticUpdates = isLangEN ? 'Automatic Updates' : 'Pembaruan Otomatis'

// Work Time
const DayAmountEn = 'Day Amount'
const EditWorkPatternEn = 'Edit Work Time'
const JanuaryEn = 'January'
const FebruaryEn = 'February'
const MarchEn = 'March'
const AprilEn = 'April'
const MayEn = 'May'
const JuneEn = 'June'
const JulyEn = 'July'
const AugustEn = 'August'
const SeptemberEn = 'September'
const OctoberEn = 'October'
const NovemberEn = 'November'
const DecemberEn = 'December'

const EditScheduleEn = 'Edit Schedule'

const JanuaryId = 'Januari'
const FebruaryId = 'Februari'
const MarchId = 'Maret'
const AprilId = 'April'
const MayId = 'Mei'
const JuneId = 'Juni'
const JulyId = 'Juli'
const AugustId = 'Agustus'
const SeptemberId = 'September'
const OctoberId = 'Oktober'
const NovemberId = 'November'
const DecemberId = 'Desember'
const DayAmountId = 'Jumlah Hari'
const EditWorkPatternId = 'Ubah Waktu Kerja'

const EditScheduleId = 'Edit Jadwal'

const DayAmount = isLangEN ? DayAmountEn : DayAmountId
const EditWorkPattern = isLangEN ? EditWorkPatternEn : EditWorkPatternId
const January = isLangEN ? JanuaryEn : JanuaryId
const February = isLangEN ? FebruaryEn : FebruaryId
const March = isLangEN ? MarchEn : MarchId
const April = isLangEN ? AprilEn : AprilId
const May = isLangEN ? MayEn : MayId
const June = isLangEN ? JuneEn : JuneId
const July = isLangEN ? JulyEn : JulyId
const August = isLangEN ? AugustEn : AugustId
const September = isLangEN ? SeptemberEn : SeptemberId
const October = isLangEN ? OctoberEn : OctoberId
const November = isLangEN ? NovemberEn : NovemberId
const December = isLangEN ? DecemberEn : DecemberId
const EditSchedule = isLangEN ? EditScheduleEn : EditScheduleId

// Daily Attendance
const BreakEn = 'Break'
const AfterBreakEn = 'After Break'
const OvertimeInEn = 'Overtime In'
const OvertimeOutEn = 'Overtime Out'
const NoteEn = 'Note'
const ChangeHistoriesEn = 'Change Histories'
const DescChangeHistoriesEn = 'Description'
const DateChangeHistoriesEn = 'Date'
const EditWorkScheduleEn = 'Edit Work Schedule'
const deleteWorkScheduleEn = 'Delete Work Schedule'
const langSureDoDeleteScheduleEn = 'Are you sure you want to delete Work Schedule on this presence?'

const BreakId = 'Mulai Istirahat'
const AfterBreakId = 'Selesai Istirahat'
const OvertimeInId = 'Mulai Lembur'
const OvertimeOutId = 'Selesai Lembur'
const NoteId = 'Catatan'
const ChangeHistoriesId = 'Riwayat Perubahan'
const DescChangeHistoriesId = 'Perubahan'
const DateChangeHistoriesId = 'Tgl. Perubahan'
const EditWorkScheduleId = 'Ubah Jadwal Kerja'
const deleteWorkScheduleId = 'Hapus Jadwal Kerja'
const langSureDoDeleteScheduleId = 'Yakin akan menghapus Jadwal Kerja pada presensi ini?'

const Break = isLangEN ? BreakEn : BreakId
const AfterBreak = isLangEN ? AfterBreakEn : AfterBreakId
export const maxBreak = isLangEN ? 'Max. Break Minutes' : 'Maks. Menit Istirahat'
export const maxBreakHelp = isLangEN ? 'Attendance after the break will be carried out automatically after the maximum rest minutes are met.' : 'Presensi selesai istirahat akan dijalankan secara otomatis setelah maksimal menit istirahat terpenuhi.'
export const breakStart = isLangEN ? 'Break Start' : 'Istirahat Mulai'
export const breakMax = isLangEN ? 'Break max.' : 'Istirahat maks.'
const OvertimeIn = isLangEN ? OvertimeInEn : OvertimeInId
const OvertimeOut = isLangEN ? OvertimeOutEn : OvertimeOutId
const Note = isLangEN ? NoteEn : NoteId
const ChangeHistories = isLangEN ? ChangeHistoriesEn : ChangeHistoriesId
export const LangVisitHistory = isLangEN ? 'Visit History' : 'Riwayat Kunjungan'
const DescChangeHistories = isLangEN ? DescChangeHistoriesEn : DescChangeHistoriesId
const DateChangeHistories = isLangEN ? DateChangeHistoriesEn : DateChangeHistoriesId
const EditWorkSchedule = isLangEN ? EditWorkScheduleEn : EditWorkScheduleId
const deleteWorkSchedule = isLangEN ? deleteWorkScheduleEn : deleteWorkScheduleId
const langSureDoDeleteSchedule = isLangEN ? langSureDoDeleteScheduleEn : langSureDoDeleteScheduleId

// Attendance Summary
const AttendanceDaysEn = 'Attendance Days'
const WorkHoursEn = 'Work Hours'
const BreakHoursEn = 'Break Hours'
const OvertimeHoursEn = 'Overtime Hours'
const LateHoursEn = 'Late Hours'
const LateDaysEn = 'Late Days'
const LateToleranceDaysEn = 'Late Tolerance Days'
const NotCompleteDaysEn = 'Not Complete Days'
const LateToleranceHoursEn = 'Late Tolerance Hours'

const AddNoteEn = 'Add Note'

const AttendanceDaysId = 'Total Hari Kehadiran'
const WorkHoursId = 'Total Jam Kerja'
const BreakHoursId = 'Total Jam Istirahat'
const OvertimeHoursId = 'Total Jam Lembur'
const LateHoursId = 'Total Jam Terlambat'
const LateDaysId = 'Total Hari Terlambat'
const LateToleranceDaysId = 'Total Hari Toleransi'
const NotCompleteDaysId = 'Jam Kerja Tidak Terpenuhi'
const LateToleranceHoursId = 'Total Jam Toleransi'

const AddNoteId = 'Tambah Catatan'

const AttendanceDays = isLangEN ? AttendanceDaysEn : AttendanceDaysId
const WorkHours = isLangEN ? WorkHoursEn : WorkHoursId
const BreakHours = isLangEN ? BreakHoursEn : BreakHoursId
export const BreakHour = isLangEN ? 'Break Hour' : 'Jam Istirahat'
const OvertimeHours = isLangEN ? OvertimeHoursEn : OvertimeHoursId
const LateHours = isLangEN ? LateHoursEn : LateHoursId
const LateDays = isLangEN ? LateDaysEn : LateDaysId
const LateToleranceDays = isLangEN ? LateToleranceDaysEn : LateToleranceDaysId
export const TotalDaysAutoClockOut = isLangEN ? 'Total Days Auto Clock Out' : 'Total Hari Presensi Keluar Otomatis'
const NotCompleteDays = isLangEN ? NotCompleteDaysEn : NotCompleteDaysId
const LateToleranceHours = isLangEN ? LateToleranceHoursEn : LateToleranceHoursId
const AddNote = isLangEN ? AddNoteEn : AddNoteId
const LangTotalLeaveDays = isLangEN ? `${annualLeaveLabelEn} Days` : `Total Hari ${annualLeaveLabelId}`
const LangTotalPermitDays = isLangEN ? 'Leave Days' : 'Total Hari Izin'
const LangTotalNotClockInDays = isLangEN ? 'Not Clock In Days' : 'Total Hari Tidak Masuk'
const LangChooseEmployee = isLangEN ? 'Choose Employee' : 'Pilih Karyawan'
export const LangPleaseChooseEmployee = isLangEN ? 'Please choose an employee first' : 'Silakan pilih karyawan terlebih dahulu'
const LangLoginSupervisorInfo = isLangEN ? 'You are logged in as a supervisor in the following divisions:' : 'Anda masuk sebagai supervisor pada divisi'

// Attendance Review
const AttendanceReviewEn = 'Attendance Review'
const GpsStatusEn = 'Gps Status'
const MarkedEn = 'Marked'
const UnmarkedEn = 'Unmarked'
const MarkEn = 'Mark'
const DateLangEn = 'Date'
const TypeEn = 'Type'
const TimeEn = 'Time'
const SelfieEn = 'Selfie'

const AttendanceReviewId = 'Review Kehadiran'
const GpsStatusId = 'Status Gps'
const MarkedId = 'Sudah Dicek'
const UnmarkedId = 'Perlu Dicek'
const MarkId = 'Tandai Sudah Dicek'
const DateLangId = 'Tanggal'
const TypeId = 'Tipe'
const TimeId = 'Waktu'
const SelfieId = 'Swafoto'

const AttendanceReview = isLangEN ? AttendanceReviewEn : AttendanceReviewId
const GpsStatus = isLangEN ? GpsStatusEn : GpsStatusId
const Marked = isLangEN ? MarkedEn : MarkedId
const Unmarked = isLangEN ? UnmarkedEn : UnmarkedId
const Mark = isLangEN ? MarkEn : MarkId
const DateLang = isLangEN ? DateLangEn : DateLangId
const Type = isLangEN ? TypeEn : TypeId
export const LangRequired = isLangEN ? 'Required' : 'Wajib'
const Time = isLangEN ? TimeEn : TimeId
const Selfie = isLangEN ? SelfieEn : SelfieId
// const AttendanceReviewInfo = isLangEN ? AttendanceReviewInfoEn : AttendanceReviewInfoId
const AttendanceReviewInfo = isLangEN
  ? `The data shown when:
  \u26AC First time attendance
  \u26AC Located outside the designated attendance location
  \u26AC Using a different device than before
`
  : `Data yang ditampilkan adalah saat:
  \u26AC Presensi pertamakali
  \u26AC Berada di luar lokasi kehadiran yang sudah diatur
  \u26AC Menggunakan device yang berbeda dengan sebelumnya
`
export const LangWorkTimeOut = isLangEN ? 'Clock Out Date' : 'Tanggal Presensi Keluar'
const PrevDay = isLangEN ? 'Prev Day' : 'Hari sebelumnya'
const NextDay = isLangEN ? 'Next Day' : 'Hari berikutnya'
const ShortHour = isLangEN ? 'Hr' : 'Jm'
const ShortMinute = isLangEN ? 'Min' : 'Mn'
const PrevWeek = isLangEN ? 'Prev week' : 'Minggu sebelumnya'
const NextWeek = isLangEN ? 'Next week' : 'Minggu berikutnya'
const UseTimezoneCompany = isLangEN ? 'Use Timezone Company' : 'Menggunakan Zona Waktu Perusahaan'
const TypeKeyword = isLangEN ? 'Type keyword' : 'Tulis kata kunci'
export const LangKeyword = isLangEN ? 'Keyword' : 'Kata kunci'
const NoOptions = isLangEN ? 'No Options' : 'Tidak ada pilihan'
export const Options = isLangEN ? 'Options' : 'Pilihan'
export const AddOption = isLangEN ? 'Add Option' : 'Tambah Pilihan'
export const ChooseType = isLangEN ? 'Choose Type' : 'Pilih Tipe'
export const LangTipCheckboxField = isLangEN ? 'Options, can select more than one' : 'Pilihan, dapat memilih lebih dari satu'
export const LangTipComboboxField = isLangEN ? 'Options, can only choose one' : 'Pilihan, hanya dapat memilih salah satu'
const PleaseWait = isLangEN ? 'Please wait' : 'Mohon tunggu'
const SpecificDate = isLangEN ? 'Specific Date' : 'Tanggal Tertentu'
const RangeDate = isLangEN ? 'Range Date' : 'Rentang Waktu'
const Process = isLangEN ? 'Process' : 'Proses'
const Cancel = isLangEN ? 'Cancel' : 'Batal'
const NoteOnAttendIn = isLangEN ? 'Note on attendance in' : 'Catatan Saat Presensi Masuk'
const NoteOnAttendOut = isLangEN ? 'Note on attendance out' : 'Catatan Saat Presensi Keluar'
const NoteOnAttendStartOvertime = isLangEN ? 'Note on Overtime Clock-In' : 'Catatan Saat Presensi Mulai Lembur'
const NoteOnAttendFinishOvertime = isLangEN ? 'Note on Overtime Clock-Out' : 'Catatan Saat Presensi Selesai Lembur'
const NoteNotRequired = isLangEN ? 'Not Required' : 'Tidak Dibutuhkan'
const NoteOptional = isLangEN ? 'Optional' : 'Opsional'
const NoteRequired = isLangEN ? 'Required' : 'Dibutuhkan'
const NoteMsgInstruction = isLangEN ? 'Message instruction' : 'Pesan Instruksi'
const EmailConfirmation = isLangEN ? 'Make sure the email address you write is correct.' : 'Pastikan alamat email yang Anda tulis sudah benar.'
const SpotAssignPersonnel = isLangEN ? 'Assigned Employees' : 'Karyawan Ditugaskan'
const SpotAssignSpecificDivision = isLangEN ? 'Assign To Division' : 'Spesifik Divisi'
const SpotAssignSpecificPersonnel = isLangEN ? 'Assign To Employee' : 'Spesifik Karyawan'
const SpotAssignAll = isLangEN ? 'All Employees' : 'Semua Karyawan'
export const LangChangeSpcEmployee = isLangEN ? 'Employee Specific Changes' : 'Perubahan Spesifik Karyawan'
export const LangChangeSpcGroup = isLangEN ? 'Division Specific Changes' : 'Perubahan Spesifik Divisi'
const LangOn = isLangEN ? 'on' : 'pada'
const LangLeaveRemainingDay = isLangEN ? 'Remaining Annual Leave' : `Sisa ${annualLeaveLabelId}`
const LangLeaveExpiredAt = isLangEN ? 'End Date of Annual Leave' : `Tgl. ${annualLeaveLabelId} Berakhir`
export const LangLeaveUpdate = isLangEN ? 'Auto Reload Annual Leave Settings' : 'Pengaturan Pembaruan Cuti Otomatis'
export const LangSetWorkStartDate = isLangEN ? 'set the starting date of employment' : 'atur Tgl. Mulai Bekerja'
export const LangFirstThing = isLangEN ? 'first.' : 'terlebih dahulu.'
export const LangNoPrevSettings = isLangEN ? 'There are no previous settings,' : 'Belum terdapat pengaturan sebelumnya,'
export const LangStartSetup = isLangEN ? 'Start setup' : 'Mulai pengaturan'
export const LangStartWorkOn = isLangEN ? 'Employees started work on' : 'Karyawan mulai bekerja pada'
export const LangFirstGetAnnualLeave = isLangEN ? 'first get annual leave on' : 'pertama kali mendapatkan cuti pada tanggal'
export const LangAutoAdded = isLangEN ? 'The remaining leave will be automatically added by' : 'Sisa cuti akan otomatis ditambahkan sebanyak'
export const LangOnWhen = isLangEN ? 'on' : 'pada'
export const LangAutoAddToday = isLangEN ? 'Since the execution time for automatic additions falls on today, the additions will be executed immediately after this setting is saved.' : 'Karena waktu eksekusi penambahan otomatis jatuh pada hari ini, maka penambahan akan langsung dilakukan setelah pengaturan ini disimpan.'
export const LangLeaveTransactionHistory = isLangEN ? 'Annual Leave Transaction History' : 'Riwayat Transaksi Cuti'
export const LangAutomaticRenewalPeriod = isLangEN ? 'Automatic Renewal Period' : 'Periode Pembaruan Otomatis'
export const LangRemainingLeave = isLangEN ? 'Remaining Leave' : 'Sisa Cuti Sebelumnya'
export const LangAdded = isLangEN ? 'Added' : 'Ditambahkan'
export const LangTurnOnSettings = isLangEN ? 'Turn on Settings' : 'Aktifkan Pengaturan'
export const LangBalance = isLangEN ? 'Balance' : 'Saldo'
export const LangBalanceReqLeave = isLangEN ? 'Remaining leave days' : 'Saldo'
export const LangAmount = isLangEN ? 'Amount' : 'Jumlah'
export const LangAmountReqLeave = isLangEN ? 'Numbers of requested leave days' : 'Jumlah'
export const LangTransactionDate = isLangEN ? 'Date of Submission' : 'Tanggal Pengajuan'
export const LangEmergencyContact = isLangEN ? 'Emergency Contact' : 'Kontak Darurat'
export const LangContact = isLangEN ? 'Contact' : 'Kontak'
export const LangAddContact = isLangEN ? 'Add Contact' : 'Tambah Kontak'
const LangProfile = isLangEN ? 'Profile' : 'Profil'
const LangAccount = isLangEN ? 'Mobile Application Login Account' : 'Akun Masuk Aplikasi Mobile'
export const LangDownloadAppInfo = isLangEN ? 'Kerjoo mobile application can be downloaded via:' : 'Aplikasi mobile kerjoo dapat diunduh melalui:'
const LangPasswordNotEquals = isLangEN ? 'Password not equals' : 'Kata Sandi tidak sama'
const LangLeave = isLangEN ? `${annualLeaveLabelEn}` : `${annualLeaveLabelId}`
const LangLeaveRequest = isLangEN ? 'Request' : 'Pengajuan'
const LangLeaveApproved = isLangEN ? 'Approved' : 'Disetujui'
const LangLeaveRejected = isLangEN ? 'Rejected' : 'Ditolak'
export const LangRejectedBy = isLangEN ? 'Rejected by' : 'Ditolak oleh'
export const LangPending = isLangEN ? 'Pending' : 'Menunggu persetujuan'
const LangLeaveToday = isLangEN ? 'Today' : `${annualLeaveLabelId} Hari Ini`
const LangLeaveTomorrow = isLangEN ? 'Tomorrow' : `${annualLeaveLabelId} Besok`
const LangLeaveNext7Day = isLangEN ? 'Next 7 Days' : `${annualLeaveLabelId} 7 Hari Kedepan`
const LangEmployee = isLangEN ? 'Employee' : 'Karyawan'
export const LangApplicantemployees = isLangEN ? 'Requester Employee' : 'Karyawan Pemohon'
export const LangApplicantSchedule = isLangEN ? 'Requester Schedule' : 'Jadwal Pemohon'
export const LangTargetemployees = isLangEN ? 'Target Employee' : 'Karyawan Target'
export const LangTargetSchedule = isLangEN ? 'Target Schedule' : 'Jadwal Target'
export const LangDateCreated = isLangEN ? 'Date Created' : 'Tanggal Dibuat'
const LangLeaveCreatedAt = isLangEN ? 'Request Date' : 'Tgl. Pengajuan'
const LangLeaveDates = isLangEN ? `${annualLeaveLabelEn} Date` : `Tgl. ${annualLeaveLabelId}`
export const LangTotalDay = isLangEN ? 'Total Days' : 'Total Hari'
const LangLeaveDesc = isLangEN ? 'Necessity' : 'Keperluan'
const LangAction = isLangEN ? 'Action' : 'Aksi'
const LangConfirm = isLangEN ? 'Confirmation' : 'Konfirmasi'
const LangLeaveRequestApproveConfirm = isLangEN ? `Are you sure want to approve this ${annualLeaveLabelEn} request?` : `Anda yakin akan menerima pengajuan ${annualLeaveLabelId} ini?`
const LangLeaveYesSure = isLangEN ? 'Yes sure' : 'Ya yakin'
const LangLeaveNotNow = isLangEN ? 'Not Now' : 'Nanti dulu'
const LangLeaveApprovedAt = isLangEN ? 'Approve date' : 'Tgl. Disetujui'
const LangLeaveRejectedAt = isLangEN ? 'Reject date' : 'Tgl. Ditolak'
const LangReason = isLangEN ? 'Reason' : 'Alasan'
const LangOptional = isLangEN ? 'Optional' : 'Opsional'
const LangProcess = isLangEN ? 'Process' : 'Proses'
const LangBack = isLangEN ? 'Back' : 'Kembali'
const LangLeaveRequestReject = isLangEN ? 'Reject Request' : 'Tolak Pengajuan'
const LangLeaveCancel = isLangEN ? 'Cancel' : 'Batalkan'
const LangLeaveCancelHelp = isLangEN ? `Check the ${annualLeaveLabelEn} date to be canceled` : `Centang tanggal ${annualLeaveLabelId} yang akan dibatalkan`
const LangLeaveRequestCancel = isLangEN ? `Cancel ${annualLeaveLabelEn}` : `Batalkan ${annualLeaveLabelId}`
const LangLeaveRequestDetail = isLangEN ? 'Request Detail' : 'Detail Pengajuan'
const LangLeaveFiles = isLangEN ? 'Files' : 'File Kelengkapan'
const LangLeaveApprove = isLangEN ? 'Approve' : 'Terima'
const LangLeaveReject = isLangEN ? 'Reject' : 'Tolak'
export const LangRejected = isLangEN ? 'Rejected' : 'Ditolak'
const LangEmployeeDeletedAt = isLangEN ? 'Employee was removed on' : 'Karyawan telah dihapus pada'
const LangDivision = Division
const LangSearch = Search
const LangLeaveStartDate = isLangEN ? `${annualLeaveLabelEn} Start` : `Mulai ${annualLeaveLabelId}`
const LangLeaveEndDate = isLangEN ? `${annualLeaveLabelEn} End` : `Selesai ${annualLeaveLabelId}`
const LangExportExcel = ExportExcel
const LangCancelAttendance = isLangEN ? 'Cancel Attendance' : 'Batalkan Presensi'
const LangEditAttendance = isLangEN ? 'Edit Attendance' : 'Ubah Presensi'
const LangChooseAction = isLangEN ? 'Choose action' : 'Silakan pilih aksi yang akan dilakukan'
export const LangActionOptional = isLangEN ? 'Select an optional action' : 'Pilih aksi opsional'
export const LangReplaceAllDeduction = isLangEN ? 'Replace all existing deduction' : 'Ganti semua potongan yang ada'
export const LangReplaceAllBonus = isLangEN ? 'Replace all existing bonus' : 'Ganti semua bonus yang ada'
export const LangReplaceByName = isLangEN ? 'Replace with the same name' : 'Ganti yang namanya sama saja'
const LangEditDateAttendance = isLangEN ? 'Edit Date Attendance' : 'Ubah Waktu Presensi'
const LangCancelAttendanceAssociate = isLangEN ? 'The following attendance will be canceled' : 'Berikut presensi yang akan ikut dibatalkan'
const LangYesSure = isLangEN ? 'Yes sure' : 'Ya yakin'
const LangNotNow = isLangEN ? 'Not Now' : 'Nanti dulu'
const LangCancelAttendanceConfirm = isLangEN ? 'Attendance that has been canceled cannot be returned, and may affect attendance summaries.' : 'Presensi yang sudah dibatalkan tidak dapat dikembalikan lagi, dan dapat mempengaruhi ringkasan kehadiran.'
const LangSureDoThis = isLangEN ? 'Are you sure want to do this?' : 'Yakin akan melakukan aksi ini?'
const LangLookingAttendanceRelated = isLangEN ? 'Looking for attendance related' : 'Mencari presensi yang terkait'
const LangAttendanceTimeLaT = isLangEN ? 'Attendance time must be later than' : 'Waktu presensi harus lebih dari'
const LangAttendanceTimeLeT = isLangEN ? 'Attendance time must be less than' : 'Waktu presensi harus kurang dari'
const LangAttendanceEnterNewTime = isLangEN ? 'Please enter a new attendance time' : 'Silakan masukkan waktu presensi yang baru'
const LangSave = isLangEN ? 'Save' : 'Simpan'
const LangAttendIn = isLangEN ? 'Clock In' : 'Masuk'
const LangAttendOut = isLangEN ? 'Clock Out' : 'Keluar'
const LangAttendBreak = isLangEN ? 'Break' : 'Istirahat'
const LangAttendAfterBreak = isLangEN ? 'After Break' : 'Selesai Istirahat'
export const LangAnnualLeave = isLangEN ? 'Annual Leave' : 'Cuti'
export const LangAnnualLeave2 = isLangEN ? 'Leave' : 'Cuti'
const LangAttendOvertime = isLangEN ? 'Overtime' : 'Lembur'
export const LangAttendOvertime2 = isLangEN ? 'Overtime Clock-In' : 'Lembur'
const LangAttendFinishOvertime = isLangEN ? 'Overtime Clock-Out' : 'Selesai Lembur'
const LangAttendMustOnSpot = isLangEN ? 'Type of attendance that must match the location' : 'Presensi Yang Harus Sesuai Lokasi'
const LangMarkAll = isLangEN ? 'Mark All' : 'Tandai Semua'
const LangImportExcel = isLangEN ? 'Import Excel' : 'Impor Excel'
export const LangImportExcelVisitSchedule = isLangEN ? 'Import Excel Visit Schedule Format' : 'Impor Excel Format Jadwal Kunjungan'
export const LangImportExcelClientFormat = isLangEN ? 'Import Excel Client Format' : 'Impor Excel Format Klien'
export const LangVisitSchedule = isLangEN ? 'Visit Schedule' : 'Jadwal Kunjungan'
export const LangImportExcelDeduction = isLangEN ? 'Import Excel Deduction' : 'Impor Excel Potongan'
export const LangBonus = isLangEN ? 'Bonus' : 'Bonus'
export const LangImportExcelDeductionFormat = isLangEN ? 'Download Deduction Import Format' : 'Unduh Format Impor Potong Gaji'
export const LangImportExcelBonus = isLangEN ? 'Import Excel Bonus' : 'Impor Excel Bonus'
export const LangImportExcelBonusFormat = isLangEN ? 'Download Bonus Import Format' : 'Unduh Format Impor Bonus'
export const LangOtherPaymentFormat = isLangEN ? 'Download Other Payment Format' : 'Unduh Format Pembayaran Lain'
const LangImportant = isLangEN ? 'Important' : 'Penting'
const LangDownloadImportFormat = isLangEN ? 'Download import excel format' : 'Download format impor excel di sini'
const LangImportMakeSure = isLangEN ? 'Please make sure that the data you’re going to upload is in accordance with the format' : 'Untuk melakukan impor karyawan, pastikan file yang diupload sudah sesuai dengan format yang ditentukan'
const LangImportSeparate = isLangEN ? 'Please separate employee data according to their divisions. If an employee doesn’t have a division, there’s no need to select a division' : 'Upload dilakukan per divisi, silakan pisahkan data karyawan sesuai divisi masing-masing. Bila karyawan tidak memililki divisi maka saat impor data tidak perlu memilih divisi'
export const LangReadyDownload = isLangEN ? 'File ready to download.' : 'File siap diunduh.'
export const LangPreapareDownload = isLangEN ? 'Prepare Download...' : 'Menyiapkan Unduhan...'
export const LangErrorMessage = isLangEN ? 'Something went wrong. Please try again later.' : 'Terjadi kesalahan. Harap coba lagi nanti.'
const LangChooseFile = isLangEN ? 'Choose File' : 'Pilih Berkas'
export const LangChoose = isLangEN ? 'Choose' : 'Pilih'
const LangMaxSize = isLangEN ? 'Max. Size' : 'Ukuran Maksimal'
const LangImportSuccessCount = isLangEN ? 'Success Insert' : 'Data sukses dimasukkan'
const LangImportFailedCount = isLangEN ? 'Failed Insert' : 'Data gagal dimasukkan'
const LangImportErrorReason = isLangEN ? 'Errors:' : 'Penyebab data gagal dimasukkan'
const LangRow = isLangEN ? 'Row' : 'Baris'
const LangImportErrorMsg1 = isLangEN ? 'Please check the imported excel file that you uploaded and correct the data before re-uploading it.' : 'Silakan cek file impor excel yang Anda upload serta lakukan perbaikan data sebelum melakukan upload ulang.'
const LangImportErrorMsg2 = isLangEN ? 'Make sure that employee data that has entered the system is not re-entered.' : 'Pastikan data karyawan yang sudah masuk ke sistem tidak diikutkan kembali.'
const LangImportDone = isLangEN ? 'Done' : 'Selesai'
const LangImportCancel = isLangEN ? 'Cancel' : 'Batal'
const LangYes = isLangEN ? 'Yes' : 'Ya'
const LangNo = isLangEN ? 'No' : 'Tidak'
const LangMobileApp = isLangEN ? 'Mobile App' : 'Aplikasi Mobile'
const LangQuote = isLangEN ? 'Quotes' : 'Quote'
const LangHideLeaveReqeustButton = isLangEN ? `Hide ${annualLeaveLabelEn} Request Button` : `Sembunyikan Tombol ${annualLeaveLabelId}`
const LangHidePermitReqeustButton = isLangEN ? 'Hide Leave Request Button' : 'Sembunyikan Tombol Izin'
export const LangRevisionAttendanceButton = isLangEN ? 'Hide Revision Attendance Button' : 'Sembunyikan Tombol Revisi Kehadiran'
export const LangHideViewSalaryButton = isLangEN ? 'Hide View Salary Button' : 'Sembunyikan Tombol Lihat Gaji'
export const LangAttendMustOnSpotInfo = isLangEN ? 'Make sure employees in this division have set spot attendance' : 'Pastikan karyawan pada divisi ini sudah diatur lokasi kehadirannya'
const LangHideOvertimeButton = isLangEN ? 'Hide Overtime Button' : 'Sembunyikan Tombol Lembur'
const LangHideBreakButton = isLangEN ? 'Hide Break Button' : 'Sembunyikan Tombol Istirahat'
const LangPictureMaxSize = isLangEN ? 'Maximum size 5mb' : 'Ukuran maksimal 5mb'
export const LangPSignatureMaxSize = isLangEN ? 'Maximum size 5mb with dimension 165px X 65px' : 'Ukuran maksimal 5mb dengan dimensi 165px X 65px'
export const LangPBgCalendarMaxSize = isLangEN ? 'Maximum size 10mb with dimension 1000px X 370px' : 'Ukuran maksimal 10mb dengan dimensi 1000px X 370px'
const LangChangeFile = isLangEN ? 'Change' : 'Ganti'
const LangDeleteConfirm = isLangEN ? 'Are you sure you want to delete?' : 'Anda yakin akan menghapus?'
const LangCustomers = isLangEN ? 'Supervision' : 'Supervisi'
const LangCustomersIs = isLangEN ? 'These supervision can be selected by the employee as the destination to which the report will be sent' : 'Supervisi ini dapat dipilih oleh karyawan sebagai tujuan kemana laporan catatannya akan dikirimkan'
const LangName = isLangEN ? 'Name' : 'Nama'
const LangEmail = isLangEN ? 'Email' : 'Email'
const LangBCC = isLangEN ? 'BCC' : 'BCC'
const LangAdd = isLangEN ? 'Add' : 'Tambah'
const LangDelete = isLangEN ? 'Delete' : 'Hapus'
const LangSeparateByComma = isLangEN ? 'Separate by comma' : 'Pisah dengan koma'
const LangReportSendTo = isLangEN ? 'Notes have been sent to the following supervision' : 'Catatan telah dikirimkan ke supervisi ini'
const LangSetting = isLangEN ? 'Setting' : 'Pengaturan'
const LangTimeIn = isLangEN ? 'Clock In' : 'Jam Masuk'
const LangTimeOut = isLangEN ? 'Clock Out' : 'Jam Keluar'
const LangApplyBulkWorktime = isLangEN ? 'Apply Bulk Work Hours' : 'Terapkan Waktu Kerja Massal'
const LangFilterGroup = isLangEN ? 'Division Filter' : 'Filter Divisi'
export const LangAdvancedFilters = isLangEN ? 'Advanced Filters' : 'Filter Lanjutan'
const LangTargetCount = isLangEN ? 'Target Quantity' : 'Jumlah Target'
const LangRepeatScheduleEveryWeek = isLangEN ? 'Repeat this schedule every week' : 'Ulang jadwal ini tiap minggu'
const LangChooseSchedule = isLangEN ? 'Choose Schedule' : 'Pilih Jadwal'
const LangDeleteSchedule = isLangEN ? 'Delete Schedule' : 'Hapus Jadwal'
const LangBulkRepeat = isLangEN ? 'Repeat Schedule in Bulk' : 'Ulang Jadwal Massal'
export const LangShiftScheduleInfo1 = isLangEN ? '* The schedule will repeat weekly starting from today as long as the checkbox [v] is applied.' : '* Jadwal akan berulang per minggu setelah hari ini selama tanda centang [v] diterapkan.'
const LangShiftSchedule = isLangEN ? 'Shift Schedule' : 'Jadwal Shift'
const LangHelpRepeat = isLangEN ? 'Repeat schedules in this date range every following week' : 'Ulang jadwal yang ada pada rentang tanggal ini di setiap minggu berikutnya'
const LangMonth = isLangEN ? 'Month' : 'Bulan'
const LangPackage = isLangEN ? 'Package' : 'Nama Paket'
export const LangSubscripDuration = isLangEN ? 'Subscription Duration' : 'Lama Berlangganan'
export const LangStartSubscription = isLangEN ? 'Start Subscription' : 'Mulai Berlangganan'
export const LangEmployeeInputted = isLangEN ? 'Total number of employees entered' : 'Jumlah Karyawan yang telah diinput'
export const LangSubsHelp1 = isLangEN ? 'Price per employee, minimum of 5 employees' : 'Harga per karyawan, minimal 5 karyawan'
export const LangSubsHelp2 = isLangEN ? 'It is recommended to choose the Master package for a lower price.' : 'Direkomendasikan memilih paket Master untuk harga lebih murah.'
export const LangSubsHelp3 = isLangEN ? 'It is recommended to choose the Pro package for a lower price.' : 'Direkomendasikan memilih paket Pro untuk harga lebih murah.'
export const LangSubsHelp4 = isLangEN ? 'Maximum Free Package' : 'Paket Gratis maksimal'
export const LangSubsHelp5 = isLangEN ? 'employee. You need to reduce the number of employees that have been input first.' : 'karyawan. Anda perlu mengurangi jumlah karyawan yang telah diinput terlebih dahulu.'
export const LangSubsHelp6 = isLangEN ? 'Does your company have specific needs? Contact' : 'Perusahaan Anda memiliki kebutuhan tertentu? Hubungi'
export const LangSubsHelp7 = isLangEN ? 'KERJOO team' : 'tim KERJOO'
export const LangSubsHelp8 = isLangEN ? 'to get special price.' : 'untuk mendapatkan harga terbaik.'
export const LangSubsHelp9 = isLangEN ? 'Complete the Free Package Order' : 'Selesaikan Order Paket Gratis'
export const LangSubsHelp10 = isLangEN ? 'Please review your order summary' : 'Silakan periksa kembali Ringkasan Pesanan Anda'
export const LangMaximal = isLangEN ? 'Maximum of' : 'Maksimal'
export const LangEmp = isLangEN ? 'Employee' : 'Karyawan'
export const LangEmps = isLangEN ? 'employees' : 'karyawan'
export const LangOrderSummary = isLangEN ? 'Order Summary' : 'Ringkasan'
export const LangExtendSubscription = isLangEN ? 'Extend Subscription' : 'Perpanjang Langganan'
export const LangSubscriptionInfo = isLangEN ? 'Subscription Info' : 'Info Langganan'
export const LangRenewForm = isLangEN ? 'Renew Form' : 'Form Perpanjangan'
export const LangOrderForm = isLangEN ? 'Order Form' : 'Form Order'
export const LangPricePerEmp = isLangEN ? 'Price Per Employee (Rp)' : 'Harga Per Karyawan (Rp)'
export const LangPerEmp = isLangEN ? 'per Employee' : 'per Karyawan'
export const LangPerEmp2 = isLangEN ? 'per Employee' : 'per karyawan'
export const LangRemainingActivePeriod = isLangEN ? 'Remaining Active Period' : 'Sisa Masa Aktif'
export const LangRenewDuration = isLangEN ? 'Renew Duration' : 'Durasi Perpanjangan'
export const Lang3Month = isLangEN ? '3 month' : '3 bulan'
export const Lang6MonthD2 = isLangEN ? '6 month discount 2%' : '6 bulan diskon 2%'
export const Lang6MonthD10 = isLangEN ? '6 month discount 10%' : '6 bulan diskon 10%'
export const Lang6MonthD5 = isLangEN ? '6 month discount 5%' : '6 bulan diskon 5%'
export const Lang12MonthD5 = isLangEN ? '12 month discount 5%' : '12 bulan diskon 5%'
export const Lang12MonthD7 = isLangEN ? '12 month discount 7%' : '12 bulan diskon 7%'
export const Lang12MonthD15 = isLangEN ? '12 month discount 15%' : '12 bulan diskon 15%'
export const Lang6MonthD0 = isLangEN ? '6 month' : '6 bulan'
export const Lang12MonthD0 = isLangEN ? '12 month' : '12 bulan'
export const Lang18MonthD = isLangEN ? '18 month discount 17%' : '18 bulan diskon 17%'
export const Lang18MonthD10 = isLangEN ? '18 month discount 10%' : '18 bulan diskon 10%'
export const Lang24MonthD = isLangEN ? '24 month discount 20%' : '24 bulan diskon 20%'
export const Lang24MonthD12 = isLangEN ? '24 month discount 12%' : '24 bulan diskon 12%'
export const LangDuration6RenewD = isLangEN ? '6 month discount 2%' : '6 bulan diskon 2%'
export const LangDuration12RenewD = isLangEN ? '12 month discount 5%' : '12 bulan diskon 5%'
export const LangSelectPackage = isLangEN ? 'Select Package' : 'Pilih Paket'
export const LangMasterPackage = isLangEN ? 'Master Package' : 'Paket Master'
export const LangProPackage = isLangEN ? 'Pro Package' : 'Paket Pro'
export const LangBasicPackage = isLangEN ? 'Basic Package' : 'Paket Basic'
export const LangFreeForever = isLangEN ? 'Free Package, no time limit' : 'Paket Gratis Selamanya'
export const LangFreeForever2 = isLangEN ? 'Free Forever' : 'Gratis Selamanya'
export const LangFreePackage = isLangEN ? 'Free Package' : 'Paket Gratis'
export const LangAddEmployeeQuota = isLangEN ? 'Add Employee Quota' : 'Tambah Kuota Karyawan'
export const LangIncreaseEmployeeQuota = isLangEN ? 'Increase Employee Quota' : 'Tambah Kuota Karyawan'
export const LangIncreaseEmployeesQuota = isLangEN ? 'Increase Employees Quota' : 'Tambah Kuota Karyawan'
export const LangDecreaseEmployeeQuota = isLangEN ? 'Decrease Employee Quota' : 'Kurangi Kuota Karyawan'
export const LangReduceEmployeeError1 = isLangEN ? 'The Basic package requires a minimum of 5 employees.' : 'Paket Basic membutuhkan minimal 5 karyawan.'
export const LangReduceEmployeeError2 = isLangEN ? 'The Pro package requires a minimum of 5 employees.' : 'Paket Pro membutuhkan minimal 5 karyawan.'
export const LangReduceEmployeeError3 = isLangEN ? 'The Advance package requires a minimum of 51 employees.' : 'Paket Advance membutuhkan minimal 51 karyawan.'
export const LangReduceEmployeeError4 = isLangEN ? 'The Pro package requires a minimum of 5 employees.' : 'Paket Pro membutuhkan minimal 5 karyawan.'
export const LangReduceEmployeeError5 = isLangEN ? 'The Master package requires a minimum of 5 employees.' : 'Paket Master membutuhkan minimal 5 karyawan.'
export const LangReduceEmployeeInfo1 = isLangEN ? 'After payment is received, the kerjoo team will contact again to delete' : 'Setelah pembayaran diterima, tim kerjoo akan menghubungi kembali untuk menghapus'
export const LangReduceEmployeeInfo2 = isLangEN ? 'employees.' : 'karyawan.'
export const LangSubsDuration = isLangEN ? 'Subscription Duration' : 'Durasi Berlangganan'
export const LangDiscount = isLangEN ? 'Discount (Rp)' : 'Diskon (Rp)'
export const LangFinalTotal = isLangEN ? 'Final Total (Rp)' : 'Total Akhir (Rp)'
export const LangFinalTotalUpgrade = isLangEN ? 'Total Upgrade Cost (Rp)' : 'Total Biaya Upgrade (Rp)'
export const LangCompleteOrder = isLangEN ? 'Payment' : 'Pembayaran'
export const LangNext2 = isLangEN ? 'Next' : 'Selanjutnya'
export const LangOrderSuccess = isLangEN ? 'Order Successful' : 'Order Berhasil'
export const LangOrderRenew = isLangEN ? 'Order Renew' : 'Order Perpanjangan'
export const LangOrderAddEmp = isLangEN ? 'Order Add Employee' : 'Order Tambah Karyawan'
export const LangOrderAddQuotaBroadcast = isLangEN ? 'Order Broadcast Quota' : 'Order Penambahan Kuota Broadcast'
export const LangSubscriptionOrder = isLangEN ? 'Subscription Order' : 'Order Berlangganan'
export const LangSubsOrderHelp1 = isLangEN ? 'Thank You for Placing an Order to' : 'Terima Kasih Sudah Melakukan'
export const LangSubsOrderHelp2 = isLangEN ? 'please proceed with making the payment of ' : 'silakan lakukan pembayaran sejumlah'
export const LangSubsOrderHelp3 = isLangEN ? 'to one of the following bank accounts' : 'ke salah satu rekening di bawah ini'
export const LangSubsOrderHelp4 = isLangEN ? 'Afterward, kindly confirm your payment via' : 'Selanjutnya, silakan konfirmasi pembayaran ke'
export const LangSubsOrderHelp5 = isLangEN ? 'WhatsApp at 0821 3333 6949' : 'WhatsApp 0821 3333 6949'
export const LangOrderFreeSuccessHelp1 = isLangEN ? 'Successful Free Package Subscription' : 'Sukses Berlangganan Paket Gratis'
export const LangOrderFreeSuccessHelp2 = isLangEN ? 'Mr./Ms.' : 'Bpk/Ibu'
export const LangOrderFreeSuccessHelp3 = isLangEN ? 'Thank you for choosing the Free Package, this Package will be active forever.' : 'Terima kasih telah memilih Paket Gratis, Paket ini akan aktif selamanya.'
export const LangOrderFreeSuccessHelp4 = isLangEN ? 'We hope that this collaboration will continue and that Kerjoo can always provide benefits to your company, sir/madam.' : 'Kami berharap, kerja sama ini akan terus berlangsung dan Kerjoo dapat selalu memberikan manfaat untuk Perusahaan Bpk/Ibu.'
export const LangOrderFreeSuccessHelp5 = isLangEN ? 'You can increase the number of employees by upgrading to the next package. Please visit' : 'Anda bisa meningkatkan jumlah karyawan dengan upgrade ke paket berikutnya. Silakan kunjungi'
export const LangOrderFreeSuccessHelp6 = isLangEN ? 'to see the package list.' : 'untuk melihat daftar paket.'
export const LangOrderFreeSuccessHelp7 = isLangEN ? 'Thank You' : 'Terima kasih'
export const LangTrial14Day = isLangEN ? '14 Day Trial Period' : 'Masa Percobaan 14 Hari'
const LangExpiredOn = isLangEN ? 'Expired On' : 'Masa Berakhir'
const LangSpesificEmployee = isLangEN ? 'Specific Employee' : 'Spesifik Karyawan'
export const LangSpesificClient = isLangEN ? 'Specific Client' : 'Spesifik Klien'
const LangIdentity = isLangEN ? 'Identity' : 'Identitas'
const LangPersonnelsQuota = isLangEN ? 'Employee Quota' : 'Kuota Karyawan'
const LangPersonnelsInput = isLangEN ? 'Inputted employees' : 'Karyawan Diinput'
const LangExportExcelSendTo = isLangEN ? 'Send to email' : 'Kirimkan File ke Email'
const LangExportExcelSendToHelp = isLangEN ? 'Separate emails with commas.' : 'Pisah email dengan koma jika lebih dari satu.'
const LangExportExcelSendToTime = isLangEN ? 'The time it takes to receive an excel export email is a maximum of 20 minutes, depending on the amount of data collected.' : 'Waktu yang dibutuhkan untuk menerima email hasil ekspor excel maksimal 20 menit, tergantung banyaknya data yang dikumpulkan.'
const LangRequestSuccess = isLangEN ? 'Request sent successfully.' : 'Permintaan berhasil dikirimkan.'
const LangImportShiftHelp1 = isLangEN ? 'To make imports easier, please specify employees by division or you can select specific employees.' : 'Untuk mempermudah impor, silakan tentukan karyawan berdasarkan divisi atau Anda bisa memilih karyawan secara spesifik.'
export const LangOtherPaymentHelp1 = isLangEN ? 'To make other payments easier, please specify employees by division.' : 'Untuk mempermudah melakukan pembayaran lain, silakan tentukan karyawan berdasarkan divisi.'
const LangImportShiftHelp2 = isLangEN ? 'A brief description' : 'Penjelasan singkat'
const LangImportShiftHelp3 = isLangEN ? 'To import a shift schedule, make sure the uploaded file matches the specified format' : 'Untuk melakukan impor jadwal shift, pastikan file yang diupload sudah sesuai dengan format yang ditentukan'
export const LangImportClientVisitHelp3 = isLangEN ? 'To import a client visit schedule, make sure the uploaded file matches the specified format' : 'Untuk melakukan impor jadwal kunjungan klien, pastikan file yang diupload sudah sesuai dengan format yang ditentukan'
export const LangImportClientHelp3 = isLangEN ? 'To import clients, make sure the uploaded file matches the specified format' : 'Untuk melakukan impor klien, pastikan file yang diupload sudah sesuai dengan format yang ditentukan'
export const LangImportDeductiontHelp3 = isLangEN ? 'To import a deduction, make sure the uploaded file matches the specified format' : 'Untuk melakukan impor potongan, pastikan file yang diupload sudah sesuai dengan format yang ditentukan'
export const LangImportBaseSalarytHelp3 = isLangEN ? 'To import a base salary, make sure the uploaded file matches the specified format' : 'Untuk melakukan impor gaji dasar, pastikan file yang diupload sudah sesuai dengan format yang ditentukan'
export const LangImportBonusHelp3 = isLangEN ? 'To import a bonus, make sure the uploaded file matches the specified format' : 'Untuk melakukan impor bonus, pastikan file yang diupload sudah sesuai dengan format yang ditentukan'
const LangImportShiftHelp4 = isLangEN ? 'Download the excel import format here' : 'Download format impor excel di sini'
const LangImportShiftHelp5 = isLangEN ? 'The import action will only be executed if all the data is correct' : 'Aksi impor hanya akan dijalankan jika semua data sudah benar'
const LangImportShiftHelp6 = isLangEN ? 'If there is data that needs to be corrected, the system will immediately display the information after clicking the process button' : 'Jika ada data yang perlu diperbaiki, sistem akan segera menampilkan informasinya setelah klik tombol proses'
const LangImportShiftHelp7 = isLangEN ? 'Overwrite the schedule if it already exists' : 'Timpa jadwal jika sudah ada'
export const LangImportClientVisitHelp8 = isLangEN ? 'Separate with a comma if on one date there is more than one client visited.' : 'Pisah dengan koma jika dalam satu tanggal terdapat lebih dari satu klien yang dikunjungi.'
const LangAttendancePerfomance = isLangEN ? 'Attendance Perfomance' : 'Performa Kehadiran'
const LangOntime = isLangEN ? 'Ontime' : 'Tepat Waktu'
export const LangOntimePerfAtt = isLangEN ? 'On-Time Attendance' : 'Tepat Waktu'
export const LangLatePerfAtt = isLangEN ? 'Late Attendance' : 'Terlambat'
export const LangNotYetPerfAtt = isLangEN ? 'Not/not yet clock-in' : 'Tidak/belum hadir'
export const LangAutomaticCOPerfAtt = isLangEN ? 'Automatic clock-out' : 'Presensi keluar otomatis'
const LangLate = isLangEN ? 'Late' : 'Terlambat'
const LangNoAttendance = isLangEN ? 'No employee are ' : 'Tidak ada karyawan yang '
export const LangNoDataHelp1 = isLangEN ? 'No employees were on time' : 'Tidak ada karyawan tepat waktu'
export const LangNoDataHelp2 = isLangEN ? 'No employees were late' : 'Tidak ada karyawan terlambat'
export const LangNoDataHelp3 = isLangEN ? 'No employees are not/not yet clock-in' : 'Tidak ada karyawan tidak/belum hadir'
export const LangInfoHelp1 = isLangEN ? 'employees were on time' : 'karyawan tepat waktu'
export const LangInfoHelp2 = isLangEN ? 'employees were late' : 'karyawan terlambat'
export const LangInfoHelp3 = isLangEN ? 'employees have not/not yet clock-in' : 'karyawan tidak/belum hadir'
const LangDeveloper = isLangEN ? 'Integration' : 'Integrasi'
const LangDeveloperApiDocumentation = isLangEN ? 'API Documentation' : 'Dokumentasi API'
const LangBccTo = isLangEN ? 'BCC Report To' : 'BCC Laporan Ke'
const LangBccToPlaceholder = isLangEN ? 'Separate email with commas if more than one' : 'Pisahkan email dengan koma jika lebih dari satu'
const LangBccToHelp = isLangEN ? 'This BCC will be used if the bcc column in the table above is not filled.' : 'BCC ini akan digunakan jika kolom bcc pada tabel di atas tidak terisi.'
const LangSeeResult = isLangEN ? 'See Result' : 'Lihat Hasil'
const LangOutOfQuota = isLangEN ? 'Out Of Quota' : 'Kuota Habis'
const LangSubscriptionSetting = isLangEN ? 'Subscription Setting' : 'Pengaturan Langganan'
const LangOutOfQuotaMessage = isLangEN ? 'The employee quota has run out, please order to add employees on the menu ' : 'Kuota karyawan telah habis, silakan order tambah karyawan pada menu '
const LangNotYetLogin = isLangEN ? 'No/Not clock in yet' : 'Tidak/Belum Masuk'
const LangAbsent = isLangEN ? 'Absent' : 'Tidak Masuk'
const LangPosition = isLangEN ? 'Position' : 'Jabatan'
export const LangAppearance = isLangEN ? 'Appearance' : 'Tampilan'
export const LangAnnotation = isLangEN ? 'Annotation' : 'Keterangan'
const LangDayOff = isLangEN ? 'Day Off' : 'Libur'
const LangScheduleOff = isLangEN ? 'Schedule Off' : 'Jadwal Libur'
const LangEmpty = isLangEN ? 'Empty' : 'Kosong'
export const LangDataNotFilled = isLangEN ? 'Data is not filled' : 'Data tidak diisi'
export const LangDataEmpty = isLangEN ? 'Data is empty' : 'Data kosong'
const LangFrom = isLangEN ? 'From' : 'Dari'
const LangTo = isLangEN ? 'To' : 'Menjadi'
const LangCreatedBy = isLangEN ? 'Created By' : 'Dibuat Oleh'
const LangEditedBy = isLangEN ? 'Edited By' : 'Diubah Oleh'
const LangChanged = isLangEN ? 'Changed' : 'Perubahan'
const LangDeletedBy = isLangEN ? 'Deleted By' : 'Dihapus Oleh'
const LangMinute = isLangEN ? 'Minute' : 'Menit'
const LangPenalty = isLangEN ? 'Penalty' : 'Denda'
const LangLateness = isLangEN ? 'Lateness' : 'Keterlambatan'
const LangLateCharge = isLangEN ? 'Late Penalty' : 'Denda Keterlambatan'
const LangNewLateCharge = isLangEN ? 'New Late Charge' : 'Denda Keterlambatan Baru'
const LangAddPenalty = isLangEN ? 'Add Penalty' : 'Tambah Denda'
const LangBulkActivate = isLangEN ? 'Bulk activate' : 'Aktifkan Masal'
const LangActivate = isLangEN ? 'Activate' : 'Aktifkan'
const LangNonActivate = isLangEN ? 'Non Activate' : 'Non Aktifkan'
const LangList = isLangEN ? 'List' : 'Daftar'
const LangActivation = isLangEN ? 'Activation' : 'Aktifasi'
const LangActive = isLangEN ? 'Active' : 'Aktif'
const LangNonActive = isLangEN ? 'Inactive' : 'Non Aktif'
const LangActivityHistories = isLangEN ? 'Activity Histories' : 'Riwayat Aktivitas'
const LangActivityDate = isLangEN ? 'Activity Date' : 'Tanggal Aktivitas'
const LangActivity = isLangEN ? 'Activity' : 'Aktivitas'
const LangLogout = isLangEN ? 'Logout' : 'Keluar'
const LangLogin = isLangEN ? 'Login' : 'Masuk'
const LangSystem = isLangEN ? 'System' : 'Sistem'
const LangNewFeature = isLangEN ? 'New Feature' : 'Fitur Terbaru'
const LangHowtoUse = isLangEN ? 'How to Use' : 'Cara Penggunaan'
const LangLateChargeNote = isLangEN ? 'Late penalty does not apply in multiples' : 'Denda keterlambatan tidak berlaku kelipatan'
const LangCause = isLangEN ? 'Cause' : 'Sebab'
export const LangCopyLatePenalty = isLangEN ? 'Align with Other Division' : 'Samakan dengan Divisi Lain'
export const LangCopyLatePenaltyWith = isLangEN ? 'Align the late penalty settings of' : 'Samakan denda keterlambatan'
export const LangSelectReferenceDivision = isLangEN ? 'Select Reference Division' : 'Pilih Divisi Acuan'

const LangAttendanceRevision = isLangEN ? 'Attendance Revision' : 'Revisi Kehadiran'
const LangAttendanceType = isLangEN ? 'Attendance Type' : 'Jenis Kehadiran'
export const LangApproveSelected = isLangEN ? 'Approve Selected' : 'Izinkan Terpilih'
export const LangApproveAll = isLangEN ? 'Approve All' : 'Izinkan Semua'
const LangAttendance = isLangEN ? 'Attendance' : 'Kehadiran'
const LangProcessed = isLangEN ? 'Processed' : 'Telah Diproses'
const LangCurrentPresence = isLangEN ? 'Current Presence' : 'Presensi Sekarang'
const LangPreviousPresence = isLangEN ? 'Previous Presence' : 'Presensi Sebelumnya'
const LangPresenceRequest = isLangEN ? 'Presence Request' : 'Permintaan Presensi'
const LangRevisionNote = isLangEN ? 'Revision Note' : 'Catatan Revisi'
const LangAttendanceRevisionApproveNote = isLangEN ? 'Revision request approved and processed' : 'Permintaan revisi disetujui dan telah diproses'
const LangAttendanceRevisioRejectNote = isLangEN ? 'Sorry, the attendance revision request was not approved' : 'Mohon maaf, permintaan revisi presensi tidak disetujui'
const LangAttendanceRevisioRejectNote1 = isLangEN ? 'Sorry, the time revision request exceeds the time that can be changed' : 'Mohon maaf, waktu permintaan revisi melebihi yang dapat diubah'

const LangHolidayCalendar = isLangEN ? 'Holiday Calendar' : 'Kalender Libur'
const LangDescription = isLangEN ? 'Description' : 'Deskripsi'
const LangAddHoliday = isLangEN ? 'Add Holiday' : 'Tambah Libur'
const LangHolidayImportMakeSure = isLangEN ? 'To import a calendar, make sure the data you provide is in the specified format' : 'Untuk melakukan impor kalender, pastikan file yang diupload sudah sesuai dengan format yang ditentukan'
const LangHolidayNotes = isLangEN ? 'The holiday calendar does not apply automatically to schedule filling in the shift work pattern' : 'Kalender libur tidak berlaku otomatis untuk pengisian jadwal di Pola Kerja shift'
export const LangDownloadAnnualCalendar = isLangEN ? 'Download Annual Calendar' : 'Unduh Kalender Tahunan'
export const LangSocialMedia = isLangEN ? 'SocialMedia' : 'Media Sosial'

const LangNewBroadcast = isLangEN ? 'New Broadcast' : 'Broadcast Baru'
const LangBroadcastReceiver = isLangEN ? 'Broadcast Receiver' : 'Penerima Broadcast'
const LangMessage = isLangEN ? 'Message' : 'Pesan'
const LangMessageTitle = isLangEN ? 'Message Title' : 'Judul Pesan'
const LangNotificationMessage = isLangEN ? 'Notification Message' : 'Pesan Notifikasi'
const LangReceiver = isLangEN ? 'Receiver' : 'Penerima'
const LangAlreadySent = isLangEN ? 'Already Sent' : 'Sudah Dikirim'
const LangOtherReceiver = isLangEN ? 'Other Receiver' : 'Penerima Lainnya'
const LangNoReceiver = isLangEN ? 'No Receiver' : 'Tidak Ada Penerima'
const LangAll = isLangEN ? 'All' : 'Semua'
const LangSend = isLangEN ? 'Send' : 'Kirim'
const LangTestSend = isLangEN ? 'Test Send' : 'Tes Kirim'
const LangSent = isLangEN ? 'Sent' : 'Terkirim'
const LangSentAt = isLangEN ? 'Sent At' : 'Dikirim Pada'
const LangCreator = isLangEN ? 'Creator' : 'Pembuat'
const LangCertainDivision = isLangEN ? 'Specific division' : 'Divisi tertentu'
const LangCertainEmployee = isLangEN ? 'Specific employee' : 'Karyawan tertentu'
const LangSpesificReceiver = isLangEN ? 'Spesific receiver' : 'Penerima spesifik'
const LangExceptionReceiver = isLangEN ? 'Exception receiver' : 'Penerima terkecuali'
const LangSetup = isLangEN ? 'Setup' : 'Konsep'
const LangSending = isLangEN ? 'Sending' : 'Mengirim'
const LangQuotaRunsOut = isLangEN ? 'Quota Runs Out' : 'Kouta Habis'
const LangPaused = isLangEN ? 'Paused' : 'Ditunda'
const LangPause = isLangEN ? 'Pause' : 'Tunda'
const LangCanceled = isLangEN ? 'Canceled' : 'Batal'
const LangDone = isLangEN ? 'Done' : 'Selesai'
const LangQuota = isLangEN ? 'Quota' : 'Kuota'
export const LangQuotaCountDistance = isLangEN ? 'Quota Count Distance' : 'Kuota Hitung Jarak'
export const LangTelegramNotification = isLangEN ? 'Telegram Notification' : 'Notifikasi Telegram'
export const LangAdditionalQuota = isLangEN ? 'Additional Quota' : 'Kuota Tambahan'
const LangCopy = isLangEN ? 'Copy' : 'Salin'
const LangPrice = isLangEN ? 'Price' : 'Harga'
const LangViewContent = isLangEN ? 'View Content' : 'Lihat Konten'
const LangOrderQuota = isLangEN ? 'Order Quota' : 'Pesan Kuota'
export const LangOrderQuotaCountDistance = isLangEN ? 'Order Quota Count Visit Distance' : 'Pesan Kuota Hitung Jarak Kunjungan'
const LangSuccessBuyQuota = isLangEN ? 'Buying Quota Success' : 'Berhasil Membeli Kuota'
const LangSendMessageSuccess = isLangEN ? 'Send Message Success' : 'Berhasil Mengirim Pesan'
export const LangResendEmailSuccess = isLangEN ? 'Resend Email Success' : 'Berhasil Mengirim Ulang Email'
const LangCancelConfirmation = isLangEN ? 'Are you sure you want to cancel?' : 'Apakah anda yakin ingin batal?'
const LangOutOfQuotaBroadcastMessage = isLangEN ? 'Please reorder on the button on the side to increase your broadcast quota' : 'Silakan melakukan order kembali pada tombol di samping untuk menambah kuota broadcast'
const LangCanceledDate = isLangEN ? 'Canceled Date' : 'Tanggal yang Dibatalkan'
export const LangNewVersionAvaiable = isLangEN ? 'The new version is available' : 'Versi baru telah tersedia'

/**
 * Configuration Attendance Success Message
 */
const LangConfigSuccessMessageAttendance = isLangEN ? 'Config Success Message Attendance' : 'Pengaturan Pesan Sukses Presensi'
const LangSuccessMessageAttendance = isLangEN ? 'Success Message Attendance' : 'Pesan Sukses Presensi'
const LangTitle = isLangEN ? 'Title' : 'Judul'
const LangContent = isLangEN ? 'Content' : 'Konten'
const LangMessageIfLate = isLangEN ? 'Message If Late' : 'Pesan Jika Telat'
const LangMessageIfOntime = isLangEN ? 'Message If Ontime' : 'Pesan Jika Tepat Waktu'
const LangMessageIfOnSpot = isLangEN ? 'Message If On Spot' : 'Pesan Jika Sesuai Lokasi'
const LangMessageIfOutOfSpot = isLangEN ? 'Message If Out Of Spot' : 'Pesan Jika Diluar Lokasi'
const LangSuccess = isLangEN ? 'Success' : 'Sukses'
const LangBackToDefault = isLangEN ? 'Back to Default' : 'Kembalikan ke Semula'
const LangSuccessSaveData = isLangEN ? 'Success Save Data' : 'Sukses Menyimpan Data'
const LangSuccessBackToDefault = isLangEN ? 'Success Back to Default' : 'Sukses Mengembalikan ke Semula'
const LangBackToDefaultConfirm = isLangEN ? 'Are you sure you want to back to default?' : 'Apakah Anda yakin ingin mengembalikan ke semula?'
const LangMaxRangeExport60Day = isLangEN ? 'Maximum time span is 60 days.' : 'Rentang waktu maksimal 60 hari.'
const LangHelpAbsent = isLangEN ? `List of employees who are not/not present yet, do not have a schedule and are not currently on unpaid leave / paid leave.` : `Daftar karyawan yang tidak/belum presensi masuk, tidak libur dan tidak sedang izin / cuti.`

/**
 * Tooltip Attendance Summary
 */
const LangTooltipAttendanceDay = isLangEN ? 'The number of days in which attendance is made. If in one day there are several absent entries, then only one is counted. If there is only absence of rest/overtime it does not count as days of attendance.' : 'Jumlah hari di mana dilakukan abensi masuk. Bila dalam satu hari ada beberapa kali absen masuk, maka hanya terhitung satu. Jika hanya ada absen istirahat/lembur tidak dihitung sebagai hari kehadiran.'
const LangTooltipTotalNotClockInDay = isLangEN ? `Number of days without attendance, where that day is not on ${annualLeaveLabelEn}/leave/day off schedule.` : `Jumlah hari tidak presensi masuk, dimana hari itu tidak sedang ${annualLeaveLabelId}/izin/jadwal libur.`
const LangTooltipTotalLeaveDay = isLangEN ? `The number of days of ${annualLeaveLabelEn} that has been approved.` : `Jumlah hari ${annualLeaveLabelId} yang telah disetujui.`
const LangTooltipTotalPermitDay = isLangEN ? 'the number of days of leave that has been approved.' : 'Jumlah hari izin yang telah disetujui.'
const LangTooltipWorkHour = isLangEN ? 'The number of working hours calculated from the number of hours in to hours out, minus hours of rest.' : 'Jumlah jam kerja yang diperhitungkan dari jumlah jam masuk hingga jam keluar, dikurangi jam istirahat.'
const LangTooltipBreakHour = isLangEN ? 'The number of hours of rest is calculated from the start of the break to the time of the end of the break.' : 'Jumlah jam istirahat yang diperhitungkan dari jam mulai istirahat hingga jam selesai istirahat.'
const LangTooltipOvertimeHour = isLangEN ? 'The number of overtime hours calculated from hours in overtime to hours out overtime.' : 'Jumlah jam lembur yang diperhitungkan dari jam masuk lembur hingga jam keluar lembur.'
const LangTooltipLateHour = isLangEN ? 'The number of hours late, calculated from the time of absent entry minus the schedule of entry whose result is more than the tolerance time of delay.' : 'Jumlah jam terlambat , dihitung dari waktu absen masuk dikurangi jadwal masuk yang hasil nya lebih dari waktu toleransi keterlambatan.'
const LangTooltipLateDay = isLangEN ? 'The number of days late, where the time of absence is more than the scheduled time of entry and the time of tolerance, if any. Total Hours Tolerance: The number of hours late, but still within tolerance.' : 'Jumlah hari terlambat, di mana waktu absen masuk lebih dari jadwal jam masuk maupun jam toleransi apabila ada. Total Jam Toleransi: Jumlah jam terlambat, tapi masih masuk dalam toleransi.'
const LangTooltipLateToleranceDay = isLangEN ? 'The number of days in which it was detected that it was late (employees doing absenteeism were past the work schedule), but were still within tolerance.' : 'Jumlah hari di mana terdeteksi terlambat (karyawan melakukan absen masuk sudah melewati jadwal masuk kerja), tapi masih masuk toleransi.'
const LangTooltipLateToleranceHour = isLangEN ? 'The number of hours late, but still within tolerance.' : 'Jumlah jam terlambat, tapi masih masuk dalam toleransi.'
const LangTooltipNotCompleteDay = isLangEN ? 'The number of days in which the employee`s number of hours worked on that day does not meet the scheduled number of hours. Working hours are calculated from the time the attendance is in to the time the attendance is out.' : 'Jumlah hari di mana jumlah jam kerja karyawan pada hari tersebut tidak memenuhi jumlah jam yang dijadwalkan. Jam kerja dihitung dari waktu absensi masuk sampai dengan waktu absensi keluar.'
export const LangTooltipAttendancePercentage = isLangEN ? 'Percent value of ((Attendance Days + Annual Leave Days) / Total Work Day Schedule) x 100.' : 'Merupakan nilai persen dari ((total hari kehadiran + total hari cuti) / total hari jadwal kerja) x 100.'
export const LangTooltipPercentageAchievedWorkingHours = isLangEN ? 'Percent value of (Work Hours / Total Hours Work Schedule) x 100.' : 'Merupakan nilai persen dari (total jam kerja / total jam jadwal kerja) x 100.'

/**
 * Config Reminder Message Attendance
 */
const LangConfigReminderMessageAttendance = isLangEN ? 'Config Reminder Message Attendance' : 'Pengaturan Pesan Pengingat Absensi Masuk'
const LangReminderMessageAttendance = isLangEN ? 'Reminder Attendance Clock In' : 'Pesan Pengingat Presensi Masuk'
const LangMessageSuccessReminder = isLangEN ? 'Success Presence' : 'Sukses Presensi'
const LangReminderClockOutMessage = isLangEN ? 'Reminder Attendance Clock Out' : 'Pesan Pengingat Presensi Keluar'
const LangReminderClockOutHelp = isLangEN ? 'This message will be sent to the employee`s phone if five minutes after working hours have not made an exit presence.' : 'Pesan ini akan dikirimkan ke HP karyawan jika lima menit setelah jam selesai bekerja belum melakukan presensi keluar.'
const LangReminderAfterBreakMessage = isLangEN ? 'Reminder Attendance After Break' : 'Pesan Pengingat Presensi Selesai Istirahat'
const LangReminderAfterBreakHelp = isLangEN ? 'This message will be sent to the employee`s phone if 63 minutes after the break attendance has not made the attendance finish break.' : 'Pesan ini akan dikirimkan ke HP karyawan jika 65 menit setelah presensi istirahat belum melakukan presensi selesai istirahat.'

/**
 * izin
 */
const LangPermit = isLangEN ? 'Leave' : 'Izin'
export const LangWorkPermit = isLangEN ? 'Work Permit' : 'Izin'
const LangPermitRequest = isLangEN ? 'Request' : 'Pengajuan'
const LangPermitApproved = isLangEN ? 'Approved' : 'Disetujui'
const LangPermitRejected = isLangEN ? 'Rejected' : 'Ditolak'
const LangPermitToday = isLangEN ? 'Today' : 'Izin Hari Ini'
const LangPermitTomorrow = isLangEN ? 'Tomorrow' : 'Izin Besok'
const LangPermitNext7Day = isLangEN ? 'Next 7 Days' : 'Izin 7 Hari Kedepan'
const LangPermitCreatedAt = isLangEN ? 'Request Date' : 'Tgl. Pengajuan'
const LangPermitDates = isLangEN ? 'Leave Date' : 'Tgl. Izin'
const LangPermitDesc = isLangEN ? 'Necessity' : 'Keperluan'
export const LangPermitDeduction = isLangEN ? 'Will deduct wages' : 'Mengurangi Gaji'
export const LangPermitNotDeduction = isLangEN ? 'Not deduct wages' : 'Tidak mengurangi gaji'
export const LangNotWorkSchedule = isLangEN ? 'Not Work Schedule' : 'Bukan Jadwal Kerja'
export const LangPermitDeductionLabel = isLangEN ? 'This will deduct wages' : 'Izin ini akan mengurangi gaji'
const LangPermitRequestApproveConfirm = isLangEN ? 'Are you sure want to approve this request?' : 'Anda yakin akan menerima pengajuan izin ini?'
const LangPermitYesSure = isLangEN ? 'Yes sure' : 'Ya yakin'
const LangPermitNotNow = isLangEN ? 'Not Now' : 'Nanti dulu'
const LangPermitApprovedAt = isLangEN ? 'Approve date' : 'Tgl. Disetujui'
const LangPermitRejectedAt = isLangEN ? 'Reject date' : 'Tgl. Ditolak'
const LangPermitRequestReject = isLangEN ? 'Reject Request' : 'Tolak Pengajuan'
const LangPermitCancel = isLangEN ? 'Cancel' : 'Batalkan'
const LangPermitCancelHelp = isLangEN ? 'Check the permit date to be canceled' : 'Centang tanggal izin yang akan dibatalkan'
const LangPermitRequestCancel = isLangEN ? 'Cancel Permit' : 'Batalkan Izin'
const LangPermitApprove = isLangEN ? 'Approve' : 'Terima'
const LangPermitReject = isLangEN ? 'Reject' : 'Tolak'
const LangPermitStartDate = isLangEN ? 'Leave Start' : 'Mulai Izin'
const LangPermitEndDate = isLangEN ? 'Leave End' : 'Selesai Izin'
const LangPermitRequestDetail = isLangEN ? 'Request Detail' : 'Detail Pengajuan'
const LangPermitRequestDate = isLangEN ? 'Request Date' : 'Tgl. Pengajuan'
const LangPermitFiles = isLangEN ? 'Files' : 'File Kelengkapan'

const LangPrintApplication = isLangEN ? 'Print Leave Request' : 'Cetak Permohonan'
const LangPrint = isLangEN ? 'Print' : 'Cetak'
const LangPrintReceiver = isLangEN ? 'Receiver' : 'Penerima'

const LangAppVersion = isLangEN ? 'App Version' : 'Versi Aplikasi'
const LangDevice = isLangEN ? 'Device' : 'Perangkat'
const LangDeviceName = isLangEN ? 'Device Name' : 'Nama Perangkat'
const LangDeviceId = isLangEN ? 'Device Id' : 'Id Perangkat'
const LangDeviceModel = isLangEN ? 'Device Model' : 'Model Perangkat'
const LangDeviceType = isLangEN ? 'Device Type' : 'Tipe Perangkat'
const LangDeviceIp = isLangEN ? 'Device IP' : 'IP Perangkat'
const LangDeviceManufacture = isLangEN ? 'Device Manufacture' : 'Manufaktur Perangkat'

/**
 * menu
 */

const LangMenuLeaveRequest = isLangEN ? `${annualLeaveLabelEn}` : `${annualLeaveLabelId}`
const LangMenuSubscribeInfo = isLangEN ? 'Subscription Information' : 'Langganan'
const LangMenuPermitRequest = isLangEN ? 'Leave Request' : 'Izin Hari'

export const LangRecap = isLangEN ? 'Recap' : 'Rekap'
export const LangHolidayWarning = isLangEN ? 'This date holiday calendar cannot be updated' : 'Kalender libur tanggal ini sudah tidak dapat diperbarui'
export const LangEmployeeDoesntHaveGroup = isLangEN ? 'employees hasn\'t joined the division.' : 'karyawan yang belum masuk dalam divisi.'
export const LangEmployeeDoesntHaveWorktime = isLangEN ? 'employees do not have a work schedule.' : 'karyawan yang belum memiliki jadwal hari kerja.'
export const LangEmployeeDocExpired = isLangEN ? 'employees with expired related documents.' : 'karyawan yang dokumen terkaitnya berakhir.'
export const LangEmployeeDocInReminder = isLangEN ? 'employee whose related documents are in the reminder period.' : 'karyawan yang dokumen terkaitnya dalam masa ingatkan.'
export const LangPresenceDetail = isLangEN ? 'Presence Detail' : 'Detail Presensi'
export const LangEmailNotification = isLangEN ? 'Email Notification' : 'Notifikasi Email'
export const LangNotificationAndApproval = isLangEN ? 'Notification & Approval' : 'Notifikasi & Persetujuan'
export const LangCanSeeSelfiePhoto = isLangEN ? 'Can See Selfie Photo' : 'Dapat Melihat Foto Selfie'
export const LangCanSeeLocationMap = isLangEN ? 'Can See Location Map' : 'Dapat Melihat Map Lokasi'
export const LangCanSeeInfoDevice = isLangEN ? 'Can See Info Device' : 'Dapat Melihat Info Perangkat'
export const LangPresenceNote = isLangEN ? 'Presence Note' : 'Catatan Presensi'
export const LangPresenceReview = isLangEN ? 'Presence Review' : 'Review Presensi'
export const LangPresenceRevision = isLangEN ? 'Presence Revision' : 'Revisi Presensi'
export const LangPresenceRevisionNotification = isLangEN ? 'Presence Revision Notification' : 'Notifikasi Revisi Presensi'
export const LangLeaveDayRequestNotif = isLangEN ? 'Leave Request Day' : 'Permohonan Izin Hari'
export const LangLeaveHourRequest = isLangEN ? 'Leave Request Hour' : 'Permohonan Izin Jam'
export const LangUpdateUnpaidLeaveStatus = isLangEN ? 'Updating Status Unpaid Leave Reducing Salary' : 'Memperbarui Status Mengurangi Gaji Izin Hari'
export const LangAnnualLeaveRequest = isLangEN ? `${annualLeaveLabelEn} Request` : `Permohonan ${annualLeaveLabelId}`
export const LangChangeAnnualLeaveLabelButton = isLangEN ? `Change ${annualLeaveLabelEn} Label Button` : `Ganti Label Tombol ${annualLeaveLabelId}`
export const LangGroupBy = isLangEN ? 'Group By' : 'Kelompokan'
export const LangAttendanceCount = isLangEN ? 'Number of Attendances' : 'Jumlah Kehadiran'
export const LangEmployeeCount = isLangEN ? 'Number of Employees' : 'Jumlah Karyawan'
export const LangBasedOnAttendance = isLangEN ? 'Based on attendance' : 'Berdasarkan kehadiran'
export const LangAttendanceReviewEmailNotif = isLangEN ? 'Attendance Review Email Notification' : 'Email Notif Review Presensi'
export const LangFirstTimeAttendance = isLangEN ? 'First time attendance' : 'Presensi pertamakali'
export const LangUsingDifferentDevice = isLangEN ? 'Using a different device than before' : 'Menggunakan perangkat yang berbeda dengan sebelumnya'
export const LangOutsideAttendanceLocation = isLangEN ? 'Attendance Outside Location' : 'Di luar lokasi kehadiran'
export const LangOvertimeReview = isLangEN ? 'Overtime Review' : 'Review Lembur'
export const LangRejectionHistory = isLangEN ? 'Rejection History' : 'Riwayat Penolakan'
export const LangOvertimeTime = isLangEN ? 'Overtime' : 'Waktu Lembur'
export const LangOvertimePresenceApproval = isLangEN ? 'Overtime Presence Approval' : 'Persetujuan Presensi Lembur'
export const LangTotalOvertimeAmount = isLangEN ? 'Total Overtime Amount' : 'Jumlah Total Lembur'
export const LangOvertimeTotal = isLangEN ? 'Overtime Total' : 'Total Lembur'
export const LangUnkown = isLangEN ? 'Unkown' : 'Tidak Diketahui'
export const LangNotYetKnown = isLangEN ? 'Not Yet Known' : 'Belum Diketahui'
export const LangWorkStatus = isLangEN ? 'Employment Status' : 'Status Kerja'
export const LangPermitHourReview = isLangEN ? 'Leave Hour' : 'Review Izin Jam'
export const LangDuration = isLangEN ? 'Duration' : 'Durasi'
export const LangDurationTooltip = isLangEN ? 'By Google Calculation' : 'Menurut Perhitungan Google'
export const LangBy = isLangEN ? 'By' : 'Oleh'
export const LangAllowShiftExchange = isLangEN ? 'Allow employees to exchange shifts' : 'Izinkan Karyawan saling bertukar jadwal shift'
export const LangSet = isLangEN ? 'Set' : 'Atur'
export const LangSearchUser = isLangEN ? 'Search User' : 'Cari User'
export const LangShiftScheduleExchange = isLangEN ? 'Shift Schedule Exchange' : 'Pertukaran Jadwal Shift'
export const LangAllowReadStorageMobile = isLangEN ? 'Allow Read Storage Mobile' : 'Izinkan Membaca Penyimpanan Seluler'
export const LangHideEmployeeList = isLangEN ? 'Hide Employee List' : 'Sembunyikan Daftar Karyawan'
export const LangShowAllAnnualLeaveOnCalendar = isLangEN ? 'Show All Employees\' Annual Leave On Calendar' : 'Tampilkan Cuti Semua Karyawan Pada Kalender'
export const LangShowAllBirthdayOnCalendar = isLangEN ? 'Show All Employees\' Birthday On Calendar' : 'Tampilkan Ulang Tahun Semua Karyawan Pada Kalender'
export const LangFullfilled = isLangEN ? 'Fullfilled' : 'Terpenuhi'
export const LangNotFullfilled = isLangEN ? 'Not Fullfilled' : 'Tidak Terpenuhi'
export const LangLeaveHours = isLangEN ? 'Leave Hours' : 'Izin Jam'
export const LangReplacementHours = isLangEN ? 'Replacement Hours' : 'Jam Pengganti'
export const LangOther = isLangEN ? 'Other' : 'Lainnya'
export const LangWorkPatternTimezoneMsg1 = isLangEN ? 'Employees who are in a different time zone than the company' : 'Karyawan yang menggunakan zona waktu berbeda dengan Perusahaan'
export const LangWorkPatternTimezoneMsg1sub1 = isLangEN ? 'will be automatically adjusted' : 'akan otomatis disesuaikan'
export const LangWorkPatternTimezoneMsg1sub2 = isLangEN ? 'see more detailed explanation' : 'lihat penjelasan lebih detail'
export const LangWorkPatternTimezoneMsg1sub22 = isLangEN ? 'see a more detailed explanation' : 'lihat penjelasan lebih detail'
export const LangWorkPatternTimezoneMsg2 = ({ userTZ, companyTZ }) => (isLangEN ? <>These employees are in the <b>{userTZ}</b> time zone, which is different from the company's time zone <b>({companyTZ})</b>. The time will be automatically adjusted from <b>{companyTZ}</b> to <b>{userTZ}</b>,</> : <>Karyawan ini menggunakan zona waktu <b>{userTZ}</b> yang berbeda dengan Perusahaan <b>({companyTZ})</b>, waktu akan otomatis disesuaikan dari <b>{companyTZ}</b> ke <b>{userTZ}</b>,</>)
export const LangNews = isLangEN ? 'News' : 'Berita'
export const LangAttendanceApproval = isLangEN ? 'Attendance Approval' : 'Persetujuan Presensi'
export const LangAllowed = isLangEN ? 'Allowed' : 'Izinkan'
export const LangAccepted = isLangEN ? 'Accepted' : 'Diterima'
export const LangNotYetAccepted = isLangEN ? 'Not yet accepted' : 'Belum diterima'
export const LangAttendanceRestriction = isLangEN ? 'Attendance Restriction' : 'Pembatasan Presensi'
export const LangMinuteAfterClockin = isLangEN ? 'Minute After Clock In' : 'Menit Setelah Jadwal Masuk'
export const LangMinuteBeforeClockin = isLangEN ? 'Minute Before Clock In' : 'Menit Sebelum Jadwal Masuk'
export const LangMinuteAfterClockout = isLangEN ? 'Minute After Clock Out' : 'Menit Setelah Jadwal Keluar'
export const LangBulkAction = isLangEN ? 'Bulk Action' : 'Aksi Massal'
export const LangBulkAction1 = isLangEN ? 'Equalize the remaining leave days' : 'Samakan sisa cuti'
export const LangBulkAction2 = isLangEN ? 'Decrease the remaining leave days' : 'Kurangi sisa cuti'
export const LangBulkAction3 = isLangEN ? 'Increase the remaining leave days' : 'Tambah sisa cuti'
export const LangBulkAction4 = isLangEN ? 'Switch division' : 'Pindah Divisi'
export const LangBulkAction5 = isLangEN ? 'Delete Employee' : 'Hapus Karyawan'
export const LangBulkAction6 = isLangEN ? 'Send Verification Email' : 'Kirim Email Verifikasi'
export const LangBulkActionWarning = isLangEN ? 'Please be careful, submitted action cannot be undone' : 'Mohon hati-hati, aksi yang sudah dikirimkan tidak dapat dibatalkan.'
export const LangSubstract = isLangEN ? 'Substract' : 'Kurangi'
export const LangActiveMonth = isLangEN ? 'Active Month' : 'Bulan Masa Aktif'
export const LangAddeddValue = isLangEN ? 'Added Value' : 'Nilai Penambahan'
export const LangSubstractionValue = isLangEN ? 'Substraction Value' : 'Nilai Pengurangan'
export const LangActionSuccessfulSent = isLangEN ? 'Action Successful Sent' : 'Aksi Berhasil Dikirimkan'
export const LangSlackIntegration = isLangEN ? 'Slack Integration' : 'Integrasi Slack'
export const LangTelegramIntegration = isLangEN ? 'Telegram Integration' : 'Integrasi Telegram'
export const LangApprovedBy = isLangEN ? 'Approved by' : 'Disetujui oleh'
export const LangAttendanceApprovalInfo = isLangEN ? 'List of employees who are unable to attend due to time restrictions.' : 'Daftar karyawan yang tidak dapat melakukan presensi karena pembatasan waktu.'
export const LangGroupAttendanceLimitInfo = isLangEN ? 'Limit the attendance of employees to enter/leave only at certain times.' : 'Batasi presensi masuk/keluar karyawan hanya bisa pada waktu tertentu.'
export const LangHowToIntegrate = isLangEN ? 'How To Integrate?' : 'Bagaimana Cara Integrasinya?'
export const LangHowToGetChatId = isLangEN ? 'How to get Chat ID' : 'Cara mendapatkan Chat ID'
export const LangSlackIntegrationInfo = isLangEN ? 'Connect the Kerjoo application with Slack, which aims to make HR/Admins get notifications on Slack when employees make a presence in the Kerjoo application.' : 'Hubungkan aplikasi Kerjoo dengan Slack yang bertujuan agar HR/Admin mendapatkan notifikasi di Slack saat karyawan melakukan presensi di aplikasi Kerjoo.'
export const LangTelegramIntegrationInfo = isLangEN ? 'Connect the Kerjoo application with Telegram, which aims to make HR/Admins get notifications on Telegram when employees make a presence in the Kerjoo application.' : 'Hubungkan aplikasi Kerjoo dengan Telegram yang bertujuan agar HR/Admin mendapatkan notifikasi di Telegram saat karyawan melakukan presensi di aplikasi Kerjoo.'
export const LangWebhookInfo = isLangEN ? 'Every incoming presence will be sent directly to the Webhook URL that you set with the POST method. Kerjoo will detect a failure if the Webhook URL does not return a 2xx status code, and Kerjoo will try to retransmit a maximum of five attempts over two minutes.' : 'Setiap presensi yang masuk, akan langsung dikirimkan juga ke Webhook URL yang Anda atur dengan method POST. Kerjoo akan mendeteksi gagal jika Webhook URL tidak mengembalikan status code 2xx, dan Kerjoo akan mencoba mengirimkan ulang maksimal lima kali percobaan selama dua menit.'
export const LangWebhookSampleDataCaption = isLangEN ? 'Here\'s the structure and sample data your URL Webhook will receive' : 'Berikut struktur dan contoh data yang akan diterima oleh Webhook URL Anda'
export const LangActivateNotification = isLangEN ? 'Activate Notification' : 'Aktifkan Notifikasi'
export const LangShowPresenceNote = isLangEN ? 'Show Presence Note' : 'Tampilkan Catatan Presensi'
export const LangShowAttendanceSpot = isLangEN ? 'Show Attendance Spot' : 'Tampilkan Lokasi Kehadiran'
export const LangActionSuccessMessage = isLangEN ? 'Action Successful Sent, You will get a notification email when the action is finished processing.' : 'Aksi Berhasil Dikirimkan,  Anda akan mendapatkan email notifikasi jika aksi selesai diproses.'
export const LangNewIntegration = isLangEN ? 'New Integration' : 'Integrasi Baru'
export const LangDeviceValidation = isLangEN ? 'Device Validation' : 'Validasi Perangkat'
export const LangRecapAutoClockOut = isLangEN ? 'Recap Auto Clock Out' : 'Rekap Presensi Keluar Otomatis'
export const LangEarlyClockOut = isLangEN ? 'Early Clock Out' : 'Presensi Pulang Lebih Awal'
export const LangLateAfterBreak = isLangEN ? 'Late After Break' : 'Presensi Selesai Istirahat Lebih Lama'
export const LangGetEmailNotification = isLangEN ? 'Get Email Notification' : 'Dapatkan Notifikasi Email'
export const LangDeviceValidationActivate = isLangEN ? 'Device Validation Activate' : 'Aktifkan Validasi Perangkat'
export const LangDeviceApproval = isLangEN ? 'Device Approval' : 'Persetujuan Perangkat'
export const LangShiftChangeScheduleRecap = isLangEN ? 'Switch Shift Schedule Recap' : 'Rekap Tukar Jadwal Shift'
export const LangWorkPatternDeleted = isLangEN ? 'Work Pattern Has Been Removed' : 'Pola Kerja Sudah Dihapus'
export const LangDeviceInfo = isLangEN ? 'Device Info' : 'Info Perangkat'
export const LangDailyReport = isLangEN ? 'Daily Report' : 'Laporan Harian'
export const LangDailyReportTime = isLangEN ? 'Daily Report Time' : 'Waktu Laporan Harian'
export const LangDailyReportInfo = isLangEN ? 'Please set a time when the daily attendance report notification email is sent to your email.' : 'Silakan atur waktu kapan email notifikasi laporan kehadiran harian dikirimkan ke email Anda.'
export const LangAutomaticClockout = isLangEN ? 'Automatic Clockout' : 'Presensi Keluar Otomatis'
export const LangAutomaticClockoutAsSchedule = isLangEN ? 'Force attendance time according to schedule' : 'Jadikan jam presensi keluar sesuai jadwal'
export const LangDeviceApproveConfirm = isLangEN ? 'Are you sure you allow this device? ' : 'Apakah anda yakin mengizinkan perangkat ini?'
export const LangActionConfirm = isLangEN ? 'Are you sure to continue this action? ' : 'Apakah anda yakin melanjutkan aksi ini?'
export const LangDeletePermanentConfirm = isLangEN ? 'Are you sure you want to permanently delete this data ? ' : 'Apakah Anda yakin ingin menghapus data ini secara permanen?'
export const LangRegisteredDevice = isLangEN ? 'Registered Device' : 'Perangkat Terdaftar'
export const LangManagePayroll = isLangEN ? 'Manage Payroll' : 'Kelola Gaji'
export const LangViewDetails = isLangEN ? 'View Details' : 'Lihat Detail'
export const LangStartDate = isLangEN ? 'Start Date' : 'Tanggal Mulai'
export const LangEndDate = isLangEN ? 'End Date' : 'Tanggal Selesai'
export const LangStartDeleted = isLangEN ? 'Start Deleted' : 'Dihapus Mulai'
export const LangEndDeleted = isLangEN ? 'End Deleted' : 'Dihapus Sampai'
export const LangAttendanceRevisionInfo = isLangEN ? 'Employee attendance revision requests that require validation' : 'Permintaan revisi kehadiran karyawan yang memerlukan validasi'
export const LangDeviceApprovalInfo = isLangEN ? 'List of employees with smartphone devices that require validation' : 'Daftar karyawan dengan perangkat smartphone yang memerlukan validasi'
export const LangOvertimeReviewInfo = isLangEN ? 'List of employee overtime hours that require validation' : 'Daftar jam lembur karyawan yang memerlukan validasi'
export const LangLeaveHourReviewInfo = isLangEN ? 'List of employee leave in hours that require validation' : 'Daftar izin dalam jam karyawan yang memerlukan validasi'
export const LangBroadcastInfo = isLangEN ? 'List of broadcasts that the company sends to employees' : 'Daftar broadcast yang dikirimkan perusahaan kepada karyawan'
export const LangPermitRequestInfo = isLangEN ? 'List of employees who do leaves and require action answers' : 'Daftar karyawan yang melakukan izin dan membutuhkan aksi jawaban'
export const LangLeaveRequestInfo = isLangEN ? 'List of employees who do paid leave and require action answers' : 'Daftar karyawan yang melakukan cuti dan membutuhkan aksi jawaban'
export const LangClientVisit = isLangEN ? 'Client Visit' : 'Kunjungan Klien'
export const LangClientDeletedAt = isLangEN ? 'Deleted at' : 'Dihapus pada'
export const LangClientVisitStart = isLangEN ? 'Client Visit Start' : 'Kunjungan Klien Datang'
export const LangMustClockInFirst = isLangEN ? 'Must Clock In First' : 'Harus Presensi Masuk Dulu'
export const LangMustEndBeforeStart = isLangEN ? 'Must clock out the visit before clock in the next visit' : 'Harus menyelesaikan kunjungan sebelum mulai kunjungan berikutnya'
export const LangClientVisitEnd = isLangEN ? 'Client Visit End' : 'Kunjungan Klien Pulang'
export const LangClient = isLangEN ? 'Client' : 'Klien'
export const LangTotalDistance = isLangEN ? 'Total Distance' : 'Total Jarak'
export const LangStartPoint = isLangEN ? 'Start Point' : 'Titik Awal'
export const LangEndPoint = isLangEN ? 'End Point' : 'Titik Tujuan'
export const LangDistance = isLangEN ? 'Distance' : 'Jarak'
export const LangDetailDistanceDesc = isLangEN ? 'The distance will be automatically calculated if there is a distance calculation quota.' : 'Jarak akan otomatis dihitung jika terdapat kuota hitung jarak.'
export const LangReturn = isLangEN ? 'Return' : 'Pulang'
export const LangDepart = isLangEN ? 'Depart' : 'Pergi'
export const LangVisit = isLangEN ? 'Visit' : 'Kunjungan'
export const LangVisitSummary = isLangEN ? 'Visit Summary' : 'Ringkasan Kunjungan'
export const LangVisitScheduleTotal = isLangEN ? 'Visit Schedule Total' : 'Total Jadwal Kunjungan'
export const LangVisitTotal = isLangEN ? 'Visit Total' : 'Total Kunjungan'
export const LangVisitPercentage = isLangEN ? 'Visit Percentage' : 'Persentase Kunjungan'
export const LangPhoto = isLangEN ? 'Photo' : 'Foto'
export const LangSignature = isLangEN ? 'Signature' : 'Tanda Tangan'
export const LangRecalculate = isLangEN ? 'Recalculate' : 'Hitung Ulang'
export const LangWaitSuccessMsg = isLangEN ? 'The request was successfully submitted, please wait at least a minute and refresh to see the results.' : 'Permintaan berhasil dikirimkan, mohon tunggu setidaknya satu menit dan refresh untuk melihat hasil.'
export const LangBaseSalary = isLangEN ? 'Base Salary' : 'Gaji Dasar'
export const LangImportBaseSalary = isLangEN ? 'Import Base Salary' : 'Impor Gaji Dasar'
export const LangImportExcelBaseSalary = isLangEN ? 'Import Excel Base Salary' : 'Impor Excel Gaji Dasar'
export const LangImportExcelBaseSalaryFormat = isLangEN ? 'Download Base Salary Import Format' : 'Unduh Format Impor Gaji Dasar'
export const LangTotalPayroll = isLangEN ? 'Total Payroll' : 'Total Gaji'
export const LangTotalDeduction = isLangEN ? 'Total Deduction' : 'Total Potongan'
export const LangTotalWorkHoursAndOvertime = isLangEN ? 'Total Work Hours & Overtime' : 'Total Jam Kerja & Lembur'
export const LangValueSetting = isLangEN ? 'Values Setting' : 'Pengaturan Nilai'
export const LangValue = isLangEN ? 'Value' : 'Nilai'
export const LangSetBaseSalary = isLangEN ? 'Set Base Salary' : 'Atur Gaji Dasar'
export const LangCopyBaseSalary = isLangEN ? 'Align with Other Employees' : 'Samakan dengan Karyawan Lain'
export const LangSelectReferenceEmployee = isLangEN ? 'Select Reference Employee' : 'Pilih Karyawan Acuan'
export const LangReferenceEmployee = isLangEN ? 'Reference Employee' : 'Karyawan Acuan'
export const LangCopyBaseSalaryWith = isLangEN ? 'Align the base salary settings of' : 'Samakan pengaturan gaji dasar'
export const LangWith = isLangEN ? 'with' : 'dengan'
export const LangActionSuccessfullyApplied = isLangEN ? 'Action successfully applied.' : 'Aksi berhasil diterapkan.'
export const LangSetDeduction = isLangEN ? 'Set Deduction' : 'Atur Potongan'
export const LangSetBonus = isLangEN ? 'Set Bonus' : 'Atur Bonus'
export const LangWages = isLangEN ? 'Wages' : 'Upah'
export const LangOvertimeWages = isLangEN ? 'Overtime Wages' : 'Upah Lembur'
export const LangPerHour = isLangEN ? 'Per Hour' : 'Per Jam'
export const LangPerDay = isLangEN ? 'Per Day' : 'Per Hari'
export const LangOtherDeduction = isLangEN ? 'Other Deduction' : 'Potongan Lainnya'
export const LangPeriod = isLangEN ? 'Period' : 'Periode'
export const LangPaid = isLangEN ? 'Paid' : 'Terbayar'
export const LangPay = isLangEN ? 'Pay' : 'Bayar'
export const LangPayrollMessage = isLangEN ? 'Are you sure you want to do this action? Salaries that have been paid will be visible to employees through the Kerjoo mobile application.' : 'Anda yakin akan melakukan aksi ini? Gaji yang telah dibayar akan dapat dilihat oleh karyawan melalui aplikasi mobile Kerjoo.'
export const LangCalculate = isLangEN ? 'Calculate' : 'Hitung'
export const LangPayrollCalculate = isLangEN ? 'Payroll Calculate' : 'Hitung Gaji'
export const LangPremiumFeatures = isLangEN ? 'Premium Features' : 'Fitur Premium'
export const LangDeduction = isLangEN ? 'Deduction' : 'Potongan'
export const LangPayLoan = isLangEN ? 'Pay Loan' : 'Bayar Pinjaman'
export const LangEarnings = isLangEN ? 'Earnings' : 'Pendapatan'
export const LangTotalEarnings = isLangEN ? 'Total Earnings' : 'Total Pendapatan'
export const LangReceivedBy = isLangEN ? 'Received By' : 'Diterima Oleh'
export const LangEmployeePaySlip = isLangEN ? 'Employee Payslip' : 'Slip Gaji Karyawan'
export const LangNetSalary = isLangEN ? 'Net Salary' : 'Gaji Bersih'
export const LangBasicSalary = isLangEN ? 'Basic Salary' : 'Gaji Pokok'
export const LangPaidPayroll = isLangEN ? 'Paid Payroll' : 'Gaji Terbayar'
export const LangPositionalAllowance = isLangEN ? 'Positional allowance' : 'Tunjangan Jabatan'
export const LangOvertimeSalary = isLangEN ? 'Overtime' : 'Lembur'
export const LangTotalReceived = isLangEN ? 'Total Recevied' : 'Total Diterima'
export const LangNew = isLangEN ? 'New' : 'Baru'
export const LangBaseSalaryInfo = isLangEN ? 'Total employee payments for salary calculations multiplied by hours worked according to time of attendance' : 'Jumlah pembayaran karyawan untuk perhitungan gaji dikalikan jam kerja sesuai waktu kehadiran'
export const LangCanModifyPresence = isLangEN ? 'Can Modify Presence' : 'Dapat Memodifikasi Presensi'
export const LangSalaryCalculateInfo = isLangEN ? 'The salary calculation period can be set through the time range above. For the salary calculation formula, can be seen' : 'Periode perhitungan gaji dapat diatur melalui rentang waktu di atas. Untuk rumus perhitungan gaji dapat dilihat'
export const LangHere = isLangEN ? 'Here' : 'Di sini'
export const LangSalaryCalculationFormula = isLangEN ? 'Salary Calculation Formula' : 'Rumus Perhitungan Gaji'
// eslint-disable-next-line
export const LangAvailableFeatureInfo = isLangEN ? 'This feature will be available at minimum on the :package package.' : ('Fitur ini akan tersedia minimal pada paket :package' + '.')
export const LangPresenceChangeInfo = isLangEN ? 'Change Leave In Hour' : 'Ganti Jam Izin'
export const LangNotSetYet = isLangEN ? 'Not Set Yet' : 'Belum Diatur'
export const LangSelectColumn = isLangEN ? 'Select Column' : 'Pilih Kolom'
export const LangSelectColumnPlaceholder = isLangEN ? 'Type name column' : 'Ketik nama kolom'
export const LangSelectColumnInfo = isLangEN ? 'Select columns you want to display in excel' : 'Pilih kolom yang ingin ditampilkan pada excel'
export const LangEmployeeName = isLangEN ? 'Employee Name' : 'Nama Karyawan'
export const LangSchedule = isLangEN ? 'Schedule' : 'Jadwal'
export const LangAttendanceDay = isLangEN ? 'Attendance Day' : 'Hari Presensi'
export const LangAttendanceDate = isLangEN ? 'Attendance Date' : 'Tanggal Presensi'
export const LangLateMinute = isLangEN ? 'Late Minute' : 'Terlambat Menit'
export const LangSummary = isLangEN ? 'Summary' : 'Ringkasan'
export const LangPaymentSuccess = isLangEN ? 'Payment success!' : 'Pembayaran berhasil!'
export const LangPaymentCloseModal = isLangEN ? 'You closed the popup without finishing the payment' : 'Anda menutup popup tanpa menyelesaikan pembayaran. Cek email untuk melanjutkan pembayaran.'
export const LangNPWPNumber = isLangEN ? 'NPWP' : 'NPWP'
export const LangAddAttendance = isLangEN ? 'Add Employee Attendance' : 'Tambah Presensi Karyawan'
export const LangSelectEmployee = isLangEN ? 'Select Employee' : 'Pilih Karyawan'
export const LangAttendanceType2 = isLangEN ? 'Attendance Type' : 'Tipe Presensi'
export const LangAttendanceStartTime = isLangEN ? 'Attendance Start Time' : 'Waktu Presensi Mulai'
export const LangAttendanceEndTime = isLangEN ? 'Attendance End Time' : 'Waktu Presensi Selesai'
export const LangFormatFull = isLangEN ? 'Full' : 'Lengkap'
export const LangFormatOnlyClockInOut = isLangEN ? 'Only Clock In & Clock Out' : 'Hanya Presensi Masuk & Keluar'
export const LangFormatOnlyClockInLateOut = isLangEN ? 'Only Clock In, Late & Clock Out' : 'Hanya Presensi Masuk, Terlambat & Keluar'
export const LangFormatOnlyClockInLateOutOvertime = isLangEN ? 'Only Clock In, Late, Clock Out & Overtime' : 'Hanya Presensi Masuk, Terlambat, Keluar & Lembur'
export const LangFormatOnlyNotes = isLangEN ? 'Only Notes' : 'Hanya Catatan'
export const LangFormatPresenceRecap = isLangEN ? 'Presence Recap' : 'Rekap Presensi'
export const LangFormatAttendanceRecap = isLangEN ? 'Attendance Recap' : 'Rekap Kehadiran'
export const LangFormatFullWithDateDetail = isLangEN ? 'Full with date details' : 'Lengkap dengan detail tanggal'
export const LangFormatFullWithoutDateDetail = isLangEN ? 'Full without date details' : 'Lengkap tanpa detail tanggal'
export const LangFormatOnlyDateDetail = isLangEN ? 'Only date details' : 'Hanya detail tanggal'

export const LangClockInTime = isLangEN ? 'Clock In Time' : 'Masuk Jam'
export const LangClockInSpotName = isLangEN ? 'Clock In Spot Name' : 'Masuk Nama Spot'
export const LangClockInLatitude = isLangEN ? 'Clock In Latitude' : 'Masuk Latitude'
export const LangClockInLongitude = isLangEN ? 'Clock In Longitude' : 'Masuk Longitude'
export const LangClockInNote = isLangEN ? 'Clock In Note' : 'Masuk Catatan'
export const LangClockInSupervision = isLangEN ? 'Clock In Supervision' : 'Masuk Supervisi'
export const LangClockInSelfie = isLangEN ? 'Clock In Selfie' : 'Masuk Selfie'

export const LangClockOutTime = isLangEN ? 'Clock Out Time' : 'Keluar Jam'
export const LangClockOutSpotName = isLangEN ? 'Clock Out Spot Name' : 'Keluar Nama Spot'
export const LangClockOutLatitude = isLangEN ? 'Clock Out Latitude' : 'Keluar Latitude'
export const LangClockOutLongitude = isLangEN ? 'Clock Out Longitude' : 'Keluar Longitude'
export const LangClockOutNote = isLangEN ? 'Clock Out Note' : 'Keluar Catatan'
export const LangClockOutSupervision = isLangEN ? 'Clock Out Supervision' : 'Keluar Supervisi'
export const LangClockOutSelfie = isLangEN ? 'Clock Out Selfie' : 'Keluar Selfie'

export const LangBreakTime = isLangEN ? 'Break Time' : 'Istirahat Jam'
export const LangBreakTime2 = isLangEN ? 'Break Time' : 'Istirahat'
export const LangBreakSpotName = isLangEN ? 'Break Spot Name' : 'Istirahat Nama Spot'
export const LangBreakLatitude = isLangEN ? 'Break Latitude' : 'Istirahat Latitude'
export const LangBreakLongitude = isLangEN ? 'Break Longitude' : 'Istirahat Longitude'

export const LangAfterBreakTime = isLangEN ? 'After Break Time' : 'Selesai Istirahat Jam'
export const LangAfterBreakSpotName = isLangEN ? 'After Break Spot Name' : 'Selesai Istirahat Nama Spot'
export const LangAfterBreakLatitude = isLangEN ? 'After Break Latitude' : 'Selesai Istirahat Latitude'
export const LangAfterBreakLongitude = isLangEN ? 'After Break Longitude' : 'Selesai Istirahat Longitude'

export const LangOvertimeInTime = isLangEN ? 'Overtime Time' : 'Lembur Jam'
export const LangOvertimeInSpotName = isLangEN ? 'Overtime Spot Name' : 'Lembur Nama Spot'
export const LangOvertimeInLatitude = isLangEN ? 'Overtime Latitude' : 'Lembur Latitude'
export const LangOvertimeInLongitude = isLangEN ? 'Overtime Longitude' : 'Lembur Longitude'
export const LangOvertimeInNote = isLangEN ? 'Overtime Note' : 'Lembur Catatan'
export const LangOvertimeInSupervision = isLangEN ? 'Overtime Supervision' : 'Lembur Supervisi'
export const LangOvertimeInSelfie = isLangEN ? 'Overtime Selfie' : 'Lembur Selfie'

export const LangOvertimeOutTime = isLangEN ? 'Overtime Out Time' : 'Selesai Lembur Jam'
export const LangOvertimeOutSpotName = isLangEN ? 'Overtime Out Spot Name' : 'Selesai Lembur Nama Spot'
export const LangOvertimeOutLatitude = isLangEN ? 'Overtime Out Latitude' : 'Selesai Lembur Latitude'
export const LangOvertimeOutLongitude = isLangEN ? 'Overtime Out Longitude' : 'Selesai Lembur Longitude'
export const LangOvertimeOutNote = isLangEN ? 'Overtime Out Note' : 'Selesai Lembur Catatan'
export const LangOvertimeOutSupervision = isLangEN ? 'Overtime Out Supervision' : 'Selesai Lembur Supervisi'
export const LangOvertimeOutSelfie = isLangEN ? 'Overtime Out Selfie' : 'Selesai Lembur Selfie'
export const LangEmployeeLateNotification = isLangEN ? 'Employee late Notification' : 'Notifikasi Karyawan Terlambat'
export const LangAccountLabel = isLangEN ? 'Account' : 'Akun'

export const LangPerMonth = isLangEN ? 'Per Month' : 'Per Bulan'
export const LangSalaryType = isLangEN ? 'Salary Type' : 'Tipe Gaji'
export const LangOvertimeSalaryType = isLangEN ? 'Overtime Salary Type' : 'Tipe Upah Lembur'
export const LangSalaryDetails = isLangEN ? 'Salary Details' : 'Rincian Gaji'
export const LangOvertimeDetails = isLangEN ? 'Overtime Details' : 'Detail Lembur'
export const LangPermonth = isLangEN ? 'Permonth' : 'Perbulan'
export const LangPerattendance = isLangEN ? 'Perattendance' : 'Perkehadiran'
export const LangTotalWorkDaySchedule = isLangEN ? 'Total Work Day Schedule' : 'Total Hari Jadwal Kerja'
export const LangAttendancePercentage = isLangEN ? 'Attendance Percentage' : 'Persentase Kehadiran'
export const LangTotalHoursWorkSchedule = isLangEN ? 'Total Hours Work Schedule' : 'Total Jam Jadwal Kerja'
export const LangPercentageAchievedWorkingHours = isLangEN ? 'Percentage Working Hours' : 'Persentase Capaian Jam Kerja'
export const LangLeaveHoursDeduction = isLangEN ? 'Leave Hours Deduction' : 'Potongan Izin Jam'
export const LangWorkHoursNotFulfilled = isLangEN ? 'Work Hours Not Fulfilled' : 'Potongan Jam Kerja Tidak Terpenuhi'
export const LangWorkHoursNotFulfilled2 = isLangEN ? 'Total Hours Not Fulfilled' : 'Total Jam Tidak Terpenuhi'
export const LangTotalUnfullfiledLeaveHours = isLangEN ? 'Total Unfulfilled Leave Hours' : 'Total Izin Jam Belum Diganti'
export const LangAbsenceDeductionsPerDay = isLangEN ? 'Absence Deductions Per Day' : 'Potongan Absen Per Hari'
export const LangAbsenceDeductionsPerDayRates = isLangEN ? 'Absence Deductions Per Day Rates' : 'Nominal Potongan Absen Per Hari'
export const LangAutomatic = isLangEN ? 'Automatic' : 'Otomatis'
export const LangManual = isLangEN ? 'Manual' : 'Manual'

export const LangDragableLocation = isLangEN ? 'Dragable Location' : 'Lokasi Dapat Digeser'
export const LangClientVisitDepart = isLangEN ? 'Depart' : 'Datang'
export const LangCreated = isLangEN ? 'Created' : 'Dibuat'
export const LangUpdated = isLangEN ? 'Updated' : 'Diperbarui'
export const LangDeleted = isLangEN ? 'Deleted' : 'Dihapus'
export const LangEdited = isLangEN ? 'Edited' : 'Diubah'
export const LangSubject = isLangEN ? 'Subject' : 'Subjek'

export const LangDocuments = isLangEN ? 'Documents' : 'Dokumen'
export const LangEmployeeDocuments = isLangEN ? 'Employee Documents' : 'Dokumen Karyawan'
export const LangTurnOffReminder = isLangEN ? 'Turn Off Reminder' : 'Matikan Pengingat'
export const LangManageDocuments = isLangEN ? 'Manage Documents' : 'Kelola Dokumen'
export const LangAddDocument = isLangEN ? 'Add Document' : 'Tambah Dokumen'
export const LangDocumentName = isLangEN ? 'Document Name' : 'Nama Dokumen'
export const LangFile = isLangEN ? 'File' : 'Berkas'
export const LangExpiredDate = isLangEN ? 'Expired Date' : 'Tanggal Berakhir'
export const LangReminder = isLangEN ? 'Reminder' : 'Pengingat'
export const LangReminderDayInfo = isLangEN ? 'Day Before Expired' : 'Hari Sebelum Berakhir'
export const LangDayBefore = isLangEN ? 'Day Before' : 'Hari Sebelum'
export const LangExpiredDateNotSet = isLangEN ? 'Expired Date Not Set Yet' : 'Tanggal Berakhir Belum Diatur'
export const LangDocumentPeriodExpires = isLangEN ? 'Document period expire' : 'Masa dokumen berakhir'
export const LangDocumentInReminderPeriod = isLangEN ? 'Document in reminder period' : 'Dokumen dalam masa pengingat'
export const LangUploadedAt = isLangEN ? 'Uploaded At' : 'Diunggah Pada'
export const LangFileMaxSize = isLangEN ? 'Maximum size 500kb' : 'Ukuran maksimal 500kb'
export const LangOutOfQuotaPersonnelDocsMessage = isLangEN ? 'File quota for this employee has been exceeded the limit' : 'Kuota file untuk karyawan ini sudah melebihi dari batas'
export const LangCanModifyDocumentEmployee = isLangEN ? 'Can Modify Employee Document' : 'Dapat Memodifikasi Dokumen Karyawan'
export const LangPayrollDateRangeInfo = isLangEN ? 'Maximum period of 60 days' : 'Maksimal jangka waktu  60 hari'
export const LangScheduleName = isLangEN ? 'Schedule Name' : 'Nama Jadwal'
export const LangPermitHours = isLangEN ? 'Leave Hours' : 'Izin Jam'
export const LangPermitDays = isLangEN ? 'Leave Days' : 'Izin Hari'
export const LangHidePermitHourButton = isLangEN ? 'Hide Unpaid Leave Hour Button' : 'Sembunyikan Tombol Izin Jam'
export const LangOvertimeWagesSaturday = isLangEN ? 'Overtime Wages Saturday' : 'Upah Lembur Sabtu'
export const LangOvertimeWagesSunday = isLangEN ? 'Overtime Wages Sunday' : 'Upah Lembur Minggu'
export const LangOvertimeWagesHolidayCalendar = isLangEN ? 'Overtime Wages Holiday Calendar' : 'Upah Lembur Kalendar Libur'
export const LangTotalOvertimeHoursWeekday = isLangEN ? 'Total Overtime Hours Weekdays' : 'Total Jam Lembur Hari Kerja'
export const LangTotalOvertimeHoursSaturday = isLangEN ? 'Total Overtime Hours Saturday' : 'Total Jam Lembur Sabtu'
export const LangTotalOvertimeHoursSunday = isLangEN ? 'Total Overtime Hours Sunday' : 'Total Jam Lembur Minggu'
export const LangTotalOvertimeHoursHolidayCalendar = isLangEN ? 'Total Overtime Hours Holiday Calendar' : 'Total Jam Lembur Kalendar Libur'
export const LangOvertimeWeekday = isLangEN ? 'Overtime Weekday' : 'Lembur Hari Kerja'
export const LangOvertimeSaturday = isLangEN ? 'Overtime Saturday' : 'Lembur Hari Sabtu'
export const LangOvertimeSunday = isLangEN ? 'Overtime Sunday' : 'Lembur Hari Minggu'
export const LangOvertimeHolidayCalendar = isLangEN ? 'Overtime Holiday Calendar' : 'Lembur Kalendar Libur'
export const LangNormalDay = isLangEN ? 'Normal Day' : 'Hari Kerja'
export const LangInReview = isLangEN ? 'In Review' : 'Dalam Tinjauan'
export const LangAttendanceHour = isLangEN ? 'Attendance Hour' : 'Jam Presensi'
export const LangStart = isLangEN ? 'Start' : 'Mulai'
export const LangEnd = isLangEN ? 'End' : 'Selesai'
export const LangMax = isLangEN ? 'Max.' : 'Maks.'
export const LangPayroll = isLangEN ? 'Payroll' : 'Gaji'
export const LangTimeStart = isLangEN ? 'Time Start' : 'Jam Mulai'
export const LangTimeEnd = isLangEN ? 'Time End' : 'Jam Selesai'
export const LangPayrollPasswordInfo = isLangEN ? 'Please set a password to manage the Calculate Salary page.' : 'Silakan atur kata sandi untuk mengelola halaman Hitung Gaji.'
export const LangPayrollPaidPasswordInfo = isLangEN ? 'Please set a password to manage Salary.' : 'Silakan atur kata sandi untuk mengelola Gaji.'
export const LangPayrollLoginInfo = isLangEN ? 'Please enter the password to manage the Calculate Salary page.' : 'Silakan masukkan kata sandi untuk mengelola halaman Hitung Gaji.'
export const LangPayrollPaidLoginInfo = isLangEN ? 'Please enter the password to manage Salary.' : 'Silakan masukkan kata sandi untuk mengelola Gaji.'
export const LangDeletePassword = isLangEN ? 'Delete Password' : 'Hapus Kata Sandi'
export const LangPayrollAuthError = isLangEN ? 'Access denied, try refreshing the page to re login' : 'Akses ditolak, coba segarkan halaman untuk masuk kembali'
export const LangPayrollAuthErrorPassword = isLangEN ? 'The password you entered is incorrect, please try again.' : 'Kata sandi yang Anda inputkan tidak benar, silakan coba lagi.'
export const LangPayrollAuthLoginSuccess = isLangEN ? 'Login successfull' : 'Berhasil Masuk'
export const LangPageUnlocked = isLangEN ? 'Page Unlocked' : 'Halaman Tidak Dikunci'
export const LangPageLocked = isLangEN ? 'Page Locked' : 'Halaman Dikunci'
export const LangPayrollPasswordAlertInfo = isLangEN ? 'to manage the Calculate Salary page. Make sure all admins know the password you set.' : 'untuk mengelola halaman Hitung Gaji. Pastikan semua admin mengetahui kata sandi yang Anda atur.'
export const LangPagePassword = isLangEN ? 'Page Password' : 'Kata Sandi Halaman'
export const LangPlease = isLangEN ? 'Please' : 'Silakan'
export const LangApprover = isLangEN ? 'Approver' : 'Penyetuju'
export const LangLateToleranceSecond = isLangEN ? 'Late Tolerance Second' : 'Detik Toleransi Terlambat'
export const LangPaidDate = isLangEN ? 'Paid Date' : 'Tanggal Bayar'
export const LangClearDeductions = isLangEN ? 'Clear deductions after this payment' : 'Kosongkan potongan setelah pembayaran ini'
export const LangClearBonus = isLangEN ? 'Clear bonus after this payment' : 'Kosongkan bonus setelah pembayaran ini'
export const LangPayrollBulkAction1 = isLangEN ? 'Add Deduction' : 'Tambah Potongan'
export const LangPayrollBulkAction2 = isLangEN ? 'Delete Specific Deduction' : 'Hapus Spesifik Potongan'
export const LangPayrollBulkAction3 = isLangEN ? 'Delete All Deduction' : 'Hapus Semua Potongan'
export const LangPayrollBulkAction4 = isLangEN ? 'Add Bonus' : 'Tambah Bonus'
export const LangPayrollBulkAction5 = isLangEN ? 'Delete Specific Bonus' : 'Hapus Spesifik Bonus'
export const LangPayrollBulkAction6 = isLangEN ? 'Delete All Bonus' : 'Hapus Semua Bonus'
export const LangPayrollBulkAction7 = isLangEN ? 'Pay Payroll' : 'Bayar Gaji'
export const LangPayrollBulkAction8 = isLangEN ? 'Work Note Files' : 'File Catatan Kerja'
export const LangPayrollBulkAction9 = isLangEN ? 'Align Base Salary' : 'Samakan Gaji Dasar'
export const LangPayrollSendEmail = isLangEN ? 'Send payslip email' : 'Kirim email slip gaji'
export const LangDeductionName = isLangEN ? 'Deduction Name' : 'Nama Potongan'
export const LangBonusName = isLangEN ? 'Bonus Name' : 'Nama Bonus'
export const LangNominal = isLangEN ? 'Nominal' : 'Nominal'
export const LangSeparatorSign = isLangEN ? 'Separate with | if more than one.' : 'Pisahkan dengan | jika lebih dari satu.'
export const LangAddBonus = isLangEN ? 'Add Bonus' : 'Tambah Bonus'
export const LangEditBonus = isLangEN ? 'Edit Bonus' : 'Ubah Bonus'
export const LangTotalOvertimeWages = isLangEN ? 'Total Overtime Wages' : 'Total Upah Lembur'
export const LangTotalBonus = isLangEN ? 'Total Bonus' : 'Total Bonus'
export const LangTotalBreak = isLangEN ? 'Total Break Time' : 'Total Jam Istirahat'

// Tour Feature Lang
export const LangFinishTheSteps = isLangEN ? 'Complete the initial steps to run the best features of the Kerjoo application!' : 'Selesaikan langkah-langkah awal untuk menjalankan fitur-fitur terbaik aplikasi Kerjoo!'
export const LangFinishTheSteps2 = isLangEN ? 'Let\'s follow the steps below to fill in the basic data in the Kerjoo application' : 'Mari ikuti langkah-langkah awal pengisian data dasar aplikasi Kerjoo'
export const LangFinishTheSteps3 = isLangEN ? 'Complete the steps below to fill in your basic company data:' : 'Selesaikan langkah-langah di bawah ini untuk pengisian data dasar perusahaan Anda:'
export const LangCongYouHave = isLangEN ? 'Congratulations you have' : 'Selamat Anda telah'
export const LangFinishAllTask = isLangEN ? 'complete all tasks!' : 'menyelesaikan semua tugas!'
export const LangWelcomeTo = isLangEN ? 'Welcome to' : 'Selamat Datang di '
export const Lang6Steps = isLangEN ? 'Complete the first 6 steps of application management' : 'Lengkapi 6 step awal pengelolaan aplikasi'
export const LangNext = isLangEN ? 'Next' : 'Berikutnya'
export const LangPrev = isLangEN ? 'Prev' : 'Sebelumnya'
export const LangRosaFromKerjoo = isLangEN ? 'Rosa from Kerjoo' : 'Rosa dari Kerjoo'
export const LangOf = isLangEN ? 'of' : 'dari'

export const LangTitleTour1 = isLangEN ? 'Add Division' : 'Tambah Divisi'
export const LangDescTour11 = isLangEN ? 'Click the Add Division button then fill in the name of the division or adjust it to the name in your company, then complete the division settings.' : 'Klik tombol Tambah Divisi kemudian isikan nama divisi atau menyesuaikan dengan nama di perusahaan Anda, selanjutnya lengkapi pengaturan divisi.'
export const LangDescTour12 = isLangEN ? 'You can delete or add divisions again.' : 'Anda bisa menghapus ataupun menambahkan kembali divisi.'

export const LangTitleTour2 = isLangEN ? 'Add Work Pattern' : 'Tambah Pola Kerja'
export const LangDescTour21 = isLangEN ? 'The Work Day work pattern is for employees who work on a regular schedule.' : 'Pola kerja Hari Kerja adalah untuk karyawan yang bekerja dengan jadwal yang teratur.'
export const LangDescTour22 = isLangEN ? 'Shift work patterns are for employees who work with an ever-changing schedule.' : 'Pola kerja Shift adalah untuk karyawan yang bekerja dengan jadwal selalu berubah.'

export const LangTitleTour3 = isLangEN ? 'Add Employees' : 'Tambah Karyawan'
export const LangDescTour31 = isLangEN ? 'Additions can be done via excel or manual import.' : 'Penambahan bisa dilakukan melalui impor excel maupun manual.'
export const LangDescTour32 = isLangEN ? 'Fill in all employee data with an email address that can be used.' : 'Isikan semua data karyawan dengan alamat email yang bisa digunakan.'
export const LangDescTour33 = isLangEN ? 'For ease of initial use, please enter a general password for all. employee. The password can be changed by employees through the Kerjoo mobile application.' : 'Untuk memudahkan penggunaan awal, silakan isikan kata sandi secara general untuk semua. karyawan. Kata sandi bisa diubah oleh karyawan melalui aplikasi mobile Kerjoo.'

export const LangTitleTour4 = isLangEN ? 'Schedule Work' : 'Atur Jadwal Kerja'
export const LangDescTour41 = isLangEN ? 'Select the name of the employee then set the work time according to the work pattern.' : 'Pilih nama karyawan kemudian atur jadwal kerja sesuai dengan pola kerja.'
export const LangDescTour42 = isLangEN ? 'You can schedule work in bulk.' : 'Anda bisa mengatur jadwal kerja secara masal.'

export const LangTitleTour5 = isLangEN ? 'Manage Attendance Locations' : 'Atur Lokasi Kehadiran'
export const LangDescTour51 = isLangEN ? 'Add your employee\'s work spot based on the selected folder.' : 'Tambahkan lokasi kerja karyawan Anda berdasarkan map yang dipilih.'
export const LangDescTour52 = isLangEN ? 'Attendance spot is used as employee presence point determined by the company.' : 'Lokasi kehadiran digunakan sebagai titik presensi karyawan yang ditentukan perusahaan.'

export const LangTitleTour6 = isLangEN ? 'Complete Company Data' : 'Lengkapi Data Perusahaan'
export const LangDescTour61 = isLangEN ? 'Please fill in the information on Business Field, Address, Province, Regency/City, District & Village.' : 'Silakan lengkapi informasi Bidang Usaha, Alamat, Provinsi, Kabupaten/Kota, Kecamatan & Kelurahan.'

// new tour guide
export const LangAlertTR = isLangEN ? 'Let\'s follow the initial steps for filling basic data on the Kerjoo application!' : 'Yuk ikuti langkah-langkah awal pengisian data dasar aplikasi Kerjoo!'
export const LangAlertTR2 = isLangEN ? 'Basic Data Completeness Status:' : 'Status Kelengkapan Data Dasar:'
export const LangViewStatus = isLangEN ? 'View Status' : 'Lihat Status'
export const LangFillIn = isLangEN ? 'Fill In' : 'Lengkapi'
export const LangTasklistTitleTR = isLangEN ? 'Basic Data Completeness' : 'Kelengkapan Data Dasar'
export const LangSteps11 = isLangEN ? 'Click on the manage employees menu' : 'Klik menu Kelola Karyawan'
export const LangSteps12 = isLangEN ? 'Click on the division menu' : 'Klik menu Divisi'
export const LangSteps13 = isLangEN ? 'Click the \'Add New Division\' button' : 'Klik tombol tambah divisi'
export const LangSteps21 = isLangEN ? 'Click the Work Patterns menu' : 'Klik menu Pola Kerja'
export const LangSteps22 = isLangEN ? 'Click the Work Day menu for Work Day patterns' : 'Klik menu Hari Kerja untuk pola hari kerja'
export const LangSteps23 = isLangEN ? 'Add Work Pattern' : 'Tambah Pola Kerja'
export const LangSteps24 = isLangEN ? 'Click the Shift menu for shift employee work patterns' : 'Klik menu Shift untuk pola kerja karyawan shift'
export const LangSteps25 = isLangEN ? 'Add Work Pattern' : 'Tambah Pola Kerja'
export const LangSteps31 = isLangEN ? 'Click on the manage employees menu' : 'Klik menu Kelola Karyawan'
export const LangSteps32 = isLangEN ? 'Click the Employee Data menu' : 'Klik menu Data Karyawan'
export const LangSteps33 = isLangEN ? 'Click the Add button and specify the employee division' : 'Klik tombol Tambah dan tentukan divisi karyawan'
export const LangSteps34 = isLangEN ? 'Import Excel for an alternative way to add employees' : 'Impor Excel untuk alternatif cara tambah karyawan'
export const LangSteps41 = isLangEN ? 'Click on the manage employees menu' : 'Klik menu Kelola Karyawan'
export const LangSteps42 = isLangEN ? 'Click the Work day Schedule menu for non-shift employee schedule settings' : 'Klik menu Jadwal Hari Kerja untuk pengaturan jadwal karyawan yang bukan shift'
export const LangSteps43 = isLangEN ? 'Click the Shift Schedule menu for shift employee schedule settings' : 'Klik menu Jadwal Shift untuk pengaturan jadwal karyawan shift'
export const LangSteps51 = isLangEN ? 'Click on the manage employees menu' : 'Klik menu Kelola Karyawan'
export const LangSteps52 = isLangEN ? 'Click the Attendance Location menu' : 'Klik menu Lokasi Kehadiran'
export const LangSteps53 = isLangEN ? 'Click Add Spot and specify the employees assigned after the spot is added' : 'Klik Tambah Lokasi & tentukan karyawan yang ditugaskan setelah lokasi ditambahkan'
export const LangSteps61 = isLangEN ? 'Click the Settings menu' : 'Klik menu Pengaturan'
export const LangSteps62 = isLangEN ? 'Click on the Company menu and complete the Business Field, Address, Province, Regency/City, District & Sub-District information' : 'Klik menu Perusahaan dan lengkapi  informasi Bidang Usaha, Alamat, Provinsi, Kabupaten/Kota, Kecamatan & Kelurahan'
export const LangBasicDataComplete = isLangEN ? 'Basic data is complete' : 'Data Dasar Sudah Lengkap'
export const LangEnjoyKerjoo = isLangEN ? 'Enjoy using Kerjoo!' : 'Selamat menggunakan Kerjoo!'
export const LangDefaultDataTrial1 = isLangEN ? "This is your company's admin panel page. During this trial period, the Kerjoo system automatically inserts sample data to help you learn about Kerjoo's cool features." : 'Ini adalah halaman admin panel perusahaan Anda. Pada masa percobaan ini, sistem Kerjoo secara otomatis memasukkan data-data contoh untuk mempermudah Anda dalam mempelajari fitur keren Kerjoo.'
export const LangDefaultDataTrial2 = isLangEN ? 'You can delete all sample data at any time when you are ready to start using Kerjoo!' : 'Anda dapat menghapus semua data-data contoh kapan saja saat Anda sudah siap memulai menggunakan Kerjoo!'
export const LangReset = isLangEN ? 'Reset' : 'Reset'
export const LangResetData = isLangEN ? 'Reset Data' : 'Kosongkan Data'
export const LangOkGotIt = isLangEN ? 'Ok, Got It' : 'Ok Mengerti'
export const LangOkReset = isLangEN ? 'Ok Reset' : 'Ok Kosongkan'
export const LangResetInfo1 = isLangEN ? 'Start using the Kerjoo attendance app fully with real data.' : 'Mulai menggunakan aplikasi absensi Kerjoo sepenuhnya dengan data sebenarnya.'
export const LangResetInfo2 = isLangEN ? 'Once you reset the data, you can start entering your company data.' : 'Setelah aksi kosongkan data selesai, Anda dapat memulai memasukkan data-data perusahaan Anda.'
export const LangResetInProgress = isLangEN ? 'Reset in progress...' : 'Mengosongkan data...'
export const LangInfoDefaultPassword = isLangEN ? <span>For this trial, the login password for all these employee are: <strong>Password123</strong></span> : <span>Password login semua karyawan trial adalah: <strong>Password123</strong></span>
export const LangResetDataFirst = isLangEN ? 'Perform a reset data first to do this action' : 'Kosongkan data terlebih dahulu untuk melakukan aksi ini'

// spot quota lang
export const LangOrderQuotaSpot = isLangEN ? 'Order Additional Spot Quota' : 'Pesan Kuota Lokasi Tambahan'

// premium features
export const LangAttendanceTimeLimiter = isLangEN ? 'Attendance Time Limiter' : 'Pembatas Waktu Presensi'
export const LangAutomaticPresenceExit = isLangEN ? 'Automatic Presence Exit' : 'Otomatis Presensi Keluar'
export const LangPresenceDeviceValidation = isLangEN ? 'Presence Device Validation' : 'Validasi Device Presensi'
export const LangMinQuota = isLangEN ? 'Minimum Employee Quota' : 'Minimal Kuota Karyawan'
export const LangMinPacket = isLangEN ? 'Minimum Package' : 'Minimal Paket'
export const LangLearnMore = isLangEN ? 'Learn More' : 'Pelajari Lebih Lanjut'

export const LangAttendanceTimeLimiterDesc = isLangEN ? 'This feature could improve employee discipline and order and to reduce fraud in complying with the working hours that have been determined by the company.' : 'Fitur ini bisa meningkatkan disiplin dan keteraturan karyawan serta untuk mengurangi kecurangan dalam mematuhi jam kerja yang sudah ditentukan perusahaan.'
export const LangCalculateSalaryDesc = isLangEN ? 'Make your employee salary calculation even better with this feature. Comes with a payslip that can be downloaded.' : 'Jadikan penghitungan gaji karyawan Anda menjadi lebih baik dengan adanya fitur ini. Dilengkapi dengan slip gaji yang bisa didownload.'
export const LangAutomaticPresenceExitDesc = isLangEN ? 'The habit of employees forgetting to leave attendance will be overcome with this feature. Immediately activate it in your admin panel.' : 'Kebiasaan karyawan lupa melakukan presensi keluar bakal teratasi dengan fitur ini. Segera aktifkan di admin panel Anda.'
export const LangPresenceDeviceValidationDesc = isLangEN ? 'This feature is useful in checking employee devices as well as to avoid fraud committed by employees in making attendance.' : 'Fitur ini bermanfaat dalam pengecekan perangkat karyawan sekaligus untuk menghindari kecurangan yang dilakukan oleh karyawan dalam melakukan presensi.'
export const LangClientVisitDesc = isLangEN ? 'Improve the performance of the sales team by monitoring through activity reports during visits to clients\' premises.' : 'Tingkatkan performa tim sales dengan memantau melalui laporan aktivitas saat melakukan kunjungan ke tempat klien.'
export const LangEmployeeDocumentsDesc = isLangEN ? 'Upload work contract document files or other documents related to employees and set when HR will start being reminded.' : 'Unggah file dokumen kontrak kerja atau dokumen lain yang terkait dengan karyawan dan atur kapan HR mulai diingatkan.'
export const LangPayPayrollDesc = isLangEN ? 'HRD is currently using the payroll feature for an easier and more transparent employee payroll process.' : 'Saatnya HRD menggunakan fitur bayar gaji untuk proses penggajian karyawan yang lebih mudah dan transparan.'
export const LangNoteFilesDesc = isLangEN ? 'Employees can be more complete when reporting their daily work note by adding document files or photos of proof of work.' : 'Karyawan bisa lebih lengkap saat melaporkan catatan kerja hariannya dengan menambahkan file dokumen maupun foto bukti kerja.'

export const LangDailyWorkSchedule = isLangEN ? 'Daily Work Schedule' : 'Jadwal Kerja Harian'
export const LangDailyWorkScheduleHelp = isLangEN ? 'The employee\'s daily work schedule according to the menu settings for Workday Schedule or Shift Schedule' : 'Jadwal kerja harian karyawan sesuai pengaturan menu Jadwal Hari Kerja atau Jadwal Shift'

// pintu kerjoo
export const LangPintuKerjoo = isLangEN ? 'Pintu Kerjoo' : 'Pintu Kerjoo'
export const LangAccPintuKerjoo = isLangEN ? 'Pintu Kerjoo Account' : 'Akun Pintu Kerjoo'
export const LangSetAccPintuKerjoo = isLangEN ? 'Set Pintu Kerjoo Account' : 'Atur Akun Pintu Kerjoo'
export const LangView = isLangEN ? 'View' : 'Lihat'
export const LangLanguage = isLangEN ? 'Language' : 'Bahasa'
export const LangChooseLanguage = isLangEN ? 'Choose language' : 'Pilih bahasa'
export const LangChangePass = isLangEN ? 'Change Password' : 'Ubah Password'
export const LangChangeSyncPass = isLangEN ? 'Change Sync Password' : 'Ubah Sync Password'
export const LangNewPass = isLangEN ? 'New Password' : 'Password Baru'
export const LangConfirmPass = isLangEN ? 'Confirm Password' : 'Konfirmasi Password'
export const LangConfirmSyncPass = isLangEN ? 'Confirm Sync Password' : 'Konfirmasi Sync Password'
export const LangSyncPass = isLangEN ? 'Sync Password' : 'Sync Password'
export const LangSetAccount = isLangEN ? 'set account' : 'atur akun'
export const LangHelpSupport = isLangEN ? 'Help Support' : 'Bantuan Dukungan'
export const LangTelephone = isLangEN ? 'Telephone' : 'Telepon'
export const LangPresenceUsingPintuKerjoo = isLangEN ? 'Presence using Pintu Kerjoo' : 'Presensi menggunakan Pintu Kerjoo'

// overtime calculation
export const LangOvertimeCalculation = isLangEN ? 'Overtime Wage Rules' : 'Aturan Upah Lembur'
export const LangOvertimeHours = isLangEN ? 'Overtime Hours' : 'Jam Lembur'
export const LangH5WorkingDays = isLangEN ? '(holidays) 5 Working Days' : '(hari libur) 5 Hari Kerja'
export const LangH6WorkingDays = isLangEN ? '(holidays) 6 Working Days' : '(hari libur) 6 Hari Kerja'
export const LangNumberShiftWorkingDays = isLangEN ? 'Number of Shift Working Days' : 'Jumlah Hari Kerja Shift'
export const LangEnableOvertimeCalculation = isLangEN ? 'Activate this Overtime Wage Rule' : 'Aktifkan Aturan Upah Lembur Ini'
export const LangWageRates = isLangEN ? 'Wage Rates' : 'Tarif Upah'

// usage guide
export const LangUsageGuide = isLangEN ? 'Usage Guide' : 'Panduan Penggunaan'

// reimbursement
export const LangClaim = isLangEN ? 'Claim' : 'Klaim'
export const LangClaimDesc = isLangEN ? 'To manage reimbursements such as health claims, transportation, etc. The Claims feature can be accessed on the website (by admin) and also on the mobile application (by employees).' : 'Untuk mengelola penggantian dana seperti klaim kesehatan, transportasi, dll. Fitur Klaim dapat diakses di website (oleh admin) dan juga di aplikasi mobile (oleh karyawan).'
export const LandAddClaim = isLangEN ? 'Add Claim Type' : 'Tambah Jenis Klaim'
export const LangEffectiveDate = isLangEN ? 'Effective Date' : 'Tgl. Mulai Berlaku'
export const LangExpiredIn = isLangEN ? 'Expired In' : 'Kadaluwarsa'
export const LangAssignToNewEmployee = isLangEN ? 'Assign to New Employee' : 'Terapkan ke Karyawan Baru'
export const LangMinReqAmount = isLangEN ? 'Min. Request Amount' : 'Min. Jumlah Pengajuan'
export const LangMaxReqAmount = isLangEN ? 'Max. Request Amount' : 'Max. Jumlah Pengajuan'
export const LangMinNextClaim = isLangEN ? 'Min. Next Claim' : 'Min. Klaim Selanjutnya'
export const LangAssignEmployee = isLangEN ? 'Assign Employee' : 'Tetapkan Karyawan'
export const LangClaimHistories = isLangEN ? 'Claim Histories' : 'Riwayat Klaim'
export const LangRequestDate = isLangEN ? 'Request Date' : 'Tgl. Pengajuan'
export const LangReqAmount = isLangEN ? 'Request Amount' : 'Jumlah Pengajuan'
export const LangApproveAmount = isLangEN ? 'Approve Amount' : 'Jumlah Disetujui'
export const LangApprovalAmount = isLangEN ? 'Approval Amount' : 'Jumlah Disetujui'
export const LangExpenses = isLangEN ? 'Expenses' : 'Pengeluaran'
export const LangFiles = isLangEN ? 'Files' : 'File'
export const LangApprovedAt = isLangEN ? 'Approved at' : 'Tgl. Disetujui'
export const LangRejectedAt = isLangEN ? 'Rejected at' : 'Tgl. Ditolak'
export const LangImportExcelClaimEmployee = isLangEN ? 'Import Excel Claim Employee Format' : 'Impor Excel Format Karyawan Klaim'
export const LangImportClaimEmployeeHelp3 = isLangEN ? 'To import claim employee, make sure the uploaded file matches the specified format' : 'Untuk melakukan impor karyawan klaim, pastikan file yang diupload sudah sesuai dengan format yang ditentukan'
export const LangSureToApproveClaim = isLangEN ? 'Are you sure want to approve this claim request?' : 'Anda yakin akan menerima pengajuan klaim ini?'
export const LangSureToRejectClaim = isLangEN ? 'Are you sure want to reject this claim request?' : 'Anda yakin akan menolak pengajuan klaim ini?'
export const LangClaimRequest = isLangEN ? 'Claim Request' : 'Pengajuan Klaim'
export const LangCancel = isLangEN ? 'Cancel' : 'Batalkan'
export const LangCancelApproval = isLangEN ? 'Cancel Approval' : 'Batalkan Persetujuan'
export const LangCancelReason = isLangEN ? 'Cancel Reason' : 'Alasan Pembatalan'
export const LangApproveRequest = isLangEN ? 'Approve Request' : 'Terima Pengajuan'
export const LangReqAmountHelp = isLangEN ? 'The input amount cannot be more than the initial amount.' : 'Jumlah yang di input tidak boleh lebih dari jumlah awal.'
export const LangClaimType = isLangEN ? 'Claim Type' : 'Jenis Klaim'
export const LangShowQuota = isLangEN ? 'Show Quota to Employee' : 'Tampilkan Kuota pada Karyawan'
export const LangExpensesDetail = isLangEN ? 'Expenses Detail' : 'Detail Pengeluaran'
export const LangDownloadFile = isLangEN ? 'Download File' : 'Unduh File'
export const LangAddToPersonnelPayroll = isLangEN ? 'Add to Employee Payroll' : 'Tambahkan ke Gaji Karyawan'
export const LangAddedToPersonnelPayroll = isLangEN ? 'Added to Employee Payroll' : 'Ditambahkan ke Gaji Karyawan'
export const LangEmployeesApplied = isLangEN ? 'Employees Applied' : 'Karyawan Diterapkan'
export const LangHideClaim = isLangEN ? 'Hide Claim Menu' : 'Sembunyikan Menu Klaim'
export const LangPersonnelClaim = isLangEN ? 'Employee Claim' : 'Klaim Karyawan'
export const LangViewFile = isLangEN ? 'View file' : 'Lihat file'
export const LangAddToPayrollInfo = isLangEN ? 'Will be included in the pay period according to the approved date' : 'Akan dimasukkan ke periode gaji sesuai tanggal disetujui'
export const LangTotalClaim = isLangEN ? 'Total Claim' : 'Total Klaim'

// webhook
export const LangSendWebhookTest = isLangEN ? 'Send Webhook Test' : 'Tes Kirim Webhook'
export const LangSentDate = isLangEN ? 'Sent Date' : 'Tanggal Kirim'
export const LangWebhookLog = isLangEN ? 'Webhook Delivery Log' : 'Riwayat Pengiriman Webhook'
export const LangLoading = isLangEN ? 'Loading' : 'Memuat'
export const LangNoWebhookLog = isLangEN ? 'There is no history of sending webhooks yet' : 'Belum ada riwayat pengiriman webhook'

// special leave
export const LangLeaveType = isLangEN ? 'Leave Type' : 'Jenis Cuti'
export const LangLeaveTypeAnnualLeave = isLangEN ? 'Annual Leave' : 'Tahunan'
export const LangSpecialLeave = isLangEN ? 'Special Leave' : 'Cuti Khusus'
export const LangSpecialLeaveDesc = isLangEN ? 'Special leave is an upgraded version of annual leave that does not reduce salary. This is a solution for employees who have urgent needs or need longer leave.' : 'Cuti khusus adalah versi upgrade dari cuti tahunan yang tidak memotong gaji. Ini adalah solusi bagi karyawan yang memiliki kepentingan mendesak atau butuh cuti lebih lama.'
export const LangSpecial = isLangEN ? 'Special Leave' : 'Khusus'
export const LangAddSpecialLeave = isLangEN ? 'Add Special Leave' : 'Tambah Cuti Khusus'
export const LangEditSpecialLeave = isLangEN ? 'Edit Special Leave' : 'Ubah Cuti Khusus'
export const LangType = isLangEN ? 'Type' : 'Jenis'
export const LangSpecialLeaveType = isLangEN ? 'Special Leave Type' : 'Jenis Cuti Khusus'
export const LangMaxDays = isLangEN ? 'Max Days' : 'Maks. Hari'
export const LangStatus = isLangEN ? 'Status' : 'Status'
export const LangNotActive = isLangEN ? 'Inactive' : 'Tidak Aktif'
export const LangHideSpecialLeaveButton = isLangEN ? 'Hide Special Leave Request Button' : 'Sembunyikan Tombol Cuti Khusus'
export const LangEmployeesCanRequest = isLangEN ? 'Employees can request special leave' : 'Karyawan dapat mengajukan cuti khusus'

// manage position
export const LangManagePosition = isLangEN ? 'Manage Position' : 'Jabatan'
export const LangAddPosition = isLangEN ? 'Add Position' : 'Tambah Jabatan'
export const LangEditPosition = isLangEN ? 'Edit Position' : 'Ubah Jabatan'
export const LangChoosePosition = isLangEN ? 'Select Position' : 'Pilih Jabatan'
export const LangViewVisualization = isLangEN ? 'View Visualization' : 'Lihat Visualisasi'
export const LangVisualization = isLangEN ? 'Visualization' : 'Visualisasi'
export const LangPositionHierarchy = isLangEN ? 'Position Hierarchy ' : 'Hirarki Jabatan'
export const LangParent = isLangEN ? 'Parent' : 'Induk'
export const LangNoParent = isLangEN ? 'No Parent' : 'Tanpa Induk'
export const LangAddDefaultPosition = isLangEN ? 'Add Default Position' : 'Tambahkan Jabatan Default'
export const LangAddDefaultPositionInfo = isLangEN ? 'The positions that will be added have a hierarchical structure like the following' : 'Jabatan yang akan ditambahkan memiliki stuktur hirarki seperti berikut'
export const LangConfirmAdd = isLangEN ? 'Confirm' : 'Ok Tambahkan'
export const LangAddDefaultPositionCancel = isLangEN ? 'Cancel' : 'Batal'
export const LangPositionId = isLangEN ? 'Position ID' : 'ID Jabatan'
export const LangPositionAttendancePercentage = isLangEN ? 'Position Attendance Percentage' : 'Persentase Kehadiran Jabatan'
export const LangPositionAttendanceTargets = isLangEN ? 'Attendance Targets' : 'Target Kehadiran'
export const LangPositionAttendanceTargets2 = isLangEN ? 'Position Attendance Targets' : 'Target Kehadiran Jabatan'
export const LangPositionAttendanceTargetsErr = isLangEN ? 'The total attendance target must not exceed 100.' : 'Total target kehadiran tidak boleh lebih dari 100.'
export const LangLocation = isLangEN ? 'Location' : 'Lokasi'
export const LangTarget = isLangEN ? 'Target' : 'Target'

// admin notification
export const LangNotification = isLangEN ? 'Notifications' : 'Notifikasi'
export const LangNoNotification = isLangEN ? 'No New Notifications' : 'Tidak Ada Notifikasi Baru'
export const LangPaidLeaveRequest = isLangEN ? 'Paid Leave Request' : 'Permohonan Cuti'
export const LangUnpaidLeaveRequest = isLangEN ? 'Unpaid Leave Request' : 'Permohonan Izin'
export const LangEmployeeDocsExpired = isLangEN ? 'Employee Docs Expired' : 'Dokumen Karyawan Berakhir'
export const LangEmployeeDocsInReminder = isLangEN ? 'Employee Docs In Reminder' : 'Dokumen Karyawan Masa Ingatkan'
export const LangPintuKerjooAttendanceApproval = isLangEN ? '[Pintu Kerjoo] Attendance Approval' : '[Pintu Kerjoo] Persetujuan Presensi'
export const LangPintuKerjooAttendanceApproval2 = isLangEN ? 'Pintu Kerjoo Attendance Approval' : 'Persetujuan Presensi Pintu Kerjoo'

export const LangReview = isLangEN ? 'Review' : 'Review'
export const LangSubscription = isLangEN ? 'Subscription' : 'Langganan'
export const LangAnalytics = isLangEN ? 'Analytics' : 'Analisa'
export const LangAdjustment = isLangEN ? 'Adjustment' : 'Penyesuaian'
export const LangApproval = isLangEN ? 'Approval' : 'Persetujuan'
export const LangUserAdmin = isLangEN ? 'User Admin' : 'User Admin'
export const LangPintuKerjooAttendance = isLangEN ? 'Pintu Kerjoo Attendance' : 'Presensi Pintu Kerjoo'
export const LangSwitchToNewVersion = isLangEN ? 'Switch to new version' : 'Coba menu versi baru'
export const LangSwitchToOldVersion = isLangEN ? 'Back to old version' : 'Kembali ke versi lama'

// Alur Persetujuan
export const LangMultilevelApproval = isLangEN ? 'Multilevel Approval' : 'Persetujuan Bertingkat'
export const LangMultilevelApprovalDesc = isLangEN ? 'Granting authority to employees with certain positions to check and approve certain applications made by employees. With Multilevel Approval Settings, the flow of leave and permit approvals becomes more structured. This feature applies to annual leave, special leave and permit features.' : 'Memberikan wewenang kepada karyawan dengan jabatan tertentu untuk melakukan pengecekan dan menyetujui pengajuan tertentu yang dilakukan oleh karyawan. Dengan Pengaturan Persetujuan Bertingkat, alur persetujuan cuti dan izin jadi lebih terstruktur. Fitur ini berlaku pada fitur cuti tahunan, cuti khusus dan izin hari.'
export const LangApprovalRules = isLangEN ? 'Approval Rules' : 'Alur Persetujuan'
export const LangRuleName = isLangEN ? 'Rule Name' : 'Nama Aturan'
export const LangApplicableOn = isLangEN ? 'Applicable On' : 'Berlaku Pada'
export const LangApplicableTo = isLangEN ? 'Applicable To' : 'Berlaku Untuk'
export const LangMaxApproval = isLangEN ? 'Max Approval Due Date' : 'Maks. Hari Persetujuan'
export const LangMaxApprovalBefore = isLangEN ? 'On or Before the Unpaid/Paid Leave Date' : 'Sebelum atau Sama Dengan Tanggal Cuti/Izin'
export const LangMaxApprovalH = isLangEN ? 'Maximum Days After Unpaid/Paid Leave Date' : 'Maksimal Hari Setelah Tanggal Cuti/Izin'
export const LangAddApprovalRules = isLangEN ? 'Add Approval Rules' : 'Tambah Aturan Persetujuan'
export const LangEditApprovalRules = isLangEN ? 'Edit Approval Rules' : 'Ubah Aturan Persetujuan'
export const LangCutiTahunan = isLangEN ? 'Annual Leave' : 'Cuti Tahunan'
export const LangTargetType = isLangEN ? 'Type' : 'Tipe'
export const LangSpesificPosition = isLangEN ? 'Spesific Position' : 'Jabatan Tertentu'
export const LangSpesificDivision = isLangEN ? 'Spesific Division' : 'Divisi Tertentu'
export const LangSpesificEmployee2 = isLangEN ? 'Spesific Employee' : 'Karyawan Tertentu'
export const LangEmployeeDivisionSpv = isLangEN ? 'Employee Division Spv' : 'Spv Divisi Karyawan'
export const LangApprovalStatus = isLangEN ? 'Approval Status' : 'Status Persetujuan'
export const LangWaitingApproval = isLangEN ? 'Waiting Approval' : 'Menunggu Persetujuan'
export const LangApproved = isLangEN ? 'Approved' : 'Disetujui'
export const LangApprovedAt2 = isLangEN ? 'Approved at' : 'Disetujui pada'
export const LangRejectedAt2 = isLangEN ? 'Rejected at' : 'Ditolak pada'
export const LangApprovalLevel = isLangEN ? 'Approval Level' : 'Persetujuan Tingkat'
export const LangReviewer = isLangEN ? 'Reviewer' : 'Peninjau'
export const LangApprovalRulesFormInfo = isLangEN ? 'This multilevel approval can only be approved via the Kerjoo mobile application' : 'Persetujuan bertingkat ini hanya bisa disetujui melalui aplikasi mobile Kerjoo'
export const LangApprovalOnMobileAppInfo = isLangEN ? 'You can approve / reject this request on Kerjoo mobile application because the approval rules are implemented' : 'Anda dapat melakukan aksi terima / tolak pengajuan ini pada aplikasi mobile Kerjoo karena terdapat alur persetujuan yang diterapkan'

// Timesheet
export const LangTimesheet = isLangEN ? 'Timesheet' : 'Timesheet'
export const LangTimesheetDesc = isLangEN ? 'It is used to determine how long it takes for an employee to complete a task. Tasks within the timesheet can be added by the admin/Supervisor of the Division or by the employees themselves. The Timesheet feature will complement the "Notes" menu on the Kerjoo mobile application.' : 'Digunakan untuk mengetahui berapa lama waktu yang diperlukan untuk menyelesaikan suatu pekerjaan oleh karyawan. Pekerjaan di dalam timesheet bisa ditambahkan oleh admin/SPV Divisi atau oleh karyawan sendiri. Fitur Timesheet akan melengkapi menu “Catatan” pada aplikasi mobile Kerjoo.'
export const LangProject = isLangEN ? 'Project' : 'Proyek'
export const LangProjectName = isLangEN ? 'Project Name' : 'Nama Proyek'
export const LangAddProject = isLangEN ? 'Add Project' : 'Tambah Proyek'
export const LangEditProject = isLangEN ? 'Edit Project' : 'Ubah Proyek'
export const LangMember = isLangEN ? 'Member' : 'Anggota'
export const LangAddJob = isLangEN ? 'Add To Do' : 'Tambah Pekerjaan'
export const LangEditJob = isLangEN ? 'Edit To Do' : 'Ubah Pekerjaan'
export const LangCreatedBy2 = isLangEN ? 'Created By' : 'Pembuat'
export const LangJob = isLangEN ? 'To Do' : 'Pekerjaan'
export const LangJobDescription = isLangEN ? 'Description' : 'Deskripsi Pekerjaan'
export const LangTime = isLangEN ? 'Time' : 'Waktu'
export const LangNotStarted = isLangEN ? 'Not Started' : 'Belum Dimulai'
export const LangOnProcess = isLangEN ? 'On Process' : 'Diproses'
export const LangPending2 = isLangEN ? 'Pending' : 'Ditunda'
export const LangContinuedOtherDay = isLangEN ? '	Continued Another Day' : 'Dilanjutkan Hari Lain'
export const LangStatusFilter = isLangEN ? 'Status Filter' : 'Filter Status'
export const LangProjectFilter = isLangEN ? 'Project Filter' : 'Filter Proyek'
export const LangImportTimesheetHelp1 = isLangEN ? 'To import a timesheet data, make sure the uploaded file matches the specified format' : 'Untuk melakukan impor data timesheet, pastikan file yang diupload sudah sesuai dengan format yang ditentukan'
export const LangImportTimesheetHelp2 = isLangEN ? 'The import action will only be executed if all the data is correct' : 'Aksi impor hanya akan dijalankan jika semua data sudah benar'
export const LangImportTimesheetHelp3 = isLangEN ? 'If there is data that needs to be corrected, the system will immediately display the information after clicking the process button' : 'Jika ada data yang perlu diperbaiki, sistem akan segera menampilkan informasinya setelah klik tombol proses'
export const LangImportTimesheetHelp4 = isLangEN ? 'Download the excel import format here' : 'Download format impor excel di sini'
export const LangJobCreatedBy = (name) => isLangEN ? <>This To Do was created by {name ?? 'employee'} and can only be changed/deleted by them</> : <>Pekerjaan ini dibuat oleh {name ?? 'karyawan'} dan hanya bisa diubah / dihapus oleh karyawan tersebut</>
export const LangProcessBy = isLangEN ? 'Processed By' : 'Diproses Oleh'
export const LangToDoCount = isLangEN ? 'Amount of Work' : 'Jml. Pekerjaan'
export const LangProjectWorkList = isLangEN ? 'Project Work List' : 'Daftar Pekerjaan Proyek'
export const LangProcessedOn = isLangEN ? 'Processed On' : 'Diproses Pada'

// expires info
export const LangExpiresAlert = (days) => isLangEN ? <>Your Kerjoo app subscription has only <span className='text-danger font-weight-bold'>{days}</span> left, renew now while there's still time!</> : <>Langganan aplikasi Kerjoo tersisa <span className='text-danger font-weight-bold'>{days}</span> lagi, segera perpanjang mumpung masih ada waktu!</>
export const LangExpiresModal = (days, date) => isLangEN
  ? <>The subscription for your company's Kerjoo app will expire in <span className='text-danger font-weight-bold'>{days}</span>. Make sure to renew before <span className='font-weight-bold'>{date}</span> to continue using the Kerjoo app.</>
  : <>Masa aktif langganan aplikasi Kerjoo perusahaan Anda <span className='text-danger font-weight-bold'>{days}</span> lagi akan habis, pastikan Anda melakukan perpanjangan sebelum <span className='font-weight-bold'>{date}</span> agar karyawan dapat terus menggunakan aplikasi Kerjoo.</>
export const LangRenewNow = isLangEN ? 'Renew Now' : 'Perpanjang Sekarang'
export const LangOkLater = isLangEN ? 'Ok, remind me later' : 'Ok, ingatkan nanti'

// Calendar Dashboard
export const LangEventEmployeeBirthday = (isPlural) => isLangEN ? `${isPlural ? 'Employees' : 'Employee'} Birthday` : 'Karyawan Ulang Tahun'
export const LangEventUnpaidLeave = (isPlural) => isLangEN ? `${isPlural ? 'Employees' : 'Employee'} on Unpaid Leave` : 'Karyawan Izin'
export const LangEventPaidLeave = (isPlural) => isLangEN ? `${isPlural ? 'Employees' : 'Employee'} on Paid Leave` : 'Karyawan Cuti'
export const LangEventSpecialLeave = (isPlural) => isLangEN ? `${isPlural ? 'Employees' : 'Employee'} on Special Leave` : 'Karyawan Cuti Khusus'
export const LangEventHoliday = isLangEN ? 'Holiday' : 'Libur'
export const LangToday = isLangEN ? 'Today' : 'Hari ini'
export const LangTodaysAgenda = isLangEN ? 'Today\'s Agenda' : 'Agenda Hari Ini'
export const LangAgenda = isLangEN ? 'Agenda' : 'Agenda'
export const LangNoAgenda = isLangEN ? 'No agenda today.' : 'Tidak ada agenda hari ini.'
export const LangShowCalendar = isLangEN ? 'Show Calendar' : 'Lihat Calendar'

// Dark Mode
export const LangDarkMode = isLangEN ? 'Dark' : 'Gelap'
export const LangLightMode = isLangEN ? 'Light' : 'Terang'
export const LangAutoMode = isLangEN ? 'Auto' : 'Otomatis'

// Overtime Schedule
export const LangOvertimeSchedule = isLangEN ? 'Overtime Schedule' : 'Jadwal Lembur'
export const LangOvertimeRestriction = isLangEN ? 'Overtime Restriction' : 'Pembatasan Mulai Lembur'
export const LangAddOvertimeSchedule = isLangEN ? 'Add Overtime Schedule' : 'Tambah Jadwal Lembur'
export const LangEditOvertimeSchedule = isLangEN ? 'Edit Overtime Schedule' : 'Ubah Jadwal Lembur'
export const LangEnableOvertimeSchedule = isLangEN ? 'Employees with overtime schedules can only attend overtime according to the schedule' : 'Karyawan yang ada jadwal lembur hanya bisa presensi lembur sesuai jadwal'
export const LangBeforeSchedule = isLangEN ? 'Before Schedule' : 'Sebelum Jadwal'
export const LangTimeStart2 = isLangEN ? 'Start Time' : 'Waktu Mulai'
export const LangTimeEnd2 = isLangEN ? 'End Time' : 'Waktu Selesai'
export const LangImportOvertimeScheduleHelp1 = isLangEN ? 'To import an overtime schedule data, make sure the uploaded file matches the specified format' : 'Untuk melakukan impor data jadwal lembur, pastikan file yang diupload sudah sesuai dengan format yang ditentukan'
export const LangImportOvertimeScheduleHelp2 = isLangEN ? 'The import action will only be executed if all the data is correct' : 'Aksi impor hanya akan dijalankan jika semua data sudah benar'
export const LangImportOvertimeScheduleHelp3 = isLangEN ? 'If there is data that needs to be corrected, the system will immediately display the information after clicking the process button' : 'Jika ada data yang perlu diperbaiki, sistem akan segera menampilkan informasinya setelah klik tombol proses'
export const LangImportOvertimeScheduleHelp4 = isLangEN ? 'Download the excel import format here' : 'Download format impor excel di sini'
export const LangOnSchedule = isLangEN ? 'Using Overtime Schedule' : 'Menggunakan Jadwal Lembur' 
export const LangDownloadImportFormatOvertimeSchedule = isLangEN ? 'Download Overtime Schedule Import Format' : 'Download Format Import Jadwal Lembur'
export const LangOvertimeScheduleSetting = isLangEN ? 'Overtime Schedule Setting' : 'Pengaturan Jadwal Lembur'
export const LangOvertimeScheduleNotification = isLangEN ? 'Send overtime schedule notifications to all employees who have a schedule' : 'Pengiriman notifikasi jadwal lembur ke semua karyawan yang mempunyai jadwal'
export const LangMinuteBeforeSchedule = isLangEN ? 'Minute Before Schedule' : 'Menit Sebelum Jadwal'

// Order Histrory
export const LangOrderHistory = isLangEN ? 'Order History' : 'Riwayat Transaksi'
export const LangOrderItem = isLangEN ? 'Order Item' : 'Pesanan'
export const LangOrderName = isLangEN ? 'Order Name' : 'Nama Pemesan'
export const LangOrderTypeFilter = isLangEN ? 'Type Filter' : 'Filter Tipe'
export const LangOrderStatusSuccess = isLangEN ? 'Success' : 'Berhasil'
export const LangOrderStatusPending = isLangEN ? 'Pending' : 'Sedang Diproses'
export const LangOrderStatusCancel= isLangEN ? 'Cancel' : 'Dibatalkan'
export const LangOrderTypeSubscribe = isLangEN ? 'Subscribe' : 'Berlangganan'
export const LangOrderTypeRenew = isLangEN ? 'Renew' : 'Perpanjangan'
export const LangOrderTypeAddPersonnel = isLangEN ? 'Add Employee' : 'Tambah Karyawan'
export const LangOrderTypeAddQuotaSpot = isLangEN ? 'Add Attendance Spot Quota' : 'Tambah Kuota Lokasi Kehadiran'
export const LangOrderTypeAddQuotaBroadcast = isLangEN ? 'Add Broadcast Quota' : 'Tambah Kuota Broadcast'
export const LangOrderTypeAddQuotaDistance = isLangEN ? 'Add Calculate Client Visit Distance Quota' : 'Tambah Kuota Hitung Jarak Kunjungan Klien'
export const LangOrderTypeUpgradePackage = isLangEN ? 'Upgrade Package' : 'Upgrade Paket'
export const LangOrderHistoryPackageName = isLangEN ? 'Package Name' : 'Nama Paket'
export const LangOrderHistoryPackageNameBefore = isLangEN ? 'Package Name Before' : 'Nama Paket Sebelumnya'
export const LangOrderHistoryPersonnelQuota = isLangEN ? 'Employee Quota' : 'Kuota Karyawan'
export const LangOrderHistoryUpgradeToPackage = isLangEN ? 'Upgrade to package' : 'Upgrade ke paket'
export const LangInvoiceCompanyInfo = isLangEN ? 'Company Information' : 'Informasi Perusahaan'
export const LangInvoiceBilledTo = isLangEN ? 'Billed To' : 'Tagihan Kepada'
export const LangInvoicePhone = isLangEN ? 'Phone' : 'Telp'
export const LangInvoiceProduct = isLangEN ? 'Product' : 'Produk'
export const LangInvoicePaymentReceived = isLangEN ? 'Payment Received' : 'Pembayaran Diterima'
export const LangInvoiceRemainingBill = isLangEN ? 'Remaining Bill' : 'Sisa Tagihan'
export const LangInvoiceSincerely = isLangEN ? 'Sincerely' : 'Dengan Hormat'
export const LangInvoiceNotes = isLangEN ? 'Notes' : 'Pesan'
export const LangInvoicePaymentInfo = isLangEN ? 'Transfer Payment Via Account Number' : 'Pembayaran Transfer Melalui Nomor Rekening'
export const LaingInvoicePaid = isLangEN ? 'PAID' : 'LUNAS'

// Preview Mobile Setting
export const LangMobileAppSetting = isLangEN ? 'Mobile Application Setting' : 'Pengaturan Aplikasi Mobile'
export const LangQuoteDefault = isLangEN ? 'Set the quote you want to display' : 'Atur kutipan yang ingin Anda tampilkan'
export const LangHome= isLangEN ? 'Home' : 'Beranda'
export const LangCalendar = isLangEN ? 'Calendar' : 'Kalender'
export const LangShowAll = isLangEN ? 'Show All' : 'Lihat Semua'
export const LangShowPreview = isLangEN ? 'Show Preview' : 'Lihat Preview'
export const LangYourScheduleToday = isLangEN ? 'Your Schedule Today' : 'Jadwal Anda Hari Ini'
export const LangUnpaidLeaveHour = isLangEN ? 'Unpaid Leave Hour' : 'Izin Jam'
export const LangUnpaidLeaveDay = isLangEN ? 'Unpaid Leave Day' : 'Izin Hari'
export const LangAvailableQuota = isLangEN ? 'Available Quota' : 'Kuota Tersedia'
export const LangExpiredDates = isLangEN ? 'Expired Dates' : 'Tanggal Kadaluarsa'
export const LangActivityDetails = isLangEN ? 'Activity Details' : 'Detail Aktivitas'
export const LangClockIn = isLangEN ? 'Clock In' : 'Masuk'
export const LangAttendanceSpotMatch = isLangEN ? 'Match With Attendance Spot' : 'Sesuai Spot Presensi'
export const LangBirthday = isLangEN ? 'Birthday' : 'Ulang Tahun'
export const LangAnnual = isLangEN ? 'Annual' : 'Tahunan'
export const LangLeaveEmpty = isLangEN ? 'Paid Leave Empty' : 'Cuti Kosong'
export const LangLeaveEmptyInfo = isLangEN ? 'Currently, the paid leave is empty. You will see a list of paid leave here once they have been created.' : 'Saat ini cuti kosong. Anda akan melihat daftar cuti yang telah dibuat pada bagian ini.'
export const LangUnpaidLeaveEmptyHour = isLangEN ? 'Unpaid Leave Hour Empty' : 'Izin Jam Kosong'
export const LangUnpaidLeaveEmptyHourInfo = isLangEN ? 'Currently, the unpaid leave hour is empty. You will see a list of unpaid leave hour here once they have been created.' : 'Saat ini izin jam kosong. Anda akan melihat daftar izin jam yang telah dibuat pada bagian ini.'
export const LangUnpaidLeaveEmptyDay = isLangEN ? 'Unpaid Leave Empty' : 'Izin Kosong'
export const LangUnpaidLeaveEmptyDayInfo = isLangEN ? 'Currently, the unpaid leave is empty. You will see a list of unpaid leave here once they have been created.' : 'Saat ini izin kosong. Anda akan melihat daftar izin yang telah dibuat pada bagian ini.'
export const LangCheckYourRecap = isLangEN ? 'Check your recap performance this month' : 'Periksa kinerja rekap Anda bulan ini'
export const LangAppPreviews = isLangEN ? 'App Previews' : 'Preview Aplikasi'
export const LangClientVisitList = isLangEN ? 'Visit List' : 'Daftar Kunjungan'
export const LangClientVisitEmpty = isLangEN ? 'Client Visit Empty' : 'Kunjungan Klien Kosong'
export const LangClientVisitEmptyInfo = isLangEN ? 'Currently, the client visit is empty. You will see a list of client visit here once they have been created.' : 'Saat ini kunjungan klien kosong. Anda akan melihat daftar kunjungan klien yang telah dibuat pada bagian ini.'
export const LangCanAddNewClient = isLangEN ? 'Employees Can Add New Clients' : 'Karyawan Bisa Menambah Klien Baru'
export const LangClientName = isLangEN ? 'Client Name' : 'Nama Klien'
export const LangAttachment = isLangEN ? 'Attachments' : 'Lampiran'
export const LangClientVisitDescription = isLangEN ? 'Description' : 'Keterangan'

// Import Export Info
export const LangImportHelp1 = (feature) => (isLangEN ? `To import a ${feature ? `${feature.toLowerCase()}` : ''} data, make sure the uploaded file matches the specified format` : `Untuk melakukan impor data${feature ? ` ${feature.toLowerCase()}` : ''}, pastikan file yang diupload sudah sesuai dengan format yang ditentukan`)
export const LangImportHelp2 = isLangEN ? 'The import action will only be executed if all the data is correct' : 'Aksi impor hanya akan dijalankan jika semua data sudah benar'
export const LangImportHelp3 = isLangEN ? 'If there is data that needs to be corrected, the system will immediately display the information after clicking the process button' : 'Jika ada data yang perlu diperbaiki, sistem akan segera menampilkan informasinya setelah klik tombol proses'
export const LangImportHelp4 = isLangEN ? 'Download the excel import format here' : 'Download format impor excel di sini'

// Email Verification
export const LangVerified = isLangEN ? 'Verified' : 'Verified'
export const LangUnverified = isLangEN ? 'Unverified' : 'Belum Diverifikasi'
export const LangVerifiedAt = isLangEN ? 'Verified at' : 'Diverifikasi pada'
export const LangResendEmailVerification = isLangEN ? 'Resend Verification Email' : 'Kirim Ulang Email Verifikasi'
export const LangEmailVerified = isLangEN ? 'Email Verified' : 'Email Terverifikasi '
export const LangEmailVerificationRequired = isLangEN ? 'Email Verification Required' : 'Perlu Verifikasi Email'

// Client Visit Visualization
export const LangClientVisitVisualization = isLangEN ? 'Visit Visualization' : 'Visualisasi Kunjungan'
export const LangAddressStart = isLangEN ? 'Address Start' : 'Alamat Datang'
export const LangAddressEnd = isLangEN ? 'Address End' : 'Alamat Pulang'
export const LangFilterClient = isLangEN ? 'Client Filter' : 'Filter Klien'
export const LangVisitDate = isLangEN ? 'Visit Date' : 'Tanggal Kunjungan'
export const LangPleaseChooseVisitDate = isLangEN ? 'Please choose a visit date first' : 'Silakan pilih tanggal kunjungan terlebih dahulu'
export const LangNoDataClientVisit = isLangEN ? 'No client visits today' : 'Tidak ada kunjungan pada hari ini'
export const LangMapsDisclaimer = isLangEN ? 'The travel route displayed is an estimate of the journey taken by the employee.' : 'Rute perjalanan yang ditampilkan merupakan perkiraan perjalanan yang ditempuh oleh karyawan.'
export const LangClientVisitMustOnSpot = isLangEN ? 'Client Visit Must On Spot' : 'Kunjungan Harus Sesuai Lokasi Klien'
export const LangAddressDetail = isLangEN ? 'Address Details' : 'Detail Alamat'
export const LangSetLocation = isLangEN ? 'Set Location' : 'Set Lokasi'
export const LangExportAllClient = isLangEN ? 'Export All Client' : 'Ekspor Semua Klien'
export const LangExportSelectedClient = isLangEN ? 'Export Selected Client' : 'Ekspor Klien Terpilih'
export const LangExportSelectedClientCount = (count) => (isLangEN ? `Export ${count} Selected Client${count > 1 ? 's' : ''}` : `Ekspor ${count} Klien Terpilih`)
export const LangClientMapLocation = isLangEN ? 'Client Map Location' : 'Map Lokasi Klien'
export const LangAllowedVisitRadius = isLangEN ? 'Allowed Visit Radius' : 'Radius Kunjungan Diizinkan'

// Attendance Photo Profile
export const LangProfilePhotoApproval = isLangEN ? 'Profile Picture Approval' : 'Persetujuan Foto Profil'
export const LangNewPhotoProfile = isLangEN ? 'New Profile Picture' : 'Foto Profil Baru'
export const LangPhotoProfileConfirm = isLangEN ? 'Are you sure you allow this new profile picture? ' : 'Apakah anda yakin menyetujui pergantian foto profil ini?'
export const LangProfileApproval = isLangEN ? 'Profile Approval' : 'Persetujuan Profil'

export {
  LangLoginSupervisorInfo,
  LangChooseEmployee,
  LangTooltipTotalNotClockInDay,
  LangTotalNotClockInDays,
  LangReminderAfterBreakHelp,
  LangReminderAfterBreakMessage,
  LangReminderClockOutHelp,
  LangReminderClockOutMessage,
  LangTotalLeaveDays,
  LangTotalPermitDays,
  LangScheduleOff,
  LangDayOff,
  LangHelpAbsent,
  LangMaxRangeExport60Day,
  LangImportShiftHelp7,
  LangImportShiftHelp6,
  LangImportShiftHelp5,
  LangImportShiftHelp4,
  LangImportShiftHelp3,
  LangImportShiftHelp2,
  LangImportShiftHelp1,
  LangRequestSuccess,
  LangExportExcelSendToTime,
  LangExportExcelSendToHelp,
  LangExportExcelSendTo,
  LangPersonnelsInput,
  LangPersonnelsQuota,
  LangIdentity,
  LangSpesificEmployee,
  LangPackage,
  LangExpiredOn,
  LangMonth,
  LangHelpRepeat,
  LangShiftSchedule,
  LangBulkRepeat,
  LangDeleteSchedule,
  LangChooseSchedule,
  LangRepeatScheduleEveryWeek,

  LangPermitFiles,
  LangPermitRequestDate,
  LangPermitRequestDetail,
  LangPermitEndDate,
  LangPermitStartDate,
  LangPermitReject,
  LangPermitApprove,
  LangPermitRequestCancel,
  LangPermitCancelHelp,
  LangPermitCancel,
  LangPermitRequestReject,
  LangPermitRejectedAt,
  LangPermitApprovedAt,
  LangPermitNotNow,
  LangPermitYesSure,
  LangPermitRequestApproveConfirm,
  LangPermitDesc,
  LangPermitDates,
  LangPermitCreatedAt,
  LangPermit,
  LangPermitRequest,
  LangPermitApproved,
  LangPermitRejected,
  LangPermitToday,
  LangPermitTomorrow,
  LangPermitNext7Day,

  LangPrintApplication,
  LangPrint,
  LangPrintReceiver,

  LangMenuPermitRequest,
  LangTargetCount,
  LangFilterGroup,
  LangApplyBulkWorktime,
  LangTimeIn,
  LangTimeOut,
  LangSetting,
  LangReportSendTo,
  LangSeparateByComma,
  LangAdd,
  LangDelete,
  LangName,
  LangEmail,
  LangBCC,
  LangCustomersIs,
  LangCustomers,
  LangDeleteConfirm,
  LangChangeFile,
  LangPictureMaxSize,
  LangHideLeaveReqeustButton,
  LangHidePermitReqeustButton,
  LangHideOvertimeButton,
  LangHideBreakButton,
  LangQuote,
  LangMobileApp,
  LangYes,
  LangNo,
  LangImportCancel,
  LangImportDone,
  LangImportErrorMsg2,
  LangImportErrorMsg1,
  LangRow,
  LangImportErrorReason,
  LangImportFailedCount,
  LangImportSuccessCount,
  LangMaxSize,
  LangChooseFile,
  LangImportSeparate,
  LangImportMakeSure,
  LangDownloadImportFormat,
  LangImportant,
  LangImportExcel,
  LangMarkAll,
  LangAttendMustOnSpot,
  LangAttendIn,
  LangAttendOut,
  LangAttendBreak,
  LangAttendAfterBreak,
  LangAttendOvertime,
  LangAttendFinishOvertime,
  LangCreatedBy,
  LangEditedBy,
  LangEmpty,
  LangFrom,
  LangTo,
  LangChanged,
  LangDeletedBy,
  LangMinute,
  LangPenalty,
  LangLateCharge,
  LangLateness,
  LangAddPenalty,
  LangNewLateCharge,
  LangBulkActivate,
  LangActivation,
  LangActivate,
  LangNonActivate,
  LangList,
  LangActive,
  LangNonActive,
  LangHolidayNotes,
  LangHolidayCalendar,
  LangDescription,
  LangAddHoliday,
  LangHolidayImportMakeSure,
  LangActivityHistories,
  LangActivity,
  LangActivityDate,
  LangLogout,
  LangLogin,
  LangSystem,
  LangNewFeature,
  LangHowtoUse,
  LangLateChargeNote,
  LangCause,
  LangAttendanceRevision,
  LangAttendance,
  LangAttendanceType,
  LangCurrentPresence,
  LangPreviousPresence,
  LangPresenceRequest,
  LangProcessed,
  LangRevisionNote,
  LangAttendanceRevisioRejectNote,
  LangAttendanceRevisioRejectNote1,
  LangAttendanceRevisionApproveNote,

  LangSave,
  LangAttendanceEnterNewTime,
  LangAttendanceTimeLaT,
  LangAttendanceTimeLeT,
  LangLookingAttendanceRelated,
  LangSureDoThis,
  LangCancelAttendanceConfirm,
  LangYesSure,
  LangNotNow,
  LangBack,
  LangCancelAttendanceAssociate,
  LangEditDateAttendance,
  LangChooseAction,
  LangCancelAttendance,
  LangEditAttendance,

  LangId,
  LangEn,
  ExportExcel,
  SpotAssignPersonnel,
  SpotAssignSpecificDivision,
  SpotAssignSpecificPersonnel,
  SpotAssignAll,
  LangOn,
  LangLeaveRemainingDay,
  LangLeaveExpiredAt,
  LangProfile,
  LangAccount,
  LangPasswordNotEquals,

  LangMenuLeaveRequest,
  LangMenuSubscribeInfo,

  LangLeave,
  LangLeaveRequest,
  LangLeaveApproved,
  LangLeaveRejected,
  LangLeaveApprove,
  LangLeaveReject,
  LangLeaveToday,
  LangLeaveTomorrow,
  LangLeaveNext7Day,
  LangEmployee,
  LangLeaveCreatedAt,
  LangLeaveDates,
  LangLeaveDesc,
  LangLeaveRequestDetail,
  LangLeaveFiles,
  LangAction,
  LangConfirm,
  LangLeaveRequestApproveConfirm,
  LangLeaveYesSure,
  LangLeaveNotNow,
  LangLeaveApprovedAt,
  LangLeaveRejectedAt,
  LangReason,
  LangOptional,
  LangProcess,
  LangLeaveRequestReject,
  LangLeaveCancel,
  LangLeaveRequestCancel,
  LangEmployeeDeletedAt,
  LangDivision,
  LangSearch,
  LangLeaveCancelHelp,
  LangLeaveStartDate,
  LangLeaveEndDate,
  LangExportExcel,
  LangAttendancePerfomance,
  LangOntime,
  LangLate,
  LangNoAttendance,
  LangDeveloper,
  LangDeveloperApiDocumentation,
  LangBccTo,
  LangBccToPlaceholder,
  LangBccToHelp,
  LangSeeResult,
  LangOutOfQuota,
  LangSubscriptionSetting,
  LangOutOfQuotaMessage,
  LangNotYetLogin,
  LangAbsent,
  LangPosition,

  LangNewBroadcast,
  LangBroadcastReceiver,
  LangMessage,
  LangMessageTitle,
  LangNotificationMessage,
  LangAll,
  LangSend,
  LangTestSend,
  LangSent,
  LangSentAt,
  LangCreator,
  LangCertainDivision,
  LangCertainEmployee,
  LangExceptionReceiver,
  LangReceiver,
  LangAlreadySent,
  LangNoReceiver,
  LangOtherReceiver,
  LangSpesificReceiver,
  LangCanceled,
  LangDone,
  LangQuota,
  LangCopy,
  LangPrice,
  LangViewContent,
  LangOrderQuota,
  LangPause,
  LangPaused,
  LangQuotaRunsOut,
  LangSending,
  LangSetup,
  LangCancelConfirmation,
  LangSuccessBuyQuota,
  LangSendMessageSuccess,
  LangOutOfQuotaBroadcastMessage,
  LangCanceledDate,

  LangSuccess,
  LangConfigSuccessMessageAttendance,
  LangTitle,
  LangContent,
  LangMessageIfLate,
  LangMessageIfOntime,
  LangMessageIfOnSpot,
  LangMessageIfOutOfSpot,
  LangBackToDefault,
  LangBackToDefaultConfirm,
  LangSuccessBackToDefault,
  LangSuccessSaveData,
  LangTooltipAttendanceDay,
  LangTooltipBreakHour,
  LangTooltipLateDay,
  LangTooltipLateHour,
  LangTooltipLateToleranceDay,
  LangTooltipNotCompleteDay,
  LangTooltipOvertimeHour,
  LangTooltipWorkHour,
  LangTooltipLateToleranceHour,
  LangTooltipTotalLeaveDay,
  LangTooltipTotalPermitDay,

  PrevDay,
  NextDay,
  ShortHour,
  ShortMinute,
  PrevWeek,
  NextWeek,
  Seconds,
  UseTimezoneCompany,
  TypeKeyword,
  NoOptions,
  PleaseWait,
  SpecificDate,
  RangeDate,
  Process,
  Cancel,
  NoteOnAttendIn,
  NoteOnAttendOut,
  NoteOnAttendStartOvertime,
  NoteOnAttendFinishOvertime,
  NoteNotRequired,
  NoteOptional,
  NoteRequired,
  NoteMsgInstruction,
  EmailConfirmation,

  LangDevice,
  LangDeviceId,
  LangDeviceModel,
  LangDeviceName,
  LangDeviceType,
  LangDeviceIp,
  LangDeviceManufacture,
  LangAppVersion,

  AttendanceReview, GpsStatus, Marked, Unmarked, Mark, DateLang, Type, Time,

  AttendanceDays, WorkHours, BreakHours, OvertimeHours,
  LateHours, LateDays, LateToleranceDays, NotCompleteDays,
  LateToleranceHours, AddNote,

  Break, AfterBreak, OvertimeIn, OvertimeOut, Note, DescChangeHistories, ChangeHistories, DateChangeHistories, EditWorkSchedule, deleteWorkSchedule, langSureDoDeleteSchedule,

  Gender, Male, Female, StatusNote, StartWork, Group, EmployeIdentityID, EmployeID, EmployeIDFile,
  ProfilePicture, PhoneCC, AddNewEmployees, CreatedAt, UpdatedAt, DayAmount, EditWorkPattern,

  LangGeneral, LangMaritalStatus, LangReligion, LangBloodType, LangIdentityType, LangIdentityNumber, LangIdentityAddress, LangIdentityProvince,
  LangIdentityCity, LangDomicileAddress, LangDomicileProvince, LangDomicileCity, LangRecentEducation, LangEducationLevel, LangEducationMajor,
  LangInstitutionName, LangGraduationYear, LangEmployeeAffair, LangEmployeeId, LangEmployeeIdentityFile, LangAddFile, LangFileType,
  LangFileIdNumber, LangEmployeeFile, LangBirthPlace, LangPersonalInformation, LangOvertime, LangNoDataAvailable,

  Dashboard, CompanySetting, ManageAdmin, AttendanceSpot, WorkPattern, ManageEmployees, DataEmployees,
  EmployeesDivision, WorkTime, Timesheet, TimesheetList, TimesheetReport, DailyAttendance, AttendenceSummary,
  ManageDivision, Spot, YourTimeZone, EmployeePerDivision, SummaryAttendanceTime, SummaryAttendanceSpot,
  AttendancePerDay,

  Hi, Required, Save, CompanyLogo, CompanyName, BussinessFields, Employe, Address, Province, City,
  District, Villages, TimeZone, ZipCode, PhoneNumber, FaxNumber,

  LangMessageSuccessReminder, LangConfigReminderMessageAttendance,

  NoStatus, Present, Sick, Absent, Leave, Total, AttendanceTimeReport, OnTime, Late, Tolerance, AttendanceSpotReport, LeavePermit7days, EmployeeRequestLeave, EmployeeRequestPermit, NoLeaveRequest7Days, NoPermitRequest7Days,
  OnSpot, OutSpot, UndefinedSpot, WorkHour, WorkDay, None, Division, DivisionName,

  ClockIn, ClockOut, Search, Action, Edit, Delete, ConfirmPassword,
  AddNewAdmin, Close, Name, Password, Kosongkan, EditData,

  LateAttendancePunishment, EmployeesCount, WorkSystem, AddNewDivision, ShiftDay,
  Yes, No, WarningWorkSystem,

  TotalAssignEmployees, SpotName, SelectDivision, AllEmployees, AddNewSpot, SearchLocation,

  January, February, March, April, May, June, July, August, September, October, November, December,

  ShiftWorkTime, NameOfWorkPattern, NumberofDayinCycle, TardinessTolerance, AddWorkPattern,
  Sunday, Monday, Tuesday, Wednesday, Thrusday, Friday, Saturday, Minutes, WorkingDay, DayOff,
  WorkSchedule, Day, Color, MaximumImg, IsDay, IsDayOff, AddSchedule,

  EditSchedule, Selfie, isOnSpot, Home, Shift, Hours,

  AttendanceReviewInfo,

  MobileAplication, LangSuccessMessageAttendance, LangReminderMessageAttendance
}
